import * as React from 'react';
import { Box } from '@twilio-paste/core/box';
import { Stack } from '@twilio-paste/core/stack';
import { Button } from '@twilio-paste/core/button';
import { useCampaignContext } from "../CampaignProvider";
import { Label } from "@twilio-paste/label";
import { Input } from "@twilio-paste/input";
import { ChevronLeftIcon } from "@twilio-paste/icons/esm/ChevronLeftIcon";
import axios, { AxiosRequestConfig } from 'axios';
import { useVideoContext } from "../hooks/useVideoContext";
import { MicrophoneOffIcon } from "@twilio-paste/icons/esm/MicrophoneOffIcon";
import { MicrophoneOnIcon } from "@twilio-paste/icons/esm/MicrophoneOnIcon";
import { useParams } from "react-router-dom";
import { useAppState } from '../hooks/useAppState/useAppState';
import { InlineWidget } from "react-calendly";
import { useUID } from '@twilio-paste/core/uid-library';
import { Modal, ModalBody, ModalFooter, ModalFooterActions, ModalHeader, ModalHeading } from '@twilio-paste/core/modal';
import useState from 'react-usestateref'
import { Text } from '@twilio-paste/core/text';
import { ErrorIcon } from "@twilio-paste/icons/esm/ErrorIcon";
import { CallFailedIcon } from "@twilio-paste/icons/esm/CallFailedIcon";
import Logo from '../assets/Logo.png';
import { Checkbox } from "@twilio-paste/core/checkbox";
import styled from '@emotion/styled'
import "./Video.css";
import { Separator } from '@twilio-paste/core';


export const NFCCVideo = (): JSX.Element => {

  const startRef = React.useRef<HTMLDivElement>(null);

  //data bits
  // const [locatorInputs, setLocatorInputs] = useState<any>({})
  const [locatorInputs, setLocatorInputs, locatorInputsRef] = useState<any>({});
  const [locatorOutput, setLocatorOutput] = React.useState<any>({})
  //const [availability, setAvailability] = React.useState<any>({});
  const [availability, setAvailability, availabilityRef] = useState<any>({});
  const [agencyServiceConfigId, setAgencyServiceConfigId, agencyServiceConfigIdRef] = useState(0);
  const [connectionAgent, setConnectionAgent, connectionAgentRef] = useState({});
  const [queuePosition, setQueuePosition, queuePositionRef] = useState<number>();

  //display and navigation bits
  const [visibleStage, setVisibleStage, visibleStageRef] = useState("start"); //React.useState<string>("start");
  // const [interstitialText, setInterstitialText] = React.useState<string[]>(["Starting application..."]);

  const [interstitialText, setInterstitialText] = useState<string[]>([]);
  const [showInterstitial, setShowInterstitial] = useState(false);

  const [isBooking, setIsBooking] = React.useState(false);
  const [isDuplicateOverride, setIsDuplicateOverride] = React.useState(false);
  const [isCode, setIsCode] = React.useState(false);
  const [isEmail, setIsEmail] = React.useState(false);
  const [isQuickConnect, setIsQuickConnect, isQuickConnectRef] = useState(false);

  //video bits   
  const { video, isMuted, localVideoMedia, remoteVideoMedia, connect, disconnect, isOpen, ended, attended } = useVideoContext();
  const intervalQueuePositionRef = React.useRef<ReturnType<typeof setInterval> | null>(null)

  //validation bits
  const [emailError, setEmailError] = React.useState<string>("");
  const [fNameError, setfNameError] = React.useState<string>("");
  const [lNameError, setlNameError] = React.useState<string>("");
  const [zipError, setZipError] = React.useState<string>("");
  const [phoneError, setPhoneError] = React.useState<string>("");
  const [formValid, setFormValid] = React.useState(false);
  const [codeError, setCodeError] = React.useState<string>("");
  const [consent, setConsent] = useState(false);

  //timeout bits
  const [isModalOpen, setIsModalOpen, isModalOpenRef] = useState(false);
  const handleOpen = () => {
    setIsModalOpen(true);
  }
  const handleClose = () => {
    setIsModalOpen(false)
    clearCountdownTimer(false)
    timeout(false, visibleStage)
  }
  const modalHeadingID = useUID();
  const [countdownValue, setCountdownValue] = React.useState<number>(5);
  const [runInterval, setRunInterval] = React.useState(false);
  const intervalRef = React.useRef<ReturnType<typeof setInterval> | null>(null)

  //code and email bits
  const [isCodeModalOpen, setIsCodeModalOpen] = React.useState(false);
  const [isEmailModalOpen, setIsEmailModalOpen] = React.useState(false);
  const [isPostCallModalOpen, setIsPostCallModalOpen] = React.useState(false);
  const [isAlreadyConnectedModalOpen, setIsAlreadyConnectedModalOpen] = React.useState(false);

  const jumpToTop = () => {

		setTimeout(() => {
			if (startRef.current) {
				startRef.current.scrollIntoView({ behavior: "smooth" });

			}
		}, 100)
	};

  const handleCodeOpen = () => {
    setIsCodeModalOpen(true);
    setIsAlreadyConnectedModalOpen(false);
  }
  const handleCodeClose = () => {
    setIsCodeModalOpen(false);
  }

  const handleEmailOpen = () => {
    setIsEmailModalOpen(true);
    setIsAlreadyConnectedModalOpen(false);
  }

  const handleEmailClose = () => {
    setIsEmailModalOpen(false);
  }

  const handleAlreadyConnectedOpen = () => {
    setIsAlreadyConnectedModalOpen(true);
  }

  const handleAlreadyConnectedClose = () => {
    setIsAlreadyConnectedModalOpen(false);
  }

  const handlePostCallOpen = () => {
    console.log('here!!!')
    if (locatorInputs.email == 'none@nfcc.org') {
      const l = { ...locatorInputs, email: '' }
      setLocatorInputs(l)
      setIsPostCallModalOpen(true);
    }
    else {
      setIsPostCallModalOpen(true);
    }


  }
  const handlePostCallClose = () => {
    setIsPostCallModalOpen(false)
  }

  const codeModalHeadingID = useUID();
  const emailModalHeadingID = useUID();
  const postCallModalHeading = useUID();
  const alreadyConnectedID = useUID();
  const [code, setCode] = React.useState<string>("");

  //context bits
  const { guid } = useParams();
  const { init: videoInit } = useVideoContext();
  const { appDispatch, appState } = useAppState();
  const campaignData = useCampaignContext().campaignData;

  //translation bits
  const serviceTranslations = [
    { en: 'Student Loan Help', es: 'Préstamos de Estudiantes' },
    { en: 'Small Business Owner', es: 'Dueños de pequeños negocios' },
    { en: 'Foreclosure Prevention', es: 'Morosidad Hipotecaria' },
    { en: 'Reverse Mortgage', es: 'Hipoteca Invertida' },
    { en: 'Bankruptcy', es: 'Quiebra' },
    { en: 'Rental Counseling', es: 'Ayuda de Alquiler' },
    { en: 'First Time Homebuyer', es: 'Primeros Compradores De Vivienda' },
    { en: 'Credit Counseling', es: 'Asesoramiento crediticio' },
    { en: 'Overall Budget and Financial Review', es: ' Análisis de Presupuesto' }
  ]

  function isCalendlyEvent(e: any) {
    return e.data.event && e.data.event.indexOf("calendly") === 0;
  }

  //Set the campaign GUID in the AppState
  React.useEffect(() => {
    if (guid) {
      appDispatch({ type: "set-guid", guid: guid ?? '' });
    }
  }, [guid]);

  //Stop polling for queue position if call is answered
  React.useEffect(() => {
    if (attended) {
      console.log('attended changed', attended)
      clearInterval(intervalQueuePositionRef.current as NodeJS.Timeout);
      console.log('stopped polling for queue position')
    }
  }, [attended]);

  //Start polling for queue position when joining queue
  React.useEffect(() => {
    if (video) {

      console.log('video changed', video)
      video.on('video#joined', (payload) => {
        getQueuePosition()

      })

    }
  }, [video]);



  //Start polling for queue position when joining queue
  React.useEffect(() => {
    if (queuePosition) {
      console.log('queuePosition changed', queuePosition)
    }
  }, [queuePosition]);

  React.useEffect(() => {

    if (appState.domain) {

      window.addEventListener("message", function (e) {

        if (isCalendlyEvent(e)) {
          console.log('calendly', e.data);
          if (e.data.event == 'calendly.event_scheduled') {
            const updateAppointment = async () => {
              //create dummy appointment
              const url = e.data.payload.event.uri

              //update ConnectionAgent
              const config: AxiosRequestConfig = {
                method: 'post',
                maxBodyLength: Infinity,
                url: 'https://video-campaigns-6267.twil.io/' + appState.domain + '/update-appointment',
                headers: {
                  'Content-Type': 'application/json'
                },
                data: JSON.stringify({
                  useProd: appState.isProd,
                  data: JSON.stringify({ 'url': url, 'agencyServiceConfigId': agencyServiceConfigIdRef.current, 'connectionAgent': connectionAgentRef.current })
                })

              }
              try {
                const response = await axios.request(config);
                setVisibleStage('post-calendly');
                timeout(false, "post-calendly")
              }
              catch (error) {
                console.error(error)
              }
            }

            // call the function
            updateAppointment()
              // make sure to catch any error
              .catch(console.error);
          }
        }
      })
    };
  }, [appState.domain]);

  const handleStartFromEmail = () => {
    console.log('handleStartFromEmail')
    setIsEmail(true)
    const email = locatorInputs.email;

    //validate email
    //clear errors
    setEmailError("")
    let isError = false

    if (!Object.keys(locatorInputs).includes('email')) {
      setEmailError(translate("Please enter a valid email", "Por favor ingrese un correo electrónico válido"))
      isError = true;
    }
    // eslint-disable-next-line
    else if (Object.keys(locatorInputs).includes('email')) {
      if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,6})+$/g.test(locatorInputs.email) || locatorInputs.email.length == 1) {
        setEmailError(translate("Please enter a valid email", "Por favor ingrese un correo electrónico válido"))
        isError = true;
      }
    }
    else {
      isError = true;
    }

    if (isError) {
      return
    }



    //set dummy values for quick connect
    const l = { ...locatorInputs, firstName: 'Quick', lastName: 'Connect', phone: 'None provided', state: campaignData.state }

    console.log('l', l)
    setLocatorInputs(l)

    //close modal
    handleEmailClose()

    //start connection
    handleGetConnected(l)

  }


  const generateCode = async () => {

    console.log('CAZ calling generatecode')
    var code = ''

    const adjectives = ["Active", "Agile", "Amiable", "Ample", "Apt", "Artful", "Astute", "Avid", "Awake", "Aware", "Beloved", "Blissful", "Brave", "Breezy", "Bright",
      "Buoyant", "Candid", "Capable", "Careful", "Cheery", "Chirpy", "Clever", "Comely", "Comfy", "Crisp", "Cuddly", "Dainty", "Darling", "Dazzle", "Delish",
      "Dimple", "Doting", "Dreamy", "Dynamic", "Earnest", "Easy",
      "Exotic", "Expert", "Fabled", "Faith", "Fancy", "Fierce", "Fine", "Fond", "Free", "Fresh", "Friendly", "Frolic", "Fun", "Funny", "Gentle", "Gifted", "Glorious",
      "Grateful", "Happy", "Hardy", "Hearty", "Honest", "Huge", "Humble", "Ideal", "Innocent", "Jazzy", "Jolly", "Keen", "Kind", "Lively", "Lovely", "Loving",
      "Lucky", "Merry", "Mighty", "Modest", "Neat", "Noble", "Peace", "Playful", "Plucky", "Posh", "Pure", "Quick", "Radiant", "Rare", "Refined", "Regal", "Relish", "Rich",
      "Robust", "Rosy", "Secure", "Serene", "Sharp", "Shiny", "Simple", "Sincere", "Skill", "Sleek", "Smart", "Smitten", "Snappy", "Snug", "Solid", "Sonic",
      "Sought", "Speedy", "Spry", "Square", "Stable", "Steady", "Sunny", "Super", "Supreme", "Sure", "Sweet", "Swift", "Swish", "Talented", "Tender",
      "Tickled", "Toasty", "Top"
    ]

    const animals = [
      "Badger", "Beaver", "Bison", "Bobcat", "Buffalo", "Bulldog", "Butterfly", "Capybara", "Cheetah", "Chimp",
      "Chipmunk", "Cicada", "Cobra", "Corgi", "Cougar", "Crab", "Crane", "Crayfish", "Crow", "Deer",
      "Dingo", "Dolphin", "Donkey", "Dragonfly", "Eagle", "Eel", "Elephant", "Elk", "Falcon", "Ferret",
      "Finch", "Firefly", "Flamingo", "Fly", "Fox", "Frog", "Gazelle", "Gecko", "Gibbon", "Giraffe",
      "Goat", "Goose", "Gorilla", "Greyhound", "Guppy", "Hamster", "Hare", "Hawk",
      "Hedgehog", "Heron", "Hippo", "Horse", "Hyena", "Iguana", "Impala", "Jackal", "Jaguar",
      "Jay", "Jellyfish", "Kangaroo", "Koala", "Komodo", "Ladybug", "Lemur", "Leopard", "Lion", "Lizard",
      "Llama", "Lobster", "Macaw", "Magpie", "Manatee", "Mantis", "Marmot", "Meerkat", "Mongoose", "Monkey",
      "Moose", "Mosquito", "Moth", "Narwhal", "Newt", "Ocelot", "Octopus", "Opossum", "Orca",
      "Ostrich", "Otter", "Owl", "Panda", "Panther", "Parrot", "Peacock", "Pelican", "Penguin", "Pig",
      "Platypus", "Poodle", "Porcupine", "Possum", "Puma", "Puppy", "Rabbit", "Raccoon",
      "Rat", "Raven", "Rhino", "Rooster", "Salmon", "Scorpion", "Seagull", "Seahorse",
      "Seal", "Shark", "Sheep", "Shrimp", "Skunk", "Sloth", "Snail", "Snake", "Sparrow", "Squid",
      "Squirrel", "Starling", "Stingray", "Stork", "Swan", "Tamarin", "Tiger", "Toad", "Tortoise",
      "Turkey", "Turtle", "Viper", "Vulture", "Wallaby", "Walrus", "Warthog", "Wasp", "Weasel", "Whale",
      "Wolf", "Wombat", "Wren", "Yak", "Zebra"
    ]

    //generate code
    code = adjectives[Math.floor(Math.random() * adjectives.length)].toLowerCase() + ' ' + animals[Math.floor(Math.random() * animals.length)].toLowerCase()

    //validate code
    const url = 'https://video-campaigns-6267.twil.io/' + appState.domain + '/get-connection-by-code'

    let conn: any = {}

    const config: AxiosRequestConfig = {
      method: 'post',
      maxBodyLength: Infinity,
      url,
      headers: {
        'Content-Type': 'application/json'
      },
      data: JSON.stringify({
        useProd: appState.isProd,
        data: JSON.stringify({ "code": code })
      })
    };



    axios(config)
      .then(function (response1: any) {
        console.log("found connection", JSON.stringify(response1.data));
        if (Object.keys(response1.data).includes("error_message")) {
          //it's unique!
          return code
        }
        else {
          //not unique, generate another
          console.log('not unique')
          generateCode()
        }
      })
      .catch(function (error) {
        console.log('caz', error);
      });


    return code
  };


  React.useEffect(() => {
    if (visibleStage == 'calendly') {

      const createAppointment = async () => {
        //create dummy appointment
        //const url = 'https://video-campaigns-6267.twil.io/create-appointment'
        const url = 'https://video-campaigns-6267.twil.io/' + appState.domain + '/create-appointment'

        //const c = locatorOutput.connectionId.toString() + Math.ceil(Math.random() * 10000);

        const c = await generateCode()

        let ca: any = {
          connectionId: locatorOutput.connectionId,
          agencyId: locatorOutput.agencyId,
          code: c
        }

        const config: AxiosRequestConfig = {
          method: 'post',
          maxBodyLength: Infinity,
          url,
          headers: {
            'Content-Type': 'application/json'
          },
          data: JSON.stringify({
            useProd: appState.isProd,
            data: JSON.stringify(ca)
          })
        };
        try {
          const response = await axios.request(config);
          ca.connectionAgentId = response.data.connectionAgentId
          setConnectionAgent(ca)

        }
        catch (error) {
          console.error(error)
        }
      }

      // call the function
      createAppointment()
        // make sure to catch any error
        .catch(console.error);


    }
  }, [visibleStage]);

  const handleDisconnect = async () => {
    //if the call is ended while in the queue, need to remove the task from flex

    if (!attended) {

      //get task by videoChatRoomSid attribute
      const agencyVideoId = locatorOutput.agencyVideoId

      if (video) {
        if (video.room) {
          const roomSid = video?.room.sid

          const url = 'https://video-campaigns-6267.twil.io/' + appState.domain + '/cancel-task'

          //cancel task
          const config: AxiosRequestConfig = {
            method: 'post',
            maxBodyLength: Infinity,
            url,
            headers: {
              'Content-Type': 'application/json'
            },
            data: JSON.stringify({
              useProd: appState.isProd,
              data: JSON.stringify({ "agencyVideoId": agencyVideoId, "videoChatRoomSid": roomSid })
            })
          };
          try {
            const response = await axios.request(config);
            disconnect()
          }
          catch (error) {
            disconnect()
          }
        }
        else {
          end()
        }

      }

    }
    else {
      disconnect()
    }

  }

  //Handle countdown for timeout modal
  React.useEffect(() => {
    if (runInterval) {
      intervalRef.current = setInterval(() => {
        setCountdownValue((countdownValue) => Math.max(countdownValue - 1, 1))
      }, 1000);
    }

    return () => {
      clearInterval(intervalRef.current as NodeJS.Timeout);
    }; // clear interval when unmounting the component

  }, [runInterval]);


  //Handle countdown for timeout modal
  const clearCountdownTimer = (permanent: boolean) => {
    setRunInterval(false);
    if (!permanent) {
      timeout(true, visibleStage);
    }
    setTimeout(() => { setCountdownValue(5) }, 1000)
  };

  //initialise campaign data
  React.useEffect(() => {
    if (campaignData) {

      let l: any = {}

      if (campaignData.availableLanguages.length > 1) {
        l.language = "English"
      }

      if (campaignData.availableServices.length > 1) {
        let se = []
        if (campaignData.availableServices.includes('Credit Counseling')) {
          se.push('Credit Counseling')

          // @ts-ignore
          se.push(...campaignData.availableServices.filter(s => s != 'Credit Counseling'))
          campaignData.availableServices = se
        }

      }

      if (campaignData.availableLanguages.length == 1) {
        l.language = campaignData.availableLanguages[0]

        if (l.language === "Spanish") {
          //Translation time baby!
          setInterstitialText(['Ejecutando aplicación...']) //spanish for starting application
        }

        //setVisibleStage("service")
      }

      if (campaignData.availableServices.length == 1) {
        l.service = campaignData.availableServices[0]
        //setVisibleStage("form")
      }

      l.videoCampaignId = campaignData.videoCampaignId

      setLocatorInputs(l)
    }

  }, [campaignData]);

  const start = () => {

    console.log('starting')


    if (campaignData.type == 'booth') {
      setLocatorInputs({ ...locatorInputs, state: campaignData.state })
    }

    if (campaignData.availableServices.length > 1) {
      setVisibleStage("service")
    }

    else {
      const input: any = { service: (locatorInputs.service || campaignData.availableServices[0]), videoCampaignId: campaignData.videoCampaignId, language: (locatorInputs.language || campaignData.availableLanguages[0]) }

      if (campaignData.type == 'booth') {
        input.state = campaignData.state
      }

      setLocatorInputs(input)

      if (campaignData.type == 'booth') {
        checkAvailability(input, false)
      }
      else if (isQuickConnect) {

        setTimeout(() => checkAvailability(input, false), 500)

      }
      else if (Object.keys(locatorInputs).includes('firstName')) {
        checkAvailability(input, false)

      }
      else {
        setVisibleStage("form")
      }
    }
  }


  const enterCode = () => {
    handleCodeOpen()
  }

  const enterEmail = () => {
    handleEmailOpen()
  }

  const enterPostCall = () => {
    handlePostCallOpen()
  }

  const alreadyConnected = () => {
    handleAlreadyConnectedOpen();
  }

  const handleCodeGo = async () => {
    setIsCode(true)
    setCodeError('')

    //validate code
    const url = 'https://video-campaigns-6267.twil.io/' + appState.domain + '/get-connection-by-code'

    let conn: any = {}

    const config: AxiosRequestConfig = {
      method: 'post',
      maxBodyLength: Infinity,
      url,
      headers: {
        'Content-Type': 'application/json'
      },
      data: JSON.stringify({
        useProd: appState.isProd,
        data: JSON.stringify({ "code": code })
      })
    };
    try {
      const response = await axios.request(config);
      conn = response.data

      if (Object.keys(conn).includes('error_message')) {
        setCodeError(translate('Code not found, please check and try again', 'Código no encontrado, verifique e intente nuevamente'))
      }
      else {
        let s = ''
        let e = ''
        let p = ''
        if (Object.keys(conn).includes('serviceName')) {
          s = conn.serviceName
        }
        else {
          s = conn.service
        }

        if (Object.keys(conn).includes('fromPhone')) {
          p = conn.fromPhone
        }
        else {
          p = conn.phone
        }

        if (Object.keys(conn).includes('fromEmail')) {
          e = conn.fromEmail
        }
        else {
          e = conn.email
        }

        const input = { ...conn, service: s, videoCampaignId: campaignData.videoCampaignId, email: e, phone: p }

        if (campaignData.type == 'booth') {
          input.state = campaignData.state
        }

        setLocatorInputs(input)

        //check agency availability
        checkAvailability(input, true)


        handleCodeClose()
      }

    }
    catch (error) {
      setCodeError(translate('Code not found, please check and try again', 'Código no encontrado, verifique e intente nuevamente'))
    }



  }

  //for code pathway, route after availability check
  React.useEffect(() => {
    if (Object.keys(availability).length > 0) {
      if (isCode || (availability.available && isEmail)) {
        //if valid and agency is available, connect directly to agency 
        console.log('here1')
        callLocator(false)
      }
      else if (isEmail) {
        let l = { ...locatorInputs }
        delete l.firstName
        delete l.lastName
        delete l.zipCode
        delete l.phone


        setLocatorInputs(l)
        setConsent(false)
        setVisibleStage('prebooking')
      }
      else if (isQuickConnect && !availability.available) {
        console.log('here2')
        let l = { ...locatorInputs }
        delete l.firstName
        delete l.lastName
        delete l.zipCode
        delete l.phone
        delete l.email


        setLocatorInputs(l)
        setConsent(false)
        setVisibleStage('prebooking')
      }
      
    }


  }, [availability]);

  React.useEffect(() => {
    console.log('useeffect locatorInputs', locatorInputs)

  }, [locatorInputs]);


  //for quick connect, default values
  React.useEffect(() => {
    console.log('useeffect isqc', isQuickConnect)


    if (isQuickConnect) {
      console.log('in here')
      let i = [translate("Finding your counselor, please hold", "Encontrar a su consejero, por favor espere")]
      setLocatorInputs({ ...locatorInputs, firstName: 'Quick', lastName: 'Connect', email: 'none@nfcc.org', phone: 'None provided' })
      setInterstitialText(i)
    }
  }, [isQuickConnect]);

  //time out the app if the video call has ended
  React.useEffect(() => {
    if (ended) {
      timeout(true, visibleStage)
    }
  }, [ended]);

  //route based on output of locator
  React.useEffect(() => {

    if (Object.keys(locatorOutput).length > 0) {

      setAgencyServiceConfigId(locatorOutput.agencyServiceConfigId)

      if (Object.keys(locatorOutput).includes('error_message')) {
        setVisibleStage('error')
        timeout(false, 'error')
        setShowInterstitial(false)
      }
      else if (locatorOutput.available && locatorOutput.isDuplicate && isCode) {
        let s = [translate('Hello' + (locatorInputs.firstName != 'Quick' && " " + locatorInputs.firstName || "") + '!', '¡Hola' + (locatorInputs.firstName != 'Quick' && " " + locatorInputs.firstName || "") + "!"),
        translate('You are being connected to ' + locatorOutput.agencyName, 'Está siendo conectado a ' + locatorOutput.agencyName)]
        setInterstitialText(s)
        setShowInterstitial(true)
        startVideo()
      }
      else if (locatorOutput.available && (isQuickConnect || locatorInputs.firstName == "Quick")) {
        let t1 = translate("You are being connected to:", "Está siendo conectado a:");
        let t2 = translate("An NFCC Member Agency", "Una Agencia Miembro de NFCC");

        let imageUrl = locatorOutput.agencyLogoUrl;

        let image = `<img src="${imageUrl}" alt="Agency Image" style="max-width: 40vw; height: auto; display: block; margin: 0 auto;">`;

        let interstitialText = [t1, image, t2];

        setInterstitialText(interstitialText);
        setShowInterstitial(true);
        setTimeout(() => { startVideo() }, 4000);
      }

      else if (locatorOutput.isDuplicate) {
        setVisibleStage('duplicate')
        timeout(true, 'duplicate')
        setShowInterstitial(false)
      }
      else if (!locatorOutput.available && Object.keys(locatorOutput).includes('type')) {
        //means we've failed over to online
        setShowInterstitial(false)
        setVisibleStage('none-available')
        timeout(false, 'none-available')
      }
      else if (!locatorOutput.available && locatorOutput.calendlyUrl.length > 0 && (isBooking || isDuplicateOverride)) {
        //handle unavailable, not dup, bookable
        setVisibleStage('calendly')
        jumpToTop()
        setShowInterstitial(false)
        timeout(true, 'calendly')

      }
      else if (!locatorOutput.available && locatorOutput.calendlyUrl.length > 0 && (!isDuplicateOverride || isCode || isEmail)) {

        setShowInterstitial(false)
        if (isEmail || locatorInputs.firstName == 'Quick')  {
          let l = { ...locatorInputs }
          delete l.firstName
          delete l.lastName
          delete l.zipCode
          delete l.phone

          if(l.email == 'none@nfcc.org')
          {
            delete l.email
          }

          setLocatorInputs(l)
          setConsent(false)
          setVisibleStage('prebooking')
        }
        else if (isQuickConnect) {
          let t1 = translate("You are being connected to:", "Está siendo conectado a:");
          let t2 = translate("An NFCC Member Agency", "Una Agencia Miembro de NFCC");

          let imageUrl = locatorOutput.agencyLogoUrl;

          let image = `<img src="${imageUrl}" alt="Agency Image" style="max-width: 40vw; height: auto; display: block; margin: 0 auto;">`;

          let interstitialText = [t1, image, t2];

          setInterstitialText(interstitialText);
          setShowInterstitial(true);
          setTimeout(() => {
            setShowInterstitial(false)
            clearCountdownTimer(true)
            setVisibleStage('calendly')
            jumpToTop()
            timeout(true, 'calendly')
          },
            4000);


        }
        else {
          setVisibleStage('prebooking')
        }

      }
      /*else if (!locatorOutput.available && locatorOutput.calendlyUrl.length > 0 && !isBooking) {
        //handle unavailable, not dup, bookable
        setVisibleStage('prebooking')
      }*/
      else if (!locatorOutput.available) {
        //handle unavailable, not dup, not bookable
        setShowInterstitial(false)
        setVisibleStage('none-available')
        timeout(false, 'none-available')

      }
      else {
        let s = [translate('Hello' + (locatorInputs.firstName != 'Quick' && " " + locatorInputs.firstName || "") + "!", '!Hola' + (locatorInputs.firstName != 'Quick' && " " + locatorInputs.firstName || "") + "!"),
        translate('You are being connected to ' + locatorOutput.agencyName, 'Está siendo conectado a ' + locatorOutput.agencyName)]
        setInterstitialText(s)
        setShowInterstitial(true)
        startVideo()
      }
    }

  }, [locatorOutput]);

  //set interstitial text after availability check
  React.useEffect(() => {



    if (Object.keys(availability).length > 0) {
      setInterstitialText([translate('Finding your counselor, please hold', 'Encontrándose una consejero, por favor espere')])

    }

  }, [availability]);

  const getQueuePosition = async () => {

    console.log('in getqueueposition')


    const getPosition = async (url: string, agencyVideoId: number, roomSid: string) => {
      console.log('in getposition')

      const config: AxiosRequestConfig = {
        method: 'post',
        maxBodyLength: Infinity,
        url,
        headers: {
          'Content-Type': 'application/json'
        },
        data: JSON.stringify({
          useProd: appState.isProd,
          data: JSON.stringify({ "agencyVideoId": agencyVideoId, "videoChatRoomSid": roomSid })
        })
      }
      const response = await axios.request(config);

      if (Object.keys(response.data).includes('position')) {
        return response.data.position
      }
      else {
        return -1
      }

    }


    //get task by videoChatRoomSid attribute
    const agencyVideoId = locatorOutput.agencyVideoId
    const url = 'https://video-campaigns-6267.twil.io/' + appState.domain + '/get-queue-position'

    console.log('CAZ v', video)

    if (video) {
      if (video.room) {
        const roomSid = video?.room.sid

        let position = await getPosition(url, agencyVideoId, roomSid)
          .catch(console.error);

        console.log('initial position', position)

        setQueuePosition(position)

        if (position > 1) {
          intervalQueuePositionRef.current = setInterval(async () => {

            let p = await getPosition(url, agencyVideoId, roomSid)
              .catch(console.error);

            setQueuePosition(position)

            if (position <= 1) {
              clearInterval(intervalQueuePositionRef.current as NodeJS.Timeout);
              console.log('stopped polling for queue position 1')
            }



          }, 60000);
        }





      }
      //Stop polling if position is 1 or if attended 



    }
  }



  const startVideo = () => {

    let label = (locatorInputs.service === 'Overall Budget and Financial Review' && 'Budget' || locatorInputs.service) + '_' + locatorOutput.code + '!' + campaignData.videoCampaignId + '!' + Math.ceil(Math.random() * 100) + '_' + (locatorInputs.language == 'Spanish' && 'es' || 'en')

    if (Object.keys(locatorInputs).includes('workerSid')) {
      label = label + '_' + locatorInputs.workerSid
    }


    videoInit(locatorOutput.accountSid, locatorInputs.firstName + ' ' + locatorInputs.lastName, locatorOutput.kioskSid
      //+ '-' + locatorInputs.firstName + '_' + locatorInputs.lastName
      , label, null);

    setTimeout(() => {
      setShowInterstitial(false);
      setVisibleStage('video')

      connect()
    }, 1000)
  }

  //handle change of variable values in form
  const handleChange = ({
    target
  }: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const newI = {
      ...locatorInputs,
      [target.name]: target.value
    }
    setLocatorInputs(newI);

    setFormValid(validate('change', newI))
  };

  const handleCodeChange = ({
    target
  }: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    setCode(target.value)
  };



  //handle button clicks for language and service selection
  const handleClick = (

    s: string, type: string) => {

    const l = {
      ...locatorInputs,
      [type]: s
    }



    setLocatorInputs(l);

    if (type == "language" //&& campaignData.availableServices.length == 1
    ) {
      if (s === "Spanish") {
        //Translation time baby!
        setInterstitialText(['Ejecutando aplicación...'])
      }
      else {
        setInterstitialText(['Starting application...'])
      }
      setVisibleStage('start')
    }
    /*else if (type == "language" && campaignData.availableServices.length > 1) {
      if (s === "Spanish") {
        //Translation time baby!
        setInterstitialText(['Ejecutando aplicación...'])
      }
      setVisibleStage('service')
    }*/
    else if (type == "service" && campaignData.type == 'booth') {
      //do availability check
      checkAvailability(l, false)
    }
    else {
      setVisibleStage('form')
    }
  };

  //navigate back to previous view
  const back = (destination: string) => {
    console.log('back', destination)
    if (['service', 'start', 'language'].includes(destination) && (isCode || isEmail)) {
      setShowInterstitial(false)
      end()
    }



    let l = { ...locatorInputs }
    delete l.firstName
    delete l.lastName
    delete l.zipCode
    delete l.phone
    delete l.email


    setLocatorInputs(l)
    setConsent(false)

    setAvailability({})
    setLocatorOutput({})
    setInterstitialText([translate('Starting application...', 'Ejecutando aplicación...')])

    setVisibleStage(destination)

    if (isQuickConnect) {
      let i = [translate("Finding your counselor, please hold", "Encontrar a su consejero, por favor espere")]
      setInterstitialText(i)
    }
  };

  const updateEmailPostCall = async () => {
    const email = locatorInputs.email;


    //validate email
    //clear errors
    setEmailError("")
    let isError = false

    if (!Object.keys(locatorInputs).includes('email')) {
      setEmailError(translate("Please enter a valid email", "Por favor ingrese un correo electrónico válido"))
      isError = true;
    }
    // eslint-disable-next-line
    else if (Object.keys(locatorInputs).includes('email')) {
      if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,6})+$/g.test(locatorInputs.email) || locatorInputs.email.length == 1) {
        setEmailError(translate("Please enter a valid email", "Por favor ingrese un correo electrónico válido"))
        isError = true;
      }
    }
    else {
      isError = true;
    }

    if (isError) {
      return
    }

    let a = { emailPayload: {}, connectionPayload: { connectionId: locatorOutput.connectionId, fromEmail: email } }



    const template_data = {
      "agency_name": locatorOutput.agencyName,
      "agency_phone": locatorOutput.agencyPhone,
      "agency_website": locatorOutput.agencyWebsite,
      "agency_logo": locatorOutput.agencyLogoUrl,
      "code": locatorOutput.code
    }

    let templateId = 'd-6eaa03e0ec80474f83391abc9c5f8868'


    if (locatorInputs.language == 'Spanish')
      templateId = 'd-f1660c45dad24b35a7bd77ac6bacd44b'





    var payload = {
      "to": email,
      "from": "clientinquiry@nfcc.org",
      "fromName": "Client Inquiry",
      "templateId": templateId,
      "templateData": template_data
    }

    a.emailPayload = payload


    const config: AxiosRequestConfig = {
      method: 'post',
      maxBodyLength: Infinity,
      url: 'https://video-campaigns-6267.twil.io/' + appState.domain + '/update-email',
      headers: {
        'Content-Type': 'application/json'
      },
      data: JSON.stringify({ useProd: appState.isProd, data: JSON.stringify(a) })
    };
    const response = await axios.request(config);

    end()

  }

  //call the check availability endpoint to see who is available and route accordingly
  const checkAvailability = async (l: any, isCode: boolean) => {


    let a = { ...l }

    if (a.serviceName === 'Overall Budget and Financial Review') {
      a.serviceName = 'Budget'
    }


    if (l.service === 'Overall Budget and Financial Review') {
      a.service = 'Budget'
    }


    setShowInterstitial(true)
    const config: AxiosRequestConfig = {
      method: 'post',
      maxBodyLength: Infinity,
      url: 'https://video-campaigns-6267.twil.io/' + appState.domain + '/get-availability',
      headers: {
        'Content-Type': 'application/json'
      },
      data: JSON.stringify({ useProd: appState.isProd, data: JSON.stringify(a) })
    };
    const response = await axios.request(config);

    if (!isCode && !isEmail) {

      setShowInterstitial(false)

      if (Object.keys(response.data).includes('error_message')) {

        setVisibleStage('form')
        setZipError(translate("Please enter a valid ZIP", "Por favor ingrese un código postal válido"))
        return
      }

      setAvailability(response.data)
      console.log('av', response.data)

      if (response.data.available && campaignData.type == 'booth' && isQuickConnectRef.current) {
        callLocator(false)
      }
      else if (response.data.available && campaignData.type == 'booth') {
        //available and hasn't been through the form yet, send them to the form
        setVisibleStage('form')
      }
      else if (response.data.available && campaignData.type == 'web') {
        //available and has been through the form, call locator
        callLocator(false)
      }
      else if (response.data.offerBooking && !response.data.available) {
        console.log('is here??')
        setVisibleStage('prebooking')
        timeout(true, 'prebooking')
      }
      else if (!response.data.offerBooking && !response.data.available) {
        setVisibleStage('form')
      }
      else {
        setVisibleStage('error')
        timeout(false, 'error')
      }
    }
    else {
      console.log('is code or email')
      setAvailability(response.data)
    }
  }

  //handle button click from form
  const handleGetConnected = async (l: any | null) => {
    console.log('handleGetConnected', l)
    let skipValidation = true

    if (!l) {
      l = { ...locatorInputs }
      skipValidation = false
    }

    console.log('l2', l)

    let valid = true

    if (!skipValidation) {
      valid = validate('button', l);
    }

    if (valid) {

      if (Object.keys(availability).length > 0) {
 
          //booth
          callLocator(false)
        
        
      }
      else {
        //web
        checkAvailability(l, false)
      }
    }


  }

  //re call the locator with the duplicate override flag
  const handleDuplicateOverride = async () => {
    setIsDuplicateOverride(true)
    clearCountdownTimer(true)
    callLocator(true)

  }

  //show the calendly view - ASSUMING THIS IS ONLY EVER CALLED WHEN IT'S A DUPLICATE WITH NO OVERRIDE! ***
  const handleCalendly = async () => {
    if(locatorInputs.firstName === 'Quick')
    {
      let l = { ...locatorInputs }
      delete l.firstName
      delete l.lastName
      delete l.zipCode
      delete l.phone

      if(l.email == 'none@nfcc.org')
      {
        delete l.email
      }


      setLocatorInputs(l)
      setConsent(false)
      setVisibleStage('prebooking')
      return
    }
    //send email to client
    const email = locatorInputs.email;

    let a = { emailPayload: {}, connectionPayload: { connectionId: locatorOutput.connectionId, fromEmail: email } }

    const template_data = {
      "client_name": locatorInputs.firstName,
      "agency_name": locatorOutput.agencyName,
      "agency_phone": locatorOutput.agencyPhone,
      "agency_website": locatorOutput.agencyWebsite,
      "agency_logo": locatorOutput.agencyLogoUrl,
      "code": locatorOutput.code,
      "agency_blurb": ""
    }

    let templateId = 'd-60785f69da1147e6b6db493f72bb8b7f'

    if (locatorInputs.language == 'Spanish')
      templateId = 'd-6287198551014fada8cce6b77654a784'

    var payload = {
      "to": email,
      "from": "clientinquiry@nfcc.org",
      "fromName": "Client Inquiry",
      "templateId": templateId,
      "templateData": template_data
    }

    a.emailPayload = payload


    const config: AxiosRequestConfig = {
      method: 'post',
      maxBodyLength: Infinity,
      url: 'https://video-campaigns-6267.twil.io/' + appState.domain + '/update-email',
      headers: {
        'Content-Type': 'application/json'
      },
      data: JSON.stringify({ useProd: appState.isProd, data: JSON.stringify(a) })
    };
    const response = await axios.request(config);
    console.log(response)


    //send email to agency
    templateId = 'd-29c5d405f51542638e3d79007608d8cf'

    const templateDataAgency = {
      "client_name": (locatorInputs.firstName + " " + locatorInputs.lastName + " (returning client)"),
      "client_email": email,
      "client_phone": locatorInputs.phone,

      "client_state": locatorInputs.state,
      "client_services": locatorInputs.service,
      "client_lang": locatorInputs.language,
      "client_source": campaignData.name,
      "client_billingref": locatorOutput.connectionSid
    }


    var payloadAgency = {
      "to": locatorOutput.agencyEmail,
      "from": "clientinquiry@nfcc.org",
      "fromName": "Client Inquiry",
      "templateId": templateId,
      "templateData": templateDataAgency
    }

    a.emailPayload = payloadAgency

    const configAgency: AxiosRequestConfig = {
      method: 'post',
      maxBodyLength: Infinity,
      url: 'https://video-campaigns-6267.twil.io/' + appState.domain + '/update-email',
      headers: {
        'Content-Type': 'application/json'
      },
      data: JSON.stringify({ useProd: appState.isProd, data: JSON.stringify(a) })
    };
    const responseAgency = await axios.request(configAgency);
    console.log(responseAgency)



    //set view
    clearCountdownTimer(true)
    setVisibleStage('calendly')
    jumpToTop()
    timeout(true, 'calendly')
  }

  //validate form inputs - returns true if inputs are valid
  const validate = (type: string, input: any) => {
    //clear errors
    setEmailError("")
    setfNameError("")
    setlNameError("")
    setZipError("")
    setPhoneError("")

    let isError = false;

    //validate
    if (!Object.keys(input).includes('zipCode') && campaignData.type == 'web' && type == 'button') {
      setZipError(translate("Please enter a valid ZIP", "Por favor ingrese un código postal válido"))
      isError = true;
    }
    else if ((input.zipCode && ! /[0-9]{5}/.test(input.zipCode)) && campaignData.type == 'web') {
      setZipError(translate("Please enter a valid ZIP", "Por favor ingrese un código postal válido"))
      isError = true;
    }
    if (!Object.keys(input).includes('phone') && type == 'button') {
      setPhoneError(translate("Please enter a valid phone", "Por favor ingrese un número de teléfono válido"))
      isError = true;
    }
    // eslint-disable-next-line
    else if (Object.keys(input).includes('phone')) {
      if (! /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/g.test(input.phone) || input.phone.length == 1) {
        setPhoneError(translate("Please enter a valid phone", "Por favor ingrese un número de teléfono válido"))
        isError = true;
      }
    }
    else {
      isError = true;
    }


    if (!Object.keys(input).includes('email') && type == 'button') {
      setEmailError(translate("Please enter a valid email", "Por favor ingrese un correo electrónico válido"))
      isError = true;
    }
    // eslint-disable-next-line
    else if (Object.keys(input).includes('email')) {
      if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,6})+$/g.test(input.email) || input.email.length == 1) {
        setEmailError(translate("Please enter a valid email", "Por favor ingrese un correo electrónico válido"))
        isError = true;
      }
    }
    else {
      isError = true;
    }


    if (!Object.keys(input).includes('firstName') && type == 'button') {
      setfNameError(translate("Please enter a valid first name", "Por favor ingrese un nombre válido"))
      isError = true;
    }

    if (!Object.keys(input).includes('lastName') && type == 'button') {
      setlNameError(translate("Please enter a valid last name", "Por favor ingrese un apellido válido"))
      isError = true;
    }

    if (
      !Object.keys(input).includes('firstName') || input.firstName == "" ||
      !Object.keys(input).includes('lastName') || input.lastName == "" ||
      !Object.keys(input).includes('email') || input.email == "" ||
      !Object.keys(input).includes('phone') || input.phone == ""

    ) {
      isError = true
    }

    if ((!Object.keys(input).includes('zipCode') || input.zipCode == "") && campaignData.type == 'web') {
      isError = true
    }

    return !isError
  }

  //route for the booking pathway
  /*const handleBooking = async () => {

    if (isQuickConnect) {
      let l = locatorInputs

      delete l.firstName
      delete l.lastName
      delete l.phone
      delete l.email

      setLocatorInputs(l)
    }



    if (Object.keys(locatorOutput).length > 0) {
      setVisibleStage('calendly')
    }
    else if (campaignData.type == "booth") {
      setIsBooking(true)
      setVisibleStage('form')
    }
    else {
      setIsBooking(true)
      callLocator(false)
    }
  }*/

  //handle translation
  const translate = (en: string, es: string) => {
    if (locatorInputs.language == 'Spanish') {
      return es
    }
    return en
  }

  const translateService = (s: string) => {
    const es = (serviceTranslations.find(({ en }) => en === s) ?? {}).es ?? '';
    if (es) {
      return translate(s, es)
    }
    else {
      return s
    }
  }
  /** */

  //clear the local storage and refresh the app for the next user
  const end = () => {
    setTimeout(() => {
      location.reload()
      localStorage.clear();
      jumpToTop()
    }, 500)
  }

  //start a timeout to end the user's session
  const timeout = (isLong: boolean, stage: string) => {
    console.log('starting timeout', isLong, stage)

    if (isLong) {
      setTimeout(() => {

        if (stage == visibleStageRef.current) {
          handleOpen()
          setRunInterval(true)

          setTimeout(() => {
            if (isModalOpenRef.current) {
              end()
            }
            clearCountdownTimer(true)
          }, 5000)
        }

      }, 180000)
    }
    else {
      setTimeout(() => {
        if (stage == visibleStageRef.current) {
          handleOpen()
          setRunInterval(true)

          setTimeout(() => {
            if (isModalOpenRef.current) {
              end()
            }
            clearCountdownTimer(true)
          }, 5000)
        }
      }, 15000)
    }

  }

  //call the locator endpoint to connect a client with an agency
  const callLocator = async (duplicateOverride: boolean) => {
    setShowInterstitial(true)
    clearCountdownTimer(true)

    let d = {
      ...locatorInputsRef.current,
      duplicateOverride,
      availabilityResults: availabilityRef.current
    }

    /* if (isQuickConnect) {
       d ={ ...d, firstName: 'Quick', lastName: 'Connect', email: 'none@nfcc.org', phone: 'None provided' }
 
     }*/




    if (isCode) {
      d.code = code
    }

    if (campaignData.type == 'booth') {
      d.state = campaignData.state
    }

    console.log('d', d)


    const config: AxiosRequestConfig = {
      method: 'post',
      maxBodyLength: Infinity,
      url: 'https://video-campaigns-6267.twil.io/' + appState.domain + '/call-locator-video',
      headers: {
        'Content-Type': 'application/json'
      },
      data: JSON.stringify({ useProd: appState.isProd, data: JSON.stringify(d) })
    };
    const response = await axios.request(config);
    setLocatorOutput(response.data)

  }

  // Function to calculate the inverted color
  const getInvertedColor = (color: string) => {
    if (color) {
      const hex = color.replace('#', '');
      const r = parseInt(hex.substring(0, 2), 16);
      const g = parseInt(hex.substring(2, 4), 16);
      const b = parseInt(hex.substring(4, 6), 16);
      const invertedColor = `#${(255 - r).toString(16).padStart(2, '0')}${(255 - g).toString(16).padStart(2, '0')}${(255 - b).toString(16).padStart(2, '0')}`;
      return invertedColor;
    }
    return '#000'; // Default color if no valid color is provided
  };

  // Constant to store the inverted color for use in components
  const invertedButtonColor = getInvertedColor(campaignData?.buttonColor);

  const ActionButton = styled('button')`
  height: 12rem;
  width: 18rem;
  font-size: 1.75rem;
  font-weight: 500;
  border-radius: 10px;
  border: 1px solid #ccc;
  background-color: ${campaignData?.buttonColor};
  color: ${invertedButtonColor};
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  &:hover {
    background-color: #ccc;
    color: #000;
  }
  &:visited {
    background-color: ${campaignData?.buttonColor};
    color: ${invertedButtonColor};
  }
  &:active {
    background-color: ${campaignData?.buttonColor};
    color: ${invertedButtonColor};
  }
  &:focus {
    background-color: #ccc;
    color: #000;
  }
`;

  const ServiceButton = styled('button')`
  height: 12rem;
  width: 18rem;
  font-size: 1.75rem;
  font-weight: 500;
  border-radius: 10px;
  border: 1px solid #ccc;
  background-color: ${campaignData?.buttonColor};
  color: ${invertedButtonColor};
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  &:hover {
    background-color: #ccc;
    color: #000;
  }
  &:visited {
    background-color: ${campaignData?.buttonColor};
    color: ${invertedButtonColor};
  }
  &:active {
    background-color: ${campaignData?.buttonColor};
    color: ${invertedButtonColor};
  }
  &:focus {
    background-color: #ccc;
    color: #000;
  }
`;

  const StartButton = styled('button')`
  height: 5rem;
  width: 16rem;
  font-size: 1.25rem;
  font-weight: 500;
  border-radius: 35px;
  border: none;
  background-color: ${campaignData?.buttonColor};
  color: ${invertedButtonColor};
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25)) drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  &:visited {
    background-color: ${campaignData?.buttonColor};
    color: ${invertedButtonColor};
  }
  &:active {
    background-color: ${campaignData?.buttonColor};
    color: ${invertedButtonColor};
  }
  &:focus {
    background-color: #ccc;
    color: #000;
  }
`;

  const StartButtonDisabled = styled('button')`
  height: 5rem;
  width: 16rem;
  font-size: 1.25rem;
  font-weight: 500;
  border-radius: 35px;
  border: none;
  background-color: #CBCBCB;
  color: #ADAAAB;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25)) drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
`;

  const MuteButton = styled('button')`
  border-radius: 35px !important;
  width: 160px !important;
  height: 60px !important;
  font-size: 20px !important;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25)) drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25)) !important;
  -webkit-border-radius: 35px !important;
  -moz-border-radius: 35px !important;
  -ms-border-radius: 35px !important;
  -o-border-radius: 35px !important;
  
  /* Twilio Paste Button Base Styles */
  display: inline-flex;
  align-items: center;
  justify-content: space-evenly;
  padding: 24px 24px;
  background-color: ${campaignData?.buttonColor};
  color: ${invertedButtonColor};
  border: none;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
`;

  const EndCallButton = styled('button')`
  border-radius: 35px !important;
  width: 160px !important;
  height: 60px !important;
  font-size: 20px !important;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25)) drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25)) !important;
  -webkit-border-radius: 35px !important;
  -moz-border-radius: 35px !important;
  -ms-border-radius: 35px !important;
  -o-border-radius: 35px !important;

  /* End Call Button Styles */
  display: inline-flex;
  align-items: center;
  justify-content: space-evenly;
  padding: 24px 24px;
  background-color: red; /* Set the button background color to red */
  color: white; /* Set the text color to white */
  border: none;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
`;

  const SmallerStartButton = styled('button')`
  height: 3rem;
  width: 12rem;
  font-size: 1.25rem;
  font-weight: 500;
  border-radius: 35px;
  border: none;
  background-color: ${campaignData?.buttonColor};
  color: ${invertedButtonColor};
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25)) drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  &:visited {
    background-color: ${campaignData?.buttonColor};
    color: ${invertedButtonColor};
  }
  &:active {
    background-color: ${campaignData?.buttonColor};
    color: ${invertedButtonColor};
  }
  &:focus {
    background-color: #ccc;
    color: #000;
  }
`;

  const backgroundColorChange = visibleStage !== "calendly" ? campaignData?.backgroundColor : "white";

  // Required for the animation of text in connecting video piece
  const [activeIndex, setActiveIndex] = useState(0);
  const [fadeIn, setFadeIn] = useState(false);

  React.useEffect(() => {
    const interval = setInterval(() => {
      setFadeIn(true);
      setTimeout(() => {
        setActiveIndex(prevIndex => (prevIndex + 1) % textData.length);
        setFadeIn(false);
      }, 6000); // Adjust the delay to 6000 milliseconds (6 seconds)
    }, 7000); // Adjust the interval to 7000 milliseconds (7 seconds)

    return () => {
      clearInterval(interval);
    };
  }, []);

  // The text pieces that are cycled through on connecting to video
  const textData = [
    {
      text: translate("Almost 30 million people have been helped by NFCC member agencies since 2006.", "Casi 30 millones de personas han recibido ayuda de las agencias miembros de la NFCC desde 2006.")
    },
    {
      text: translate("Credit counseling is a safe, private way to get advice on building and living within a budget.", "El asesoramiento crediticio es una forma segura y privada de obtener consejos sobre cómo elaborar un presupuesto y vivir dentro de él.")
    },
    {
      text: translate("You will leave this session with a personalized Action Plan, to help you meet your specific financial goals.", "Saldrá de esta sesión con un Plan de Acción personalizado, para ayudarle a alcanzar sus objetivos financieros específicos.")
    }
  ];

  const HereLink = styled('span')`
  color: ${campaignData?.linkColor};
  `;

  return (<div style={{ backgroundColor: backgroundColorChange }} id="main">
    <div ref={startRef}></div>

    <Box minHeight="94vh" width="100%" display="flex" justifyContent="center">

      <Box display="flex">

        {!useCampaignContext().isLoading && visibleStage != "calendly" && <><Box position="absolute" top="0" left="0" paddingLeft="space40" paddingTop="space40"><img src={campaignData.logoUrl} width="250" /></Box>

          {!showInterstitial &&
            <>

              {visibleStage == "error" && Object.keys(availability).length == 0 &&
                <>
                  <Box display="flex" flexDirection="column" alignItems="center" rowGap="space100" justifyContent="center">
                    <ErrorIcon decorative={false} title="Error Icon" size="sizeIcon100" color="colorTextInverse" />
                    <Text variant="h1" textAlign="center" as="p" fontSize="fontSize80" color="colorTextInverse" paddingBottom="space200" >
                      {translate("Apologies, an error has occured", "Disculpas, ha ocurrido un error")}
                    </Text>
                  </Box>
                </>
              }

              {visibleStage == "start" &&
                <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">

                  {campaignData.availableServices.length > 1 &&
                    <Box position="fixed" top="0" right="0" padding="space60" display="flex" flexDirection="row">

                      <Button variant="link" onClick={(e => handleClick('English', 'language'))}>
                        {locatorInputs.language == 'English' &&
                          <Text color="colorTextInverse" as="p" fontSize="fontSize40" paddingRight="space20" fontWeight="fontWeightExtrabold">EN</Text>
                          ||
                          <Text color="colorTextInverse" as="p" paddingRight="space20">EN</Text>
                        }
                      </Button>

                      <Text color="colorTextInverse" as="p">  /  </Text>
                      <Button variant="link" onClick={e => handleClick('Spanish', 'language')}>
                        {locatorInputs.language == 'Spanish' &&
                          <Text color="colorTextInverse" as="p" paddingLeft="space20" fontWeight="fontWeightExtrabold" fontSize="fontSize40">ES</Text>
                          ||
                          <Text color="colorTextInverse" as="p" paddingLeft="space20">ES</Text>
                        }
                      </Button>

                    </Box>}

                  {campaignData.availableServices.length > 1 &&
                    <Box position="fixed" top="0" right="0" padding="space60" display="flex" flexDirection="row">

                      <Button variant="link" onClick={(e => handleClick('English', 'language'))}>
                        {locatorInputs.language == 'English' &&
                          <Text color="colorTextInverse" as="p" fontSize="fontSize40" paddingRight="space20" fontWeight="fontWeightExtrabold">EN</Text>
                          ||
                          <Text color="colorTextInverse" as="p" paddingRight="space20">EN</Text>
                        }
                      </Button>

                      <Text color="colorTextInverse" as="p">  /  </Text>
                      <Button variant="link" onClick={e => handleClick('Spanish', 'language')}>
                        {locatorInputs.language == 'Spanish' &&
                          <Text color="colorTextInverse" as="p" paddingLeft="space20" fontWeight="fontWeightExtrabold" fontSize="fontSize40">ES</Text>
                          ||
                          <Text color="colorTextInverse" as="p" paddingLeft="space20">ES</Text>
                        }
                      </Button>

                    </Box>}

                  <Text as="h1" variant="heading10" color="colorTextInverse" fontSize="fontSize90" paddingBottom="space120" >{translate("Begin Improving Your Finances Today", "Empiece hoy mismo a mejorar sus finanzas")}</Text>

                  <Box display="flex" flexDirection="column" alignItems="center" rowGap="space120" marginBottom="space120" width="100%" textAlign="center">
                    <Text as="p" color="colorTextInverse" fontSize="fontSize60">{translate("Almost 30 million people have been helped by NFCC member agencies since 2006.", "Casi 30 millones de personas han sido ayudadas por las agencias miembros de NFCC desde 2006.")}</Text>
                    <Text as="p" color="colorTextInverse" fontSize="fontSize60">{translate("Credit counseling is a safe, private way to get advice on building and living within a budget.", "El asesoramiento crediticio es una forma segura y privada de obtener consejos sobre cómo establecer y vivir dentro de un presupuesto.")}</Text>
                    <Text as="p" color="colorTextInverse" fontSize="fontSize60">{translate("You will leave this session with a  personalized Action Plan, to help you meet your specific financial goals.", "Al finalizar esta sesión, recibirás un Plan de Acción personalizado para ayudarte a alcanzar tus metas financieras específicas.")}</Text>
                  </Box>

                  <Box display="flex" flexDirection="column" rowGap="space200" alignItems="center">

                    <StartButton id='start' onClick={() => { setIsQuickConnect(true); start() }}>
                      {translate("Let's Get Started", "Empecemos")}
                    </StartButton>

                    {/* <StartButton id='start' onClick={start}>
                      {translate("Get Connected", "Conéctese")}
                    </StartButton> */}

                    {campaignData.isCodeEnabled == 1 &&
                      <Box display="flex" justifyContent="center" flexDirection="row" alignItems="center" rowGap="space40">

                        <Text as="p" color="colorTextInverse" fontSize="fontSize60">{translate('Already connected with an agency? Click ', '¿Ya está en contacto con una agencia? Haz clic ')}<HereLink id="code" onClick={alreadyConnected}>
                          {translate("here", "aquí")}
                        </HereLink>.</Text>

                        {/* <Text as="p" color="colorTextInverse" fontSize="fontSize60">{translate("Already have a code? Click ", "¿Ya tiene un código? Haz clic ")}<HereLink id="code" onClick={enterCode}>
                          {translate("here", "aquí")}
                        </HereLink>. {translate("Or enter your email. Click ", "O introduzca su correo electrónico. Haga clic en ")}<HereLink id="code" onClick={enterEmail}>{translate("here", "aquí")}</HereLink></Text> */}
                      </Box>
                    }
                  </Box>

                  <Box>

                  </Box>

                </Box>
              }

              {/*visibleStage == "start" && campaignData.availableLanguages.length > 1
                && <Box position="absolute" top="0" left="0" paddingTop="space200" marginTop="space50">

                  <Button id='start-back' variant="inverse_link" onClick={e => back("language")}>
                    <ChevronLeftIcon decorative={false} title="Back" />
                    {translate('back', 'atrás')}
                  </Button>
                </Box>
            */}

              {visibleStage == "language"
                && <Stack orientation="horizontal" spacing="space100" >
                  <ActionButton key='language-en' id='language-en' name="language" value='English' onClick={e => handleClick('English', 'language')}>
                    English
                  </ActionButton>
                  <ActionButton key='language-es' id='language-es' name="language" value='Spanish' onClick={e => handleClick('Spanish', 'language')}>
                    Español
                  </ActionButton>
                </Stack>
              }

              {visibleStage == "service"
                && <Box position="absolute" top="0" left="0" paddingTop="space200" marginTop="space50">

                  <Button id='service-back' variant="inverse_link" onClick={e => back("start")}>
                    <ChevronLeftIcon decorative={false} title="Back" />
                    {translate('back', 'atrás')}
                  </Button>
                </Box>
              }

              {visibleStage == "service"
                && <Box display="flex" flexDirection="column" paddingTop="space200" marginTop="space200" rowGap="space100">
                  <Text variant="h1" textAlign="center" as="p" fontSize="fontSize80" color="colorTextInverse">
                    {translate("I'm looking for help with:", "Estoy buscando ayuda con:")}
                  </Text>
                  <Box display="flex" flexDirection="row" columnGap="space200" width="99%" rowGap="space100" flexWrap="wrap" justifyContent="center">
                    {campaignData.availableServices &&
                      campaignData.availableServices.map((s: string, index: number) => (
                        <ServiceButton id={'service-' + index} key={'service-' + index} name="service" value={s} onClick={e => handleClick(s, 'service')}>
                          {translateService(s)}
                        </ServiceButton>
                      ))}
                  </Box>
                </Box>
              }

              {visibleStage == "prebooking" && campaignData.availableServices.length > 1
                && <Box position="absolute" top="0" left="0" paddingTop="space200" marginTop="space50">
                  <Button id='service-back' variant="inverse_link" onClick={e => back("service")}>
                    <ChevronLeftIcon decorative={false} title="Back" />
                    {translate('back', 'atrás')}
                  </Button>
                </Box>
              }

              {visibleStage == "prebooking" && campaignData.availableServices.length == 1
                && <Box position="absolute" top="0" left="0" paddingTop="space200" paddingLeft="space60" marginTop="space50">
                  <Button id='service-back' variant="inverse_link" onClick={e => back("start")}>
                    <ChevronLeftIcon decorative={false} title="Back" />
                    {translate('back', 'atrás')}
                  </Button>
                </Box>
              }


              {visibleStage == "form" && <Box display="flex" flexDirection="column" paddingTop="space200" marginTop="space200" rowGap="space100" alignItems="center">
                <Box paddingBottom="space60" paddingRight="space60">
                  <Text as="h1" color="colorTextInverse" fontSize="fontSize80" fontWeight="fontWeightMedium">
                    {translate('Please enter the following details:', 'Por favor ingrese las detalles siguientes:')}
                  </Text>
                </Box>

                <Box display="flex" flexDirection="row" columnGap="space100" justifyContent="space-between">

                  <Box width="260px">
                    <Label variant="inverse" htmlFor="firstName">{translate('First name', 'Nombre')}</Label>
                    <Input
                      id="firstName"
                      name="firstName"
                      type="text"
                      onChange={handleChange}
                      value={locatorInputs.firstName}
                      required
                      hasError={fNameError.length > 0}
                      placeholder={translate("required", "obligatorio")}
                    ></Input>
                    {fNameError.length > 0 && <Box flexDirection="row" columnGap="space20" display="flex" alignItems="flex-end">
                      <ErrorIcon decorative={false} title="First Name Error icon" color="colorTextBrandInverse" />
                      <Text paddingTop="space20" as="p" fontSize="fontSize30" color="colorTextInverse">
                        {fNameError}</Text>
                    </Box>}
                  </Box>

                  <Box width="260px">
                    <Label variant="inverse" htmlFor="lastName"> {translate('Last name', 'Apellido')}</Label>
                    <Input
                      id="lastName"
                      name="lastName"
                      type="text"
                      onChange={handleChange}
                      value={locatorInputs.lastName}
                      required
                      hasError={lNameError.length > 0}
                      placeholder={translate("required", "obligatorio")}
                    ></Input>
                    {lNameError.length > 0 && <Box flexDirection="row" columnGap="space20" display="flex" alignItems="flex-end">
                      <ErrorIcon decorative={false} title="Last Name Error icon" color="colorTextBrandInverse" />
                      <Text paddingTop="space20" as="p" fontSize="fontSize30" color="colorTextInverse">
                        {lNameError}</Text>
                    </Box>}
                  </Box>
                </Box>

                <Box display="flex" flexDirection="row" columnGap="space100" justifyContent="space-between">

                  <Box width="260px">
                    <Label variant="inverse" htmlFor="email">{translate('Email', 'Correo electrónico')}</Label>
                    <Input
                      id="email"
                      name="email"
                      type="email"
                      onChange={handleChange}
                      value={locatorInputs.email}
                      required
                      hasError={emailError.length > 0}
                      placeholder={translate("required", "obligatorio")}
                    ></Input>
                    {emailError.length > 0 && <Box flexDirection="row" columnGap="space20" display="flex" alignItems="flex-end">
                      <ErrorIcon decorative={false} title="Email Error icon" color="colorTextBrandInverse" />
                      <Text paddingTop="space20" as="p" fontSize="fontSize30" color="colorTextInverse">
                        {emailError}</Text>
                    </Box>}
                  </Box>

                  <Box width="260px">
                    <Label variant="inverse" htmlFor="phone"> {translate('Phone', 'Teléfono')}</Label>
                    <Input
                      id="phone"
                      name="phone"
                      type="tel"
                      onChange={handleChange}
                      value={locatorInputs.phone}
                      required
                      hasError={phoneError.length > 0}
                      placeholder={translate("required", "obligatorio")}
                    ></Input>
                    {phoneError.length > 0 && <Box flexDirection="row" columnGap="space20" display="flex" alignItems="flex-end">
                      <ErrorIcon decorative={false} title="Phone Number Error icon" color="colorTextBrandInverse" />
                      <Text paddingTop="space20" as="p" fontSize="fontSize30" color="colorTextInverse">
                        {phoneError}</Text>
                    </Box>}
                  </Box>

                </Box>

                {campaignData.type == "web" &&
                  <Box width="260px" paddingBottom="space60" paddingRight="space60">
                    <Label variant="inverse" htmlFor="zipCode"> {translate('Zip code', 'Código postal')}</Label>
                    <Input
                      id="zipCode"
                      name="zipCode"
                      type="text"
                      onChange={handleChange}
                      value={locatorInputs.zipCode}
                      required
                      hasError={zipError.length > 0}
                      placeholder={translate("required", "obligatorio")}
                    ></Input>
                    {zipError.length > 0 && <Box flexDirection="row" columnGap="space20" display="flex" alignItems="flex-end">
                      <ErrorIcon decorative={false} title="Zip Code Error icon" color="colorTextBrandInverse" />
                      <Text paddingTop="space20" as="p" fontSize="fontSize30" color="colorTextInverse">
                        {zipError}</Text>
                    </Box>}
                  </Box>
                }

                <Box
                  display="flex"
                  paddingTop="space50"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Checkbox
                    checked={consent}
                    id="consent"
                    value="consent"
                    name="consent"
                    onChange={(event) => {
                      setConsent(event.target.checked);
                      setFormValid(validate('change', locatorInputs))
                    }}
                  >
                    <Label variant="inverse" htmlFor="consent">
                      {translate("The NFCC and its member agencies may contact me by video, phone, email or text", "La NFCC y sus agencias miembros pueden comunicarse conmigo por video, teléfono, correo electrónico o mensaje de texto.")}
                    </Label>

                  </Checkbox></Box>


                {formValid && consent &&
                  <StartButton id='get-connected' onClick={e => handleGetConnected(null)}>
                    {translate('Get Connected', 'Conéctase')}
                  </StartButton>
                }

                {(!formValid || !consent) &&
                  <StartButtonDisabled id='get-connected' >
                    {translate('Get Connected', 'Conéctase')}
                  </StartButtonDisabled>
                }

              </Box>}

              {availability && visibleStage == "error" && Object.keys(availability).length > 0 &&

                <Box display="flex" flexDirection="column" rowGap="space100" alignItems="center" justifyContent="center">
                  <Box width="60%" textAlign="center">
                    <Text as="h1" color="colorTextInverse" fontSize="fontSize80" lineHeight="lineHeight80">
                      {translate("Sorry! It looks like we are experiencing some technical difficulties with this app. (Next steps TBD)",
                        "¡Lo siento! Parece que estamos experimentando algunas dificultades técnicas con esta aplicación.")}
                    </Text>
                  </Box>
                  <ActionButton id='language-en' name="language" value='English' onClick={end}>
                    {translate("Start again", "Iniciar de nuevo")}
                  </ActionButton>
                </Box>
              }

              {['prebooking', 'none-available', 'duplicate', 'calendly'].includes(visibleStage) && campaignData.availableServices.length > 1
                && <Box position="absolute" top="0" left="0" paddingTop="space200" marginTop="space50">

                  <Button id='service-back' variant="inverse_link" onClick={e => back("service")}>
                    <ChevronLeftIcon decorative={false} title="Back" />
                    {translate('back', 'atrás')}
                  </Button>
                </Box>
              }

              {/*['prebooking', 'none-available', 'duplicate', 'calendly'].includes(visibleStage) && campaignData.availableServices.length == 1 && campaignData.availableLanguages.length > 1
                && <Box position="absolute" top="0" left="0" paddingTop="space200" marginTop="space50">

                  <Button id='service-back' variant="inverse_link" onClick={e => back("language")}>
                    <ChevronLeftIcon decorative={false} title="Back" />
                    {translate('back', 'atrás')}
                  </Button>
                </Box>
            */}

              {['prebooking', 'none-available', 'duplicate', 'calendly'].includes(visibleStage) && campaignData.availableServices.length == 1 && campaignData.availableLanguages.length == 1
                && <Box position="absolute" top="0" left="0" paddingTop="space200" marginTop="space50">

                  <Button id='service-back' variant="inverse_link" onClick={e => back("start")}>
                    <ChevronLeftIcon decorative={false} title="Back" />
                    {translate('back', 'atrás')}
                  </Button>
                </Box>
              }


              {availability && visibleStage == "prebooking" &&
                <Box display="flex" flexDirection="column" rowGap="space100" alignItems="center" paddingTop="space200" marginTop="space200">
                  <Box width="60%" textAlign="center">
                    <Text as="h1" color="colorTextInverse" fontSize="fontSize80" lineHeight="lineHeight80" fontWeight="fontWeightMedium">
                      {translate("No counselors are available right now but we can schedule a future appointment for you, would you like to see available appointments?", "No hay consejeros disponibles en este momento, pero podemos programar una cita futura para usted, ¿le gustaría ver las citas disponibles?")}
                    </Text>
                  </Box>

                  <Box display="flex" flexDirection="row" columnGap="space100" justifyContent="space-between">

                    <Box width="260px">
                      <Label variant="inverse" htmlFor="firstName">{translate('First name', 'Nombre')}</Label>
                      <Input
                        id="firstName"
                        name="firstName"
                        type="text"
                        onChange={handleChange}
                        value={locatorInputs.firstName}
                        required
                        hasError={fNameError.length > 0}
                        placeholder={translate("required", "obligatorio")}
                      ></Input>
                      {fNameError.length > 0 && <Box flexDirection="row" columnGap="space20" display="flex" alignItems="flex-end">
                        <ErrorIcon decorative={false} title="First Name Error icon" color="colorTextBrandInverse" />
                        <Text paddingTop="space20" as="p" fontSize="fontSize30" color="colorTextInverse">
                          {fNameError}</Text>
                      </Box>}
                    </Box>

                    <Box width="260px">
                      <Label variant="inverse" htmlFor="lastName"> {translate('Last name', 'Apellido')}</Label>
                      <Input
                        id="lastName"
                        name="lastName"
                        type="text"
                        onChange={handleChange}
                        value={locatorInputs.lastName}
                        required
                        hasError={lNameError.length > 0}
                        placeholder={translate("required", "obligatorio")}
                      ></Input>
                      {lNameError.length > 0 && <Box flexDirection="row" columnGap="space20" display="flex" alignItems="flex-end">
                        <ErrorIcon decorative={false} title="Last Name Error icon" color="colorTextBrandInverse" />
                        <Text paddingTop="space20" as="p" fontSize="fontSize30" color="colorTextInverse">
                          {lNameError}</Text>
                      </Box>}
                    </Box>
                  </Box>

                  <Box display="flex" flexDirection="row" columnGap="space100" justifyContent="space-between">

                    <Box width="260px">
                      <Label variant="inverse" htmlFor="email">{translate('Email', 'Correo electrónico')}</Label>
                      <Input
                        id="email"
                        name="email"
                        type="email"
                        onChange={handleChange}
                        value={locatorInputs.email}
                        required
                        hasError={emailError.length > 0}
                        placeholder={translate("required", "obligatorio")}
                      ></Input>
                      {emailError.length > 0 && <Box flexDirection="row" columnGap="space20" display="flex" alignItems="flex-end">
                        <ErrorIcon decorative={false} title="Email Error icon" color="colorTextBrandInverse" />
                        <Text paddingTop="space20" as="p" fontSize="fontSize30" color="colorTextInverse">
                          {emailError}</Text>
                      </Box>}
                    </Box>

                    <Box width="260px">
                      <Label variant="inverse" htmlFor="phone"> {translate('Phone', 'Teléfono')}</Label>
                      <Input
                        id="phone"
                        name="phone"
                        type="tel"
                        onChange={handleChange}
                        value={locatorInputs.phone}
                        required
                        hasError={phoneError.length > 0}
                        placeholder={translate("required", "obligatorio")}
                      ></Input>
                      {phoneError.length > 0 && <Box flexDirection="row" columnGap="space20" display="flex" alignItems="flex-end">
                        <ErrorIcon decorative={false} title="Phone Number Error icon" color="colorTextBrandInverse" />
                        <Text paddingTop="space20" as="p" fontSize="fontSize30" color="colorTextInverse">
                          {phoneError}</Text>
                      </Box>}
                    </Box>

                  </Box>

                  {campaignData.type == "web" &&
                    <Box width="260px" paddingBottom="space60" paddingRight="space60">
                      <Label variant="inverse" htmlFor="zipCode"> {translate('Zip code', 'Código postal')}</Label>
                      <Input
                        id="zipCode"
                        name="zipCode"
                        type="text"
                        onChange={handleChange}
                        value={locatorInputs.zipCode}
                        required
                        hasError={zipError.length > 0}
                        placeholder={translate("required", "obligatorio")}
                      ></Input>
                      {zipError.length > 0 && <Box flexDirection="row" columnGap="space20" display="flex" alignItems="flex-end">
                        <ErrorIcon decorative={false} title="Zip Code Error icon" color="colorTextBrandInverse" />
                        <Text paddingTop="space20" as="p" fontSize="fontSize30" color="colorTextInverse">
                          {zipError}</Text>
                      </Box>}
                    </Box>
                  }

                  <Box
                    display="flex"
                    paddingTop="space50"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Checkbox
                      checked={consent}
                      id="consent"
                      value="consent"
                      name="consent"
                      onChange={(event) => {
                        setConsent(event.target.checked);
                        setFormValid(validate('change', locatorInputs))
                      }}
                    >
                      <Label variant="inverse" htmlFor="consent">
                        {translate("The NFCC and its member agencies may contact me by video, phone, email or text", "La NFCC y sus agencias miembros pueden comunicarse conmigo por video, teléfono, correo electrónico o mensaje de texto.")}
                      </Label>

                    </Checkbox></Box>

                  {formValid && consent &&
                    <StartButton id='get-connected' onClick={e => handleGetConnected(null)}>
                      {translate("Book an appointment", "Programar una cita")}
                    </StartButton>
                  }

                  {(!formValid || !consent) &&
                    <StartButtonDisabled id='get-connected' >
                      {translate("Book an appointment", "Programar una cita")}
                    </StartButtonDisabled>
                  }

                </Box>
              }

              {availability && visibleStage == "none-available" &&

                <Box display="flex" flexDirection="column" paddingTop="space200" marginTop="space200" rowGap="space100" alignItems="center">
                  <Box width="60%">
                    <Text as="h1" color="colorTextInverse" fontSize="fontSize80" textAlign="center" lineHeight="lineHeight60" fontWeight="fontWeightMedium">
                      {translate("Sorry, there are no counselors available at the moment but we have let the following agency know to reach out to you using the contact information you submitted. You will also receive an email with other ways to get in touch.",
                        "Lo sentimos, no hay consejeros disponibles en este momento, pero le hemos avisado a la siguiente agencia para que se comunique con usted utilizando la información de contacto que envió. También recibirá un correo electrónico con otras formas de ponerse en contacto.")}
                    </Text>
                  </Box>

                  <Box display="flex" flexDirection="column" rowGap="space50" alignItems="center">
                    <Text as="h2" color="colorTextInverse" fontSize="fontSize60">
                      {locatorOutput.agencyName}
                    </Text>
                    {Object.keys(locatorOutput).includes('agencyEmail') && <Text as="h3" color="colorTextInverse" fontSize="fontSize40">
                      {translate('Email:', 'Correo electrónico:')} {locatorOutput.agencyEmail}
                    </Text>}
                    {Object.keys(locatorOutput).includes('agencyPhone') && <Text as="h3" color="colorTextInverse" fontSize="fontSize40">
                      {translate('Phone:', 'Teléfono:')} {locatorOutput.agencyPhone}
                    </Text>}
                  </Box>
                </Box>
              }

              {visibleStage == "duplicate" &&

                <Box display="flex" flexDirection="column" alignItems="center" paddingTop="space100" marginTop="space100" justifyContent={isCode && "center" || "flex-start"}>
                  <Box display="flex" flexDirection="column" rowGap="space50" width={isCode && "99%" || "80%"} justifyContent="start">
                    <Box
                      padding="space40"
                      display="flex"
                      width="90%"
                      alignItems="center"
                      textAlign="center"
                      margin="auto"
                    >
                      {locatorOutput.available &&
                        <Box display="flex" flexDirection="column" rowGap="space120" alignItems="center" justifyContent="space-between">
                          <Text as="h1" color="colorTextInverse" fontSize="fontSize60" lineHeight="lineHeight80" fontWeight="fontWeightMedium">
                            {translate("We can see you have already connected with " + locatorOutput.agencyName + ', would you like to reconnect with them?',
                              'Vemos que ya se conectó con ' + locatorOutput.agencyName + '. ¿Le gustaría volver a conectarse con ellos?')}
                          </Text>

                          <StartButton value='yes' onClick={fn => { startVideo() }} >
                            {translate('Connect now (average wait time ', 'Conéctese ahora (tiempo de espera promedio de ')}{!Object.keys(locatorOutput).includes('waitTimeSecs') && 5 || Number.isNaN(locatorOutput.waitTimeSecs) && 5 || locatorOutput.waitTimeSecs == 0 && 1 || Math.ceil(locatorOutput.waitTimeSecs / 60)}{locatorOutput.waitTimeSecs <= 60 && translate(' minute', ' minuto') || translate(' minutes', ' minutos')}
                          </StartButton>
                        </Box>
                      }
                      {!locatorOutput.available && (locatorOutput.calendlyUrl?.length == 0 || !locatorOutput.calendlyUrl) && //|| locatorOutput.otherAvailability.length == 0) &&
                        <Box display="flex" flexDirection="column" rowGap="space120" alignItems="center">
                          <Text as="h1" color="colorTextInverse" fontSize="fontSize60" lineHeight="lineHeight80" fontWeight="fontWeightMedium">
                            {translate("We can see you have already connected with " + locatorOutput.agencyName + ", unfortunately they are not available to connect by video right now",
                              "Vemos que ya se conectó con " + locatorOutput.agencyName + ", desafortunadamente no están disponibles para conectarse por video en este momento")}{isCode && translate('. We have let them know you are waiting in a booth. They may try to contact you by phone or email as well. ', '. Les hemos hecho saber que está esperando en una cabina. Es posible que también intenten comunicarse con usted por teléfono o correo electrónico.')}
                          </Text>

                          <Text as="h2" color="colorTextInverse" fontSize="fontSize60">
                            {locatorOutput.agencyName}
                          </Text>
                          {Object.keys(locatorOutput).includes('agencyEmail') && <Text as="h3" color="colorTextInverse" fontSize="fontSize40">
                            {translate('Email:', 'Correo electrónico:')} {locatorOutput.agencyEmail}
                          </Text>}
                          {Object.keys(locatorOutput).includes('agencyPhone') && <Text as="h3" color="colorTextInverse" fontSize="fontSize40">
                            {translate('Phone:', 'Teléfono:')} {locatorOutput.agencyPhone}
                          </Text>
                          }
                          {Object.keys(locatorOutput).includes('agencyWebsite') && <Text as="h3" color="colorTextInverse" fontSize="fontSize40">
                            {translate('Web:', 'El sitio web:')} {locatorOutput.agencyWebsite}
                          </Text>
                          }

                        </Box>

                      }
                      {!locatorOutput.available && (locatorOutput.calendlyUrl?.length > 0) &&
                        <Box display="flex" flexDirection="column" rowGap="space120" alignItems="center">
                          <Text as="h1" color="colorTextInverse" fontSize="fontSize60" lineHeight={["lineHeight40", "lineHeight80"]} textAlign="center" fontWeight="fontWeightMedium" paddingBottom="space40">
                            {translate("We can see you have already connected with " + locatorOutput.agencyName + ", unfortunately they are not available to connect by video right now.",
                              "Vemos que ya se conectó con " + locatorOutput.agencyName + ", desafortunadamente no están disponibles para conectarse por video en este momento.")}
                          </Text>
                          <Text as="p" color="colorTextInverse" fontSize="fontSize40" paddingBottom="space40">{translate("To reconnect via video click the button below to schedule an appointment with them.",
                            "Para volver a conectarse por video, haga clic en el siguiente botón para programar una cita con ellos.")}

                          </Text>
                          <StartButton onClick={handleCalendly}>
                            {translate("Book an appointment", "Programar una cita")}
                          </StartButton>

                        </Box>

                      }
                    </Box>

                    {!isCode &&
                      <Separator orientation="horizontal" verticalSpacing="space100" />
                    }

                  </Box>

                  {!isCode && <Box display="flex" flexDirection="column" width="80%" justifyContent="space-between">
                    <Box
                      display="flex"
                      flexDirection="column"
                      padding="space40"
                      alignItems="center"
                      textAlign="center"
                    >
                      <Box display="flex">
                        <Text as="h2" color="colorTextInverse" fontSize="fontSize60" fontWeight="fontWeightMedium" lineHeight="lineHeight80" textAlign="center" paddingBottom="space60">
                          {translate("Or you can connect with another agency", "Conectar con otra agencia")}
                        </Text>
                      </Box>

                      {/* No others available, no others bookable*/}
                      {(locatorOutput.otherAvailability.length == 0 || (!((locatorOutput.otherAvailability as []).find(({ available }) => available == true)) && !((locatorOutput.otherAvailability as []).find(({ calendlyUrl }) => calendlyUrl !== '')))) &&
                        <Box display="flex" flexDirection="column" alignItems="center">
                          <StartButton onClick={handleDuplicateOverride}>
                            {translate("Get connected with a new Agency", "Conéctese con una nueva agencia")}
                          </StartButton></Box>
                      }

                      {/* No others available, others bookable*/}
                      {locatorOutput.otherAvailability.length > 0 && ((locatorOutput.otherAvailability as []).find(({ calendlyUrl }) => calendlyUrl !== ''))
                        && !locatorOutput.otherAvailability[0].available &&
                        <Box display="flex" flexDirection="column">
                          <StartButton value="yes" onClick={handleDuplicateOverride}>
                            {translate("Book an appointment with a new agency", "Programar una cita con una nueva agencia")}
                          </StartButton>
                        </Box>
                      }

                      {/* Others available*/}
                      {locatorOutput.otherAvailability.length > 0 && locatorOutput.otherAvailability[0].available &&
                        <Box display="flex" flexDirection="column">
                          <Text as="p" color="colorTextInverse" fontSize="fontSize40" paddingBottom="space60">
                            {translate("Average wait time: ", "tiempo de espera promedio de ")}{!Object.keys(locatorOutput.otherAvailability[0].availability).includes('waitTimeSecs') && 5 || Number.isNaN(locatorOutput.waitTimeSecs) && 5 || locatorOutput.otherAvailability[0].availability.waitTimeSecs == 0 && 1 || Math.ceil(locatorOutput.otherAvailability[0].availability.waitTimeSecs / 60)}{locatorOutput.otherAvailability[0].availability.waitTimeSecs <= 60 && translate(' minute', ' minuto') || translate(' minutes', ' minutos')}

                          </Text>
                          <StartButton value="yes" onClick={handleDuplicateOverride}>
                            {translate("Get connected with a new Agency", "Conéctese con una nueva agencia")}
                          </StartButton>
                        </Box>
                      }

                    </Box>
                  </Box>}
                </Box>
              }
            </>}
        </>}

        {
          visibleStage == "video" && !ended &&
          <Box display="flex" flexDirection="column" rowGap="space60" alignItems="center" margin="auto" paddingTop="space200">
            {!attended && <Box position="relative" minHeight="400px">
              <Box display="flex" flexDirection="column" rowGap="space100" alignItems="center" width="100%" textAlign="center" margin="auto">
                {(queuePosition ?? -1) < 0 && <Text color="colorTextInverse" as="h3" fontSize="fontSize80" paddingTop="space120" fontWeight="fontWeightMedium" lineHeight="lineHeight80">
                  {translate('Connecting...', 'Conectando...')} </Text>}
                {(queuePosition ?? -1) > 0 && <Text color="colorTextInverse" as="h3" fontSize="fontSize80" paddingTop="space120" fontWeight="fontWeightMedium" lineHeight="lineHeight80">

                  {translate("Wait time is approximately ", "El tiempo de espera es de aproximadamente ")}{!Object.keys(locatorOutput).includes('waitTimeSecs') && 5 || Number.isNaN(locatorOutput.waitTimeSecs) && 5 || locatorOutput.waitTimeSecs == 0 && 1 || Math.ceil(locatorOutput.waitTimeSecs / 60)}{locatorOutput.waitTimeSecs <= 60 && translate(' minute', ' minuto') || translate(' minutes', ' minutos')},
                  <br />
                  {queuePosition == 1 && translate(`there is ${queuePosition} caller ahead of you`, `hay ${queuePosition} persona llamando antes que usted`) || (queuePosition ?? -1) > 0 && translate(`there are ${queuePosition} callers ahead of you`, `hay ${queuePosition} personas delante de ti`)}

                </Text>}

                {/* { Fade in and fade out text piece } */}
                <div className="cycle-text">
                  {textData.map((data, index) => (
                    <p
                      key={index}
                      className={`text ${index === activeIndex ? 'active' : ''} ${fadeIn && index === activeIndex ? 'fade-in' : ''}`}
                    >
                      {data.text}
                    </p>
                  ))}
                </div>

              </Box>
            </Box>}
            <Box position="relative" minHeight="400px" alignContent="center" flexDirection="column" alignItems="flex-start" display="flex">
              <Box ref={localVideoMedia} position="absolute" width="30%" borderRadius="borderRadius30" overflow="hidden" zIndex="zIndex90" top="0" left="0" hidden={!attended}>
              </Box>

              <Box ref={remoteVideoMedia} position="relative" borderRadius="borderRadius30" overflow="hidden" zIndex="zIndex50">
              </Box>
              {!attended && <Box display="flex" columnGap="space200" rowGap="space100" alignContent="center" maxHeight="50px" width="max-content" flexDirection="row" paddingTop="space30" id='buttonsQueue'>
                {!isMuted && <MuteButton onClick={() => video?.mute()}>
                  <MicrophoneOffIcon size="sizeIcon20" aria-label="Mute Microphone" title="Mute Microphone" decorative={false} /> {translate("Mute", "Mudo")}
                </MuteButton>}
                {isMuted && <MuteButton onClick={() => video?.unmute()} >
                  <MicrophoneOnIcon size="sizeIcon20" aria-label="Unmute Microphone" title="Unmute Microphone" decorative={false} /> {translate("Unmute", "Activar")}
                </MuteButton>}
                <EndCallButton id='end-call' name="end-call" onClick={handleDisconnect}>
                  <CallFailedIcon size="sizeIcon20" decorative={false} title="End Call icon" /> {translate("End Call", "Finalizar llamada")}
                </EndCallButton>
              </Box>}
            </Box>
            {attended && <Box display="flex" columnGap="space200" rowGap="space100" alignItems="center" maxHeight="50px" width="max-content" flexDirection="row" paddingTop="space30" id='buttonsLive'>
              {!isMuted && <MuteButton onClick={() => video?.mute()}>
                <MicrophoneOffIcon size="sizeIcon20" aria-label="Mute Microphone" title="Mute Microphone" decorative={false} /> {translate("Mute", "Mudo")}
              </MuteButton>}
              {isMuted && <MuteButton onClick={() => video?.unmute()}>
                <MicrophoneOnIcon size="sizeIcon20" aria-label="Unmute Microphone" title="Unmute Microphone" decorative={false} /> {translate("Unmute", "Activar")}
              </MuteButton>}
              <EndCallButton id='end-call' name="end-call" onClick={handleDisconnect}>
                <CallFailedIcon size="sizeIcon20" decorative={false} title="End Call icon" /> {translate("End Call", "Finalizar llamada")}
              </EndCallButton>
            </Box>}


          </Box>
        }

        {
          visibleStage == "post-calendly" &&
          <Box display="flex" columnGap="space80" rowGap="space100" width="60%" textAlign="center" margin="auto" flexDirection="column" alignItems="center">
            <Text as="p" color="colorTextInverse" fontSize="fontSize80" lineHeight="lineHeight80" fontWeight="fontWeightMedium">
              {translate("Great job on taking the first step toward your financial independence!",
                "¡Gran trabajo al dar el primer paso hacia tu independencia financiera!")}
            </Text>
            <Text as="p" color="colorTextInverse" fontSize="fontSize80" lineHeight="lineHeight80" fontWeight="fontWeightMedium">
              {translate("You'll receive a meeting invite... copy needed",
                "You'll receive a meeting invite... copy needed SPANISH")}

            </Text>



            <EndCallButton id='language-en' name="endCall" value='End' onClick={end}>
              {translate("End", "Finalizar")}
            </EndCallButton>
          </Box>
        }



        {
          visibleStage == "video" && ended &&
          <Box display="flex" columnGap="space80" rowGap="space100" width="60%" textAlign="center" margin="auto" flexDirection="column" alignItems="center">
            <Text as="p" color="colorTextInverse" fontSize="fontSize80" lineHeight="lineHeight80" fontWeight="fontWeightMedium">
              {translate("Great job on taking the first step toward your financial independence!",
                "¡Gran trabajo al dar el primer paso hacia tu independencia financiera!")}
            </Text>
            <Text as="p" color="colorTextInverse" fontSize="fontSize80" lineHeight="lineHeight80" fontWeight="fontWeightMedium">
              {translate("If you need to connect with this agency again from a booth, please enter the following code: ",
                "Si necesita conectarse nuevamente con esta agencia desde una cabina, ingrese el siguiente código: ")}
              <br />
              <br />
              <Text paddingBottom="space80" as="p" fontSize="fontSize100" fontWeight="fontWeightBold" color="colorTextInverse">{locatorOutput?.code}</Text>
            </Text>

            <Text as="p" color="colorTextInverse" fontSize="fontSize60">{translate("Want these details by email? Click ", "¿Quiere recibirlo por correo electrónico? Haga clic en ")}<HereLink id="code" onClick={enterPostCall}>
              {translate("here", "aquí")}
            </HereLink></Text>

            <EndCallButton id='language-en' name="endCall" value='End Call' onClick={end}>
              {translate("End", "Finalizar")}
            </EndCallButton>
          </Box>
        }

        {

          visibleStage == 'calendly' && <>
            <Box position="absolute" top="0" left="0" paddingLeft="space40" paddingTop="space40"><img src={Logo} width="250" /></Box>
            <Box display="flex" flexDirection="row" paddingLeft="space100" columnGap="space100">
              {/* <Box display="flex" flexDirection="column" rowGap="space60" justifyContent="center" paddingRight="space200">
                <Text as="p" fontSize="fontSize80" lineHeight="lineHeight80" fontWeight="fontWeightMedium">
                  {translate("Agency Details", "Detalles de la Agencia")}
                </Text>
                <Text as="p" fontSize="fontSize60" lineHeight="lineHeight80" fontWeight="fontWeightNormal">
                  {translate("Name:", "Nombre:")}
                  <CalendlyText as="span" fontSize="fontSize60">{" " + locatorOutput.agencyName}</CalendlyText>
                </Text>
                <Text as="p" fontSize="fontSize60" lineHeight="lineHeight80" fontWeight="fontWeightNormal">
                  {translate("Email:", "Correo electrónico:")}
                  <CalendlyText as="span" fontSize="fontSize60">{" " + locatorOutput.agencyEmail}</CalendlyText>
                </Text>
                <Text as="p" fontSize="fontSize60" lineHeight="lineHeight80" fontWeight="fontWeightNormal">
                  {translate("Phone:", "Teléfono:")}
                  <CalendlyText as="span" fontSize="fontSize60">{" " + locatorOutput.agencyPhone}</CalendlyText>
                </Text>
                <Text as="p" fontSize="fontSize60" lineHeight="lineHeight80" fontWeight="fontWeightNormal">
                  {translate("Web:", "El sitio web:")}
                  <CalendlyText as="span" fontSize="fontSize60">{" " + locatorOutput.agencyWebsite}</CalendlyText>
                </Text>
              </Box>
              <Separator orientation="vertical" verticalSpacing="space200" /> */}
              <Box>
                <InlineWidget url={locatorOutput.calendlyUrl} pageSettings={{
                  backgroundColor: 'ffffff',
                  hideEventTypeDetails: true,
                  hideLandingPageDetails: true,
                  primaryColor: '000000',
                  textColor: '4d5055',
                  hideGdprBanner: true,
                }} prefill={{
                  email: locatorInputs.email,
                  firstName: locatorInputs.firstName,
                  lastName: locatorInputs.lastName,
                  name: locatorInputs.firstName + ' ' + locatorInputs.lastName + ' (' + locatorInputs.service + ' - ' + locatorInputs.language + ' - Video)',
                  customAnswers: {
                    a1: (connectionAgent as any).code + translate(" is your code to start your next video session.",
                      " es su código para iniciar su próxima sesión de vídeo")
                      +
                      translate("\n\nIf you plan to connect with your counselor using your own device, please click on the following link at your scheduled appointment time: ",
                        "\n\nSi planea conectarse con su consejero usando su propio teléfono celular, haga clic en el siguiente enlace a la hora programada de su cita:")
                      + (appState.isProd && 'https://video.nfcc.org/a/' || 'https://nfcc-video-app-build.pages.dev/a/') +
                      (connectionAgent as any).code,
                    a2: locatorInputs.service,
                    a3: locatorInputs.phone
                  }
                }}
                  styles={{
                    width: "1000px",
                    height: "90vh"
                  }} />

              </Box>
            </Box>
          </>
        }

        {showInterstitial && (
          <Box display="flex" flexDirection="column" paddingTop="space200" marginTop="space200" rowGap="space100" alignItems="center">
            {interstitialText &&
              interstitialText.map((text: string, index: number) => (
                <Text
                  color="colorTextInverse"
                  as="h3"
                  key={'interstit-' + index}
                  fontSize="fontSize80"
                  variant="heading30"
                  fontWeight="fontWeightMedium"
                >
                  <span dangerouslySetInnerHTML={{ __html: text }} />
                </Text>
              ))}
          </Box>
        )}

      </Box>

      <Modal ariaLabelledby={modalHeadingID} isOpen={isModalOpen} onDismiss={handleClose} size="default">

        <ModalBody>
          <Box display="flex" textAlign="center" margin="auto" paddingY="space140" width="80%" alignContent="center" justifyContent="center">
            <Text as="h3" variant="heading30" fontSize="fontSize80" fontWeight="fontWeightMedium">
              {translate("Ending session in ", "Finalizando sesión en ")}{countdownValue}
            </Text>
          </Box>
        </ModalBody>

        <ModalFooter>
          <ModalFooterActions>
            <StartButton onClick={handleClose} style={{ backgroundColor: backgroundColorChange, color: "white" }}>{translate("Wait, I need more time", "¡Esperar! necesito más tiempo")}</StartButton>
          </ModalFooterActions>
        </ModalFooter>

      </Modal>

      <Modal ariaLabelledby={codeModalHeadingID} isOpen={isCodeModalOpen} onDismiss={handleCodeClose} size="default">

        <ModalHeader children={''} />

        <ModalBody>

          <Box width="400px" margin="auto" paddingY="space30" >
            <Label variant="default" htmlFor="code">{translate("Enter Code", "Ingrese el código")}</Label>
            <Input
              id="code"
              name="code"
              type="text"
              onChange={handleCodeChange}
              value={code}
              required
              placeholder={translate("required", "obligatorio")}
              hasError={codeError.length > 0}
            ></Input>
            {codeError.length > 0 && <Box flexDirection="row" columnGap="space20" display="flex" alignItems="flex-end">
              <ErrorIcon decorative={false} title="Code Error icon" color="colorTextError" />
              <Text paddingTop="space20" as="p" fontSize="fontSize30" color="colorTextError" >
                {codeError}</Text>
            </Box>}


          </Box>


        </ModalBody>
        <ModalFooter>
          <ModalFooterActions>

            <SmallerStartButton onClick={handleCodeGo} style={{ backgroundColor: backgroundColorChange, color: "white" }}>{translate('Get Connected', 'Conéctase')}</SmallerStartButton>
          </ModalFooterActions>
        </ModalFooter>
      </Modal>

      {/*Start from email modal*/}
      <Modal ariaLabelledby={emailModalHeadingID} isOpen={isEmailModalOpen} onDismiss={handleEmailClose} size="default">
        <ModalHeader children={''} />
        <ModalBody>
          <Box width="400px" margin="auto" paddingY="space30" >
            <Label variant="default" htmlFor="email">{translate("Enter email", "Ingrese el correo electrónico")}</Label>
            <Input
              id="email"
              name="email"
              type="email"
              onChange={handleChange}
              value={locatorInputs.email}
              required
              placeholder={translate("required", "obligatorio")}
              hasError={emailError.length > 0}
            ></Input>
            {emailError.length > 0 && <Box flexDirection="row" columnGap="space20" display="flex" alignItems="flex-end">
              <ErrorIcon decorative={false} title="Email Error icon" color="colorTextError" />
              <Text paddingTop="space20" as="p" fontSize="fontSize30" color="colorTextError" >
                {emailError}</Text>
            </Box>}
          </Box>
        </ModalBody>
        <ModalFooter>
          <ModalFooterActions>
            <SmallerStartButton onClick={handleStartFromEmail} style={{ backgroundColor: backgroundColorChange, color: "white" }}>{translate('Get Connected', 'Conéctase')}</SmallerStartButton>
          </ModalFooterActions>
        </ModalFooter>
      </Modal>

      {/* post call send email to client modal */}
      <Modal ariaLabelledby={postCallModalHeading} isOpen={isPostCallModalOpen} onDismiss={handlePostCallClose} size="default">
        <ModalHeader children={''} />
        <ModalBody>
          <Box width="400px" margin="auto" paddingY="space30" >
            <Label variant="default" htmlFor="email">{translate("Enter email", "Ingrese el correo electrónico")}</Label>
            <Input
              id="email"
              name="email"
              type="email"
              onChange={handleChange}
              value={locatorInputs.email}
              required
              placeholder={translate("required", "obligatorio")}
              hasError={emailError.length > 0}
            ></Input>
            {emailError.length > 0 && <Box flexDirection="row" columnGap="space20" display="flex" alignItems="flex-end">
              <ErrorIcon decorative={false} title="Email Error icon" color="colorTextError" />
              <Text paddingTop="space20" as="p" fontSize="fontSize30" color="colorTextError" >
                {emailError}</Text>
            </Box>}
          </Box>
        </ModalBody>
        <ModalFooter>
          <ModalFooterActions>
            <SmallerStartButton onClick={updateEmailPostCall} style={{ backgroundColor: backgroundColorChange, color: "white" }}>{translate('Send Details', 'Enviar detalles')}</SmallerStartButton>
          </ModalFooterActions>
        </ModalFooter>
      </Modal>

      {/*Already connected modal*/}
      <Modal ariaLabelledby={alreadyConnectedID} isOpen={isAlreadyConnectedModalOpen} onDismiss={handleAlreadyConnectedClose} size="default">
        <ModalHeader children={''} />
        <ModalBody>
          <Box width="400px" margin="auto" paddingY="space30" display="flex" alignItems="center" flexDirection="column">

            <Text as="h1" fontSize="fontSize80" color="colorText" paddingBottom="space140">{translate('Reconnect via', 'Vuelva a conectarse mediante')}</Text>

            <Box display="flex" flexDirection="row" columnGap="space60">

              <StartButton onClick={enterCode} style={{ backgroundColor: backgroundColorChange, color: "white" }}>{translate('Code', 'Código')}</StartButton>

              <StartButton onClick={enterEmail} style={{ backgroundColor: backgroundColorChange, color: "white" }}>{translate('Email', 'Correo electrónico')}</StartButton>

            </Box>

          </Box>
        </ModalBody>
        <ModalFooterActions>
        </ModalFooterActions>
      </Modal>

    </Box>

    <Box width="100%" height='6vh' display="flex" justifyContent="flex-end"
      alignItems="flex-end" paddingBottom="space50" paddingRight="space50" >
      {visibleStage != 'video' && visibleStage != 'start' &&
        <Button variant="destructive" id='language-en' name="language" value='English' onClick={end}>
          {translate("End", "Finalizar")}
        </Button>}
    </Box>


  </div>


  );
};

NFCCVideo.displayName = 'NFCC Video Counseling';
