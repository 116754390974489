import * as React from 'react';
import { Box } from '@twilio-paste/core/box';
import { Spinner } from '@twilio-paste/core/spinner';
import { Button } from '@twilio-paste/core/button';
import { useCampaignContext } from "../CampaignProvider";
import { Label } from "@twilio-paste/label";
import { Input } from "@twilio-paste/input";
import { Select, Option } from "@twilio-paste/select";
import { ChevronLeftIcon } from "@twilio-paste/icons/esm/ChevronLeftIcon";
import axios, { AxiosRequestConfig } from 'axios';
import { useVideoContext } from "../hooks/useVideoContext";
import { MicrophoneOffIcon } from "@twilio-paste/icons/esm/MicrophoneOffIcon";
import { MicrophoneOnIcon } from "@twilio-paste/icons/esm/MicrophoneOnIcon";
import { useParams } from "react-router-dom";
import { useAppState } from '../hooks/useAppState/useAppState';
import { InlineWidget } from "react-calendly";
import { useUID } from '@twilio-paste/core/uid-library';
import { Modal, ModalBody, ModalFooter, ModalFooterActions, ModalHeader, ModalHeading } from '@twilio-paste/core/modal';
import useState from 'react-usestateref'
import { Text } from '@twilio-paste/core/text';
import { ErrorIcon } from "@twilio-paste/icons/esm/ErrorIcon";
import { CallFailedIcon } from "@twilio-paste/icons/esm/CallFailedIcon";
import Logo from '../assets/Logo.png';
import { Checkbox } from "@twilio-paste/core/checkbox";
import styled from '@emotion/styled'
import permissions from '../assets/permissions.png';
import {
  MediaPermissionsError,
  MediaPermissionsErrorType,
  requestMediaPermissions
} from 'mic-check';

/*
TODO:
- fallback to online when device error
*/

export const NFCCVideoResponsive = (): JSX.Element => {

  //data bits
  const [locatorInputs, setLocatorInputs] = useState<any>({})
  const [locatorOutput, setLocatorOutput] = React.useState<any>({})
  const [availability, setAvailability] = React.useState<any>({});
  const [agencyServiceConfigId, setAgencyServiceConfigId, agencyServiceConfigIdRef] = useState(0);
  const [connectionAgent, setConnectionAgent, connectionAgentRef] = useState({});

  //device management
  const [audioDevicesInput, setAudioDevicesInput] = React.useState<any>([]);
  const [audioDevicesOutput, setAudioDevicesOutput] = React.useState<any>([]);
  const [videoDevicesInput, setVideoDevicesInput] = React.useState<any>([]);
  const [audioDeviceInput, setAudioDeviceInput] = React.useState<any>();
  const [audioDeviceOutput, setAudioDeviceOutput] = React.useState<any>();
  const [videoDeviceInput, setVideoDeviceInput] = React.useState<any>();
  const [permissionsError, setPermissionsError] = React.useState<any>({});
  const [hasAllPermissions, setHasAllPermissions] = React.useState(false);

  //display and navigation bits
  const [visibleStage, setVisibleStage, visibleStageRef] = useState("start"); //React.useState<string>("start");
  const [showInterstitial, setShowInterstitial] = React.useState(false);
  const [interstitialText, setInterstitialText] = React.useState<string[]>(["Starting application..."]);
  const [isBooking, setIsBooking] = React.useState(false);
  const [isDuplicateOverride, setIsDuplicateOverride] = React.useState(false);
  const [isCode, setIsCode] = React.useState(false);

  //video bits   
  const { video, isMuted, localVideoMedia, remoteVideoMedia, connect, disconnect, isOpen, ended, attended } = useVideoContext();

  //validation bits
  const [emailError, setEmailError] = React.useState<string>("");
  const [fNameError, setfNameError] = React.useState<string>("");
  const [lNameError, setlNameError] = React.useState<string>("");
  const [zipError, setZipError] = React.useState<string>("");
  const [phoneError, setPhoneError] = React.useState<string>("");
  const [formValid, setFormValid] = React.useState(false);
  const [codeError, setCodeError] = React.useState<string>("");
  const [consent, setConsent] = useState(false);

  //timeout bits
  const [isModalOpen, setIsModalOpen, isModalOpenRef] = useState(false);
  const handleOpen = () => {
    setIsModalOpen(true);
  }
  const handleClose = () => {
    setIsModalOpen(false)
    clearCountdownTimer(false)
  }
  const modalHeadingID = useUID();
  const [countdownValue, setCountdownValue] = React.useState<number>(5);
  const [runInterval, setRunInterval] = React.useState(false);
  const intervalRef = React.useRef<ReturnType<typeof setInterval> | null>(null)

  //code bits
  const [isCodeModalOpen, setIsCodeModalOpen] = React.useState(false);
  const handleCodeOpen = () => {
    setIsCodeModalOpen(true);
  }
  const handleCodeClose = () => {
    setIsCodeModalOpen(false)
  }
  const codeModalHeadingID = useUID();
  const [code, setCode] = React.useState<string>("");

  //context bits
  const { guid } = useParams();
  const { init: videoInit } = useVideoContext();
  const { appDispatch, appState } = useAppState();
  const campaignData = useCampaignContext().campaignData;

  //translation bits
  const serviceTranslations = [
    { en: 'Student Loan Help', es: 'Préstamos de Estudiantes' },
    { en: 'Small Business Owner', es: 'Dueños de pequeños negocios' },
    { en: 'Foreclosure Prevention', es: 'Morosidad Hipotecaria' },
    { en: 'Reverse Mortgage', es: 'Hipoteca Invertida' },
    { en: 'Bankruptcy', es: 'Quiebra' },
    { en: 'Rental Counseling', es: 'Ayuda de Alquiler' },
    { en: 'First Time Homebuyer', es: 'Primeros Compradores De Vivienda' },
    { en: 'Credit Counseling', es: 'Asesoramiento crediticio' },
    { en: 'Overall Budget and Financial Review', es: ' Análisis de Presupuesto' }
  ]

  const createOnlineConnection = () => {
    let data = JSON.stringify({
      /* "service": locatorInputs,
       "language": conf.language ?? "English",
       "zipCode": locatorDetails.zipCode,
       "email": locatorDetails.emailAddress,
       "phone": locatorDetails.phoneNumber,
       "firstName": locatorDetails.firstName,
       "lastName": locatorDetails.lastName,*/
      ...locatorInputs,
      "useProd": appState.isProd,
      type: 'internal'
    });

    let url = 'https://chat-widget-5710.twil.io/proxy-request-nfcc-org'

    if (window.location.href.includes('localhost')) {
      url = 'https://chat-widget-5710.twil.io/proxy-request'
    }
    else if (window.location.href.includes('pages.dev')) {
      url = 'https://chat-widget-5710.twil.io/proxy-request-pages-dev'
    }


    let config = {
      method: 'post',
      url,
      headers: {
        'Content-Type': 'application/json'
      },
      data: data
    };

    axios(config)
      .then(function (response: any) {
        console.log('CAZ Online connection', JSON.stringify(response.data));
        setLocatorOutput({ type: 'online', ...response.data })
      })
      .catch(function (error: any) {
        console.error(error)
      })
  }



  const checkPermissions = () => {
    console.log('checkPermissions')

    requestMediaPermissions().then(() => {

      navigator.mediaDevices
        .getUserMedia({ video: true, audio: true })

      //get devices
      navigator.mediaDevices
        .enumerateDevices()
        .then((devices) => {

          let ai: any[] = []
          let ao: any[] = []
          let vi: any[] = []

          let ps = [];

          ps.push(
            new Promise(function (resolve, reject) {
              devices.forEach((device) => {

                console.log(`${device.kind}: ${device.label} id = ${device.deviceId}`);
                if (device.kind === 'audioinput') {
                  ai.push(device)
                  if (device.deviceId === 'default') {
                    setAudioDeviceInput(JSON.stringify(device))
                    localStorage.setItem("input_device", JSON.stringify(device));
                  }
                }
                else if (device.kind === 'audiooutput') {
                  ao.push(device)
                  if (device.deviceId === 'default') {
                    setAudioDeviceOutput(JSON.stringify(device))
                    localStorage.setItem("speaker_device", JSON.stringify(device));
                  }
                }
                else if (device.kind === 'videoinput') {
                  vi.push(device)
                  if (device.deviceId === 'default') {
                    setVideoDeviceInput(JSON.stringify(device))
                    localStorage.setItem("video_device", JSON.stringify(device));
                  }
                }
                else {
                  console.log('???', device)
                }
              }
              );
              resolve('done')
            }))

          Promise.all(ps).then((i) => {
            console.log('promise done')
            setAudioDevicesInput(ai)
            setAudioDevicesOutput(ao)
            setVideoDevicesInput(vi)
          })
        })
        .catch((err) => {
          console.error(`${err.name}: ${err.message}`);
        });
      // can successfully access camera and microphone streams
      console.log('yay')
      setPermissionsError({})
      setHasAllPermissions(true)
    })
      .catch((err: MediaPermissionsError) => {
        const { type, name, message } = err;
        if (type === MediaPermissionsErrorType.SystemPermissionDenied) {
          // browser does not have permission to access camera or microphone
          console.log('browser does not have permission to access camera or microphone')
          setPermissionsError({ fallback: true })
        } else if (type === MediaPermissionsErrorType.UserPermissionDenied) {
          // user didn't allow app to access camera or microphone
          console.log('user did not allow app to access camera or microphone')
          setPermissionsError({
            'title': translate("Camera and microphone are blocked", "Cámara y micrófono bloqueados"),
            'message': translate("This app requires access to your camera and microphone. Click the camera blocked icon in your browser's address bar and grant access to camera and microphone",
              "Esta aplicación requiere acceso a tu cámara y micrófono. Haga clic en el icono de cámara bloqueada en la barra de direcciones del navegador y conceda acceso a la cámara y al micrófono.")
          })

        } else if (type === MediaPermissionsErrorType.CouldNotStartVideoSource) {
          // camera is in use by another application (Zoom, Skype) or browser tab (Google Meet, Messenger Video)
          // (mostly Windows specific problem)
          console.log('camera is in use by another application (Zoom, Skype) or browser tab (Google Meet, Messenger Video)')

          setPermissionsError({
            'title': translate("Can't start your camera or microphone", "No se puede iniciar la cámara o el micrófono"),
            'message': translate("Another application or browser tab might already by using your webcam. Please turn off other cameras before proceeding",
              "Es posible que otra aplicación o pestaña del navegador ya esté utilizando tu webcam. Por favor, apague otras cámaras antes de proceder")
          })


        } else {
          // not all error types are handled by this library
          console.log('other')
          setPermissionsError({ fallback: true })
        }
      })
      .catch((err: any) => { console.log('other error', err) });
  }

  const handleDisconnect = async () => {
    //if the call is ended while in the queue, need to remove the task from flex
    if (!attended) {
      //get task by videoChatRoomSid attribute
      const agencyVideoId = locatorOutput.agencyVideoId

      if (video) {
        if (video.room) {
          const roomSid = video?.room.sid

          const url = 'https://video-campaigns-6267.twil.io/' + appState.domain + '/cancel-task'

          //cancel task
          const config: AxiosRequestConfig = {
            method: 'post',
            maxBodyLength: Infinity,
            url,
            headers: {
              'Content-Type': 'application/json'
            },
            data: JSON.stringify({ useProd: appState.isProd, data: JSON.stringify({ "agencyVideoId": agencyVideoId, "videoChatRoomSid": roomSid }) })
          };
          try {
            const response = await axios.request(config);
            disconnect()
          }
          catch (error) {
            disconnect()
          }
        }
        else {
          end()
        }

      }

    }
    else {
      disconnect()
    }

  }

  function isCalendlyEvent(e: any) {
    return e.data.event && e.data.event.indexOf("calendly") === 0;
  }

  //Set the campaign GUID in the AppState
  React.useEffect(() => {
    if (guid) {
      appDispatch({ type: "set-guid", guid: guid ?? '' });

      navigator.mediaDevices
        .getUserMedia({ video: true, audio: true })

      //get devices
      navigator.mediaDevices
        .enumerateDevices()
        .then((devices) => {

          let ai: any[] = []
          let ao: any[] = []
          let vi: any[] = []

          let ps = [];

          ps.push(
            new Promise(function (resolve, reject) {
              devices.forEach((device) => {

                console.log(`${device.kind}: ${device.label} id = ${device.deviceId}`);
                if (device.kind === 'audioinput') {
                  ai.push(device)
                  if (device.deviceId === 'default') {
                    setAudioDeviceInput(device)
                    localStorage.setItem("input_device", JSON.stringify(device));
                  }
                }
                else if (device.kind === 'audiooutput') {
                  ao.push(device)
                  if (device.deviceId === 'default') {
                    setAudioDeviceOutput(device)
                    localStorage.setItem("speaker_device", JSON.stringify(device));
                  }
                }
                else if (device.kind === 'videoinput') {
                  vi.push(device)
                  if (device.deviceId === 'default') {
                    setVideoDeviceInput(device)
                    localStorage.setItem("video_device", JSON.stringify(device));
                  }
                }
              }
              );
              resolve('done')
            }))

          Promise.all(ps).then((i) => {
            //console.log(ai,ao,vi)
            setAudioDevicesInput(ai)
            setAudioDevicesOutput(ao)
            setVideoDevicesInput(vi)
          })
        })
        .catch((err) => {
          console.error(`${err.name}: ${err.message}`);
        });
    }

  }, [guid]);

  React.useEffect(() => {
    if (appState.domain) {
      window.addEventListener("message", function (e) {

        if (isCalendlyEvent(e)) {
          console.log('calendly', e.data);
          if (e.data.event == 'calendly.event_scheduled') {
            const updateAppointment = async () => {
              //create dummy appointment
              const url = e.data.payload.event.uri

              //update ConnectionAgent
              const config: AxiosRequestConfig = {
                method: 'post',
                maxBodyLength: Infinity,
                url: 'https://video-campaigns-6267.twil.io/' + appState.domain + '/update-appointment',
                headers: {
                  'Content-Type': 'application/json'
                },
                data: JSON.stringify({
                  useProd: appState.isProd,
                  data: JSON.stringify({ 'url': url, 'agencyServiceConfigId': agencyServiceConfigIdRef.current, 'connectionAgent': connectionAgentRef.current })
                })

              }
              try {
                const response = await axios.request(config);
              }
              catch (error) {
                console.error(error)
              }
            }

            // call the function
            updateAppointment()
              // make sure to catch any error
              .catch(console.error);
          }
        }
      })
    };
  }, [appState.domain]);

  const generateCode = async () => {

    console.log('CAZ calling generatecode')
    var code = ''

    const adjectives = ["Active", "Agile", "Amiable", "Ample", "Apt", "Artful", "Astute", "Avid", "Awake", "Aware", "Beloved", "Blissful", "Brave", "Breezy", "Bright",
      "Buoyant", "Candid", "Capable", "Careful", "Cheery", "Chirpy", "Clever", "Comely", "Comfy", "Crisp", "Cuddly", "Dainty", "Darling", "Dazzle", "Delish",
      "Dimple", "Doting", "Dreamy", "Dynamic", "Earnest", "Easy",
      "Exotic", "Expert", "Fabled", "Faith", "Fancy", "Fierce", "Fine", "Fond", "Free", "Fresh", "Friendly", "Frolic", "Fun", "Funny", "Gentle", "Gifted", "Glorious",
      "Grateful", "Happy", "Hardy", "Hearty", "Honest", "Huge", "Humble", "Ideal", "Innocent", "Jazzy", "Jolly", "Keen", "Kind", "Lively", "Lovely", "Loving",
      "Lucky", "Merry", "Mighty", "Modest", "Neat", "Noble", "Peace", "Playful", "Plucky", "Posh", "Pure", "Quick", "Radiant", "Rare", "Refined", "Regal", "Relish", "Rich",
      "Robust", "Rosy", "Secure", "Serene", "Sharp", "Shiny", "Simple", "Sincere", "Skill", "Sleek", "Smart", "Smitten", "Snappy", "Snug", "Solid", "Sonic",
      "Sought", "Speedy", "Spry", "Square", "Stable", "Steady", "Sunny", "Super", "Supreme", "Sure", "Sweet", "Swift", "Swish", "Talented", "Tender",
      "Tickled", "Toasty", "Top"
    ]

    const animals = [
      "Badger", "Beaver", "Bison", "Bobcat", "Buffalo", "Bulldog", "Butterfly", "Capybara", "Cheetah", "Chimp",
      "Chipmunk", "Cicada", "Cobra", "Corgi", "Cougar", "Crab", "Crane", "Crayfish", "Crow", "Deer",
      "Dingo", "Dolphin", "Donkey", "Dragonfly", "Eagle", "Eel", "Elephant", "Elk", "Falcon", "Ferret",
      "Finch", "Firefly", "Flamingo", "Fly", "Fox", "Frog", "Gazelle", "Gecko", "Gibbon", "Giraffe",
      "Goat", "Goose", "Gorilla", "Greyhound", "Guppy", "Hamster", "Hare", "Hawk",
      "Hedgehog", "Heron", "Hippo", "Horse", "Hyena", "Iguana", "Impala", "Jackal", "Jaguar",
      "Jay", "Jellyfish", "Kangaroo", "Koala", "Komodo", "Ladybug", "Lemur", "Leopard", "Lion", "Lizard",
      "Llama", "Lobster", "Macaw", "Magpie", "Manatee", "Mantis", "Marmot", "Meerkat", "Mongoose", "Monkey",
      "Moose", "Mosquito", "Moth", "Narwhal", "Newt", "Ocelot", "Octopus", "Opossum", "Orca",
      "Ostrich", "Otter", "Owl", "Panda", "Panther", "Parrot", "Peacock", "Pelican", "Penguin", "Pig",
      "Platypus", "Poodle", "Porcupine", "Possum", "Puma", "Puppy", "Rabbit", "Raccoon",
      "Rat", "Raven", "Rhino", "Rooster", "Salmon", "Scorpion", "Seagull", "Seahorse",
      "Seal", "Shark", "Sheep", "Shrimp", "Skunk", "Sloth", "Snail", "Snake", "Sparrow", "Squid",
      "Squirrel", "Starling", "Stingray", "Stork", "Swan", "Tamarin", "Tiger", "Toad", "Tortoise",
      "Turkey", "Turtle", "Viper", "Vulture", "Wallaby", "Walrus", "Warthog", "Wasp", "Weasel", "Whale",
      "Wolf", "Wombat", "Wren", "Yak", "Zebra"
    ]

    //generate code
    code = adjectives[Math.floor(Math.random() * adjectives.length)].toLowerCase() + ' ' + animals[Math.floor(Math.random() * animals.length)].toLowerCase()

    //validate code
    const url = 'https://video-campaigns-6267.twil.io/' + appState.domain + '/get-connection-by-code'

    let conn: any = {}

    const config: AxiosRequestConfig = {
      method: 'post',
      maxBodyLength: Infinity,
      url,
      headers: {
        'Content-Type': 'application/json'
      },
      data: JSON.stringify({
        useProd: appState.isProd,
        data: JSON.stringify({ "code": code })
      })
    };



    axios(config)
      .then(function (response1: any) {
        console.log("found connection", JSON.stringify(response1.data));
        if (Object.keys(response1.data).includes("error_message")) {
          //it's unique!
          return code
        }
        else {
          //not unique, generate another
          console.log('not unique')
          generateCode()
        }
      })
      .catch(function (error) {
        console.log('caz', error);
      });


    return code
  };

  React.useEffect(() => {
    if (visibleStage == 'calendly') {

      const createAppointment = async () => {
        //create dummy appointment
        //const url = 'https://video-campaigns-6267.twil.io/create-appointment'
        const url = 'https://video-campaigns-6267.twil.io/' + appState.domain + '/create-appointment'

        //const c = locatorOutput.connectionId.toString() + Math.ceil(Math.random() * 10000);

        const c = await generateCode()

        let ca: any = {
          connectionId: locatorOutput.connectionId,
          agencyId: locatorOutput.agencyId,
          code: c
        }

        const config: AxiosRequestConfig = {
          method: 'post',
          maxBodyLength: Infinity,
          url,
          headers: {
            'Content-Type': 'application/json'
          },
          data: JSON.stringify({
            useProd: appState.isProd,
            data: JSON.stringify(ca)
          })
        };
        try {
          const response = await axios.request(config);

          ca.connectionAgentId = response.data.connectionAgentId

          setConnectionAgent(ca)

        }
        catch (error) {
          console.error(error)
        }
      }

      // call the function
      createAppointment()
        // make sure to catch any error
        .catch(console.error);


    }
  }, [visibleStage]);

  //Handle countdown for timeout modal
  React.useEffect(() => {
    if (runInterval) {
      intervalRef.current = setInterval(() => {
        setCountdownValue((countdownValue) => Math.max(countdownValue - 1, 1))
      }, 1000);
    }

    return () => {
      clearInterval(intervalRef.current as NodeJS.Timeout);
    }; // clear interval when unmounting the component

  }, [runInterval]);

  //Handle countdown for timeout modal
  const clearCountdownTimer = (permanent: boolean) => {

    setRunInterval(false);

    if (!permanent) {
      timeout(true, visibleStage);
    }
    setTimeout(() => { setCountdownValue(5) }, 1000)

  };

  //initialise campaign data
  React.useEffect(() => {
    if (campaignData) {


      let l: any = {}

      if (campaignData.availableLanguages.length > 1) {
        setVisibleStage('language')
      }


      if (campaignData.availableServices.length > 1) {
        let se = []
        if (campaignData.availableServices.includes('Credit Counseling')) {
          se.push('Credit Counseling')

          // @ts-ignore
          se.push(...campaignData.availableServices.filter(s => s != 'Credit Counseling'))
          campaignData.availableServices = se
        }

      }

      if (campaignData.availableLanguages.length == 1) {
        l.language = campaignData.availableLanguages[0]

        if (l.language === "Spanish") {
          //Translation time baby!
          setInterstitialText(['Ejecutando aplicación...'])
        }

        //setVisibleStage("service")
      }

      if (campaignData.availableServices.length == 1) {
        l.service = campaignData.availableServices[0]
        //setVisibleStage("form")
      }

      l.videoCampaignId = campaignData.videoCampaignId

      setLocatorInputs(l)

    }

  }, [campaignData]);

  const start = () => {


    if (campaignData.type == 'booth') {
      setLocatorInputs({ ...locatorInputs, state: campaignData.state })
    }

    if (campaignData.availableServices.length > 1) {
      setVisibleStage("service")
    }

    else {
      const input: any = { service: (locatorInputs.service || campaignData.availableServices[0]), videoCampaignId: campaignData.videoCampaignId, language: (locatorInputs.language || campaignData.availableLanguages[0]) }

      if (campaignData.type == 'booth') {
        input.state = campaignData.state
      }

      setLocatorInputs(input)

      if (campaignData.type == 'booth') {
        checkAvailability(input, false)
      }
      else if (Object.keys(locatorInputs).includes('firstName')) {
        checkAvailability(input, false)
      }
      else {
        setVisibleStage("form")
      }
    }
  }

  const enterCode = () => {
    handleCodeOpen()
  }

  const handleCodeGo = async () => {
    setIsCode(true)
    setCodeError('')

    //validate code
    const url = 'https://video-campaigns-6267.twil.io/' + appState.domain + '/get-connection-by-code'

    let conn: any = {}

    const config: AxiosRequestConfig = {
      method: 'post',
      maxBodyLength: Infinity,
      url,
      headers: {
        'Content-Type': 'application/json'
      },
      data: JSON.stringify({ useProd: appState.isProd, data: JSON.stringify({ "code": code }) })
    };
    try {
      const response = await axios.request(config);

      conn = response.data

      if (Object.keys(conn).includes('error_message')) {
        setCodeError('Code not found, please check and try again')
      }
      else {
        let s = ''
        let e = ''
        let p = ''
        if (Object.keys(conn).includes('serviceName')) {
          s = conn.serviceName
        }
        else {
          s = conn.service
        }

        if (Object.keys(conn).includes('fromPhone')) {
          p = conn.fromPhone
        }
        else {
          p = conn.phone
        }

        if (Object.keys(conn).includes('fromEmail')) {
          e = conn.fromEmail
        }
        else {
          e = conn.email
        }

        const input = { ...conn, service: s, videoCampaignId: campaignData.videoCampaignId, email: e, phone: p }
        setLocatorInputs(input)

        //check agency availability
        checkAvailability(input, true)


        handleCodeClose()
      }

    }
    catch (error) {
      setCodeError('Code not found, please check and try again')
    }



  }

  //for code pathway, route after availability check
  React.useEffect(() => {
    if (isCode) {
      //if valid and agency is available, connect directly to agency 
      callLocator(false, null)

    }
  }, [availability]);




  //time out the app if the video call has ended
  React.useEffect(() => {
    if (ended) {
      timeout(false, visibleStage)
    }
  }, [ended]);

  //route based on output of locator
  React.useEffect(() => {

    if (Object.keys(locatorOutput).length > 0) {


      setAgencyServiceConfigId(locatorOutput.agencyServiceConfigId)
      if (Object.keys(locatorOutput).includes('error_message')) {
        setVisibleStage('error')
        timeout(false, 'error')
        setShowInterstitial(false)
      }
      else if (locatorOutput.available && locatorOutput.isDuplicate && isCode) {
        let s = [translate('Hello ' + locatorInputs.firstName, 'Hola ' + locatorInputs.firstName),
        translate('You are being connected to ' + locatorOutput.agencyName, 'Está siendo conectado a ' + locatorOutput.agencyName)]
        setInterstitialText(s)
        setShowInterstitial(true)
        startVideo()
      }
      else if (locatorOutput.isDuplicate) {
        setVisibleStage('duplicate')
        timeout(true, 'duplicate')
        setShowInterstitial(false)
      }
      else if (!locatorOutput.available && Object.keys(locatorOutput).includes('type')) {
        //means we've failed over to online
        setShowInterstitial(false)
        setVisibleStage('none-available')
        timeout(false, 'none-available')
      }
      else if (!locatorOutput.available && locatorOutput.calendlyUrl.length > 0 && (!isDuplicateOverride || isCode)) {
        setShowInterstitial(false)
        setVisibleStage('prebooking')
      }
      else if (!locatorOutput.available && campaignData.type == 'web' && locatorOutput.calendlyUrl.length > 0 && isDuplicateOverride) {
        setVisibleStage('calendly')
        setShowInterstitial(false)
        timeout(true, 'calendly')
      }
      else if (!locatorOutput.available && locatorOutput.calendlyUrl.length > 0 && (isBooking || isDuplicateOverride)) {
        //handle unavailable, not dup, bookable
        setVisibleStage('calendly')
        setShowInterstitial(false)
        timeout(true, 'calendly')
      }
      else if (!locatorOutput.available && locatorOutput.calendlyUrl.length > 0 && !(isBooking)) {
        //handle unavailable, not dup, bookable
        setVisibleStage('prebooking')
      }
      else if (!locatorOutput.available) {
        //handle unavailable, not dup, not bookable
        setShowInterstitial(false)
        setVisibleStage('none-available')
        timeout(false, 'none-available')

      }
      else {
        setShowInterstitial(false)
        setVisibleStage('devices');
        checkPermissions()

        /* let s = [translate('Hello ' + locatorInputs.firstName, 'Hola ' + locatorInputs.firstName),
         translate('You are being connected to ' + locatorOutput.agencyName, 'Está siendo conectado a ' + locatorOutput.agencyName)]
         setInterstitialText(s)
         setShowInterstitial(true)
         startVideo()*/
      }
    }

  }, [locatorOutput]);

  //set interstitial text after availability check
  React.useEffect(() => {

    if (Object.keys(availability).length > 0) {
      setInterstitialText([translate('Finding you a counselor - please hold...', 'Encontrándose una consejero - por favor espere')])

    }

  }, [availability]);



  const startVideo = () => {

    let label = (locatorInputs.service === 'Overall Budget and Financial Review' && 'Budget' || locatorInputs.service) + '_' + locatorOutput.code + '!' + campaignData.videoCampaignId + '!' + Math.ceil(Math.random() * 100) + '_' + (locatorInputs.language == 'Spanish' && 'es' || 'en')

    if (Object.keys(locatorInputs).includes('workerSid')) {
      label = label + '_' + locatorInputs.workerSid
    }



    videoInit(locatorOutput.accountSid, locatorInputs.firstName + ' ' + locatorInputs.lastName, locatorOutput.kioskSid
      //+ '-' + locatorInputs.firstName + '_' + locatorInputs.lastName
      , label, JSON.parse(audioDeviceOutput).deviceId);

    setTimeout(() => {
      setShowInterstitial(false);
      setVisibleStage('video')

      connect()
    }, 1000)
  }

  const startAfterPerms = () => {
    let s = [translate('Hello ' + locatorInputs.firstName, 'Hola ' + locatorInputs.firstName),
    translate('You are being connected to ' + locatorOutput.agencyName, 'Está siendo conectado a ' + locatorOutput.agencyName)]
    setInterstitialText(s)
    setShowInterstitial(true)
    startVideo()
  }




  const setDevice = ({
    target
  }: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    console.log(target.id)
    if (target.id === 'audioDeviceInput') {
      setAudioDeviceInput(target.value)
      localStorage.setItem("input_device", target.value);
    }
    else if (target.id === 'audioDeviceOutput') {
      setAudioDeviceOutput(target.value)
      localStorage.setItem("speaker_device", target.value);
      //TODO set sink
    }
    else if (target.id === 'videoDeviceInput') {
      setVideoDeviceInput(target.value)
      localStorage.setItem("video_device", target.value);
    }

  };



  //handle change of variable values in form
  const handleChange = ({
    target
  }: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const newI = {
      ...locatorInputs,
      [target.name]: target.value
    }
    setLocatorInputs(newI);

    setFormValid(validate('change', newI))
  };

  const handleCodeChange = ({
    target
  }: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    setCode(target.value)


  };

  //handle button clicks for language and service selection
  const handleClick = (
    s: string, type: string) => {

    const l = {
      ...locatorInputs,
      [type]: s
    }

    setLocatorInputs(l);

    if (type == "language" //&& campaignData.availableServices.length == 1
    ) {
      if (s === "Spanish") {
        //Translation time baby!
        setInterstitialText(['Ejecutando aplicación...'])
      }
      else {
        setInterstitialText(['Starting application...'])
      }
      setVisibleStage('start')
    }
    /*else if (type == "language" && campaignData.availableServices.length > 1) {
      if (s === "Spanish") {
        //Translation time baby!
        setInterstitialText(['Ejecutando aplicación...'])
      }
      setVisibleStage('service')
    }*/
    else if (type == "service" && campaignData.type == 'booth') {
      //do availability check
      checkAvailability(l, false)
    }
    else {
      setVisibleStage('form')
    }
  };

  //navigate back to previous view
  const back = (destination: string) => {
    setVisibleStage(destination)
    let l = { ...locatorInputs }
    delete l.firstName
    delete l.lastName
    delete l.zipCode
    delete l.phone
    delete l.email

    setLocatorInputs({ ...locatorInputs })
    setConsent(false)
    setAvailability({})
    setLocatorOutput({})
    setInterstitialText([translate('Starting application...', 'Ejecutando aplicación...')])
  };

  //call the check availability endpoint to see who is available and route accordingly
  const checkAvailability = async (l: any, isCode: boolean) => {


    let a = { ...l }

    if (a.serviceName === 'Overall Budget and Financial Review') {
      a.serviceName = 'Budget'
    }


    if (l.service === 'Overall Budget and Financial Review') {
      a.service = 'Budget'
    }



    setShowInterstitial(true)
    const config: AxiosRequestConfig = {
      method: 'post',
      maxBodyLength: Infinity,
      url: 'https://video-campaigns-6267.twil.io/' + appState.domain + '/get-availability',
      headers: {
        'Content-Type': 'application/json'
      },
      data: JSON.stringify({ useProd: appState.isProd, data: JSON.stringify(a) })
    };
    const response = await axios.request(config);


    if (!isCode) {
      setShowInterstitial(false)

      if (Object.keys(response.data).includes('error_message')) {

        setVisibleStage('form')
        setZipError(translate("Please enter a valid ZIP", "Por favor ingrese un código postal válido"))
        return
      }

      setAvailability(response.data)

      if (response.data.available && campaignData.type == 'booth') {
        //available and hasn't been through the form yet, send them to the form
        setVisibleStage('form')
      }
      else if (campaignData.type == 'web') {
        //available and has been through the form, call locator
        callLocator(false, response.data)
      }
      else if (response.data.offerBooking && !response.data.available) {
        setVisibleStage('prebooking')
        timeout(true, 'prebooking')
      }
      else if (!response.data.offerBooking && !response.data.available && campaignData.type == 'booth') {
        setVisibleStage('form')
      }
      else {
        setVisibleStage('error')
        timeout(false, 'error')
      }
    }
    else {
      setAvailability(response.data)
    }

  }

  //handle button click from form
  const handleGetConnected = async () => {


    const valid = validate('button', locatorInputs);
    if (valid) {

      if (Object.keys(availability).length > 0) {
        //booth
        callLocator(false, null)
      }
      else if (visibleStage === 'form') {
        //web
        checkAvailability(locatorInputs, false)
      }
      else {
        setVisibleStage('form')
      }
    }

  }

  //re call the locator with the duplicate override flag
  const handleDuplicateOverride = async () => {

    setIsDuplicateOverride(true)
    clearCountdownTimer(true)
    callLocator(true, null)

  }

  //show the calendly view
  const handleCalendly = async () => {

    clearCountdownTimer(true)
    setVisibleStage('calendly')
    timeout(true, 'calendly')
  }

  //validate form inputs - returns true if inputs are valid
  const validate = (type: string, input: any) => {

    //clear errors
    setEmailError("")
    setfNameError("")
    setlNameError("")
    setZipError("")
    setPhoneError("")

    let isError = false;

    //validate
    if (!Object.keys(input).includes('zipCode') && campaignData.type == 'web' && type == 'button') {
      setZipError(translate("Please enter a valid ZIP", "Por favor ingrese un código postal válido"))
      isError = true;
    }
    else if ((input.zipCode && ! /[0-9]{5}/.test(input.zipCode)) && campaignData.type == 'web') {
      setZipError(translate("Please enter a valid ZIP", "Por favor ingrese un código postal válido"))
      isError = true;
    }
    if (!Object.keys(input).includes('phone') && type == 'button') {
      setPhoneError(translate("Please enter a valid phone", "Por favor ingrese un número de teléfono válido"))
      isError = true;
    }
    // eslint-disable-next-line
    else if (Object.keys(input).includes('phone')) {
      if (! /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/g.test(input.phone) || input.phone.length == 1) {
        setPhoneError(translate("Please enter a valid phone", "Por favor ingrese un número de teléfono válido"))
        isError = true;
      }
    }
    else {
      isError = true;
    }

    if (!Object.keys(input).includes('email') && type == 'button') {
      setEmailError(translate("Please enter a valid email", "Por favor ingrese un correo electrónico válido"))
      isError = true;
    }
    // eslint-disable-next-line
    else if (Object.keys(input).includes('email')) {
      if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,6})+$/g.test(input.email) || input.email.length == 1) {
        setEmailError(translate("Please enter a valid email", "Por favor ingrese un correo electrónico válido"))
        isError = true;
      }
    }
    else {
      isError = true;
    }


    if (!Object.keys(input).includes('firstName') && type == 'button') {
      setfNameError(translate("Please enter a valid first name", "Por favor ingrese un nombre válido"))
      isError = true;
    }

    if (!Object.keys(input).includes('lastName') && type == 'button') {
      setlNameError(translate("Please enter a valid last name", "Por favor ingrese un apellido válido"))
      isError = true;
    }

    if (
      !Object.keys(input).includes('firstName') || input.firstName == "" ||
      !Object.keys(input).includes('lastName') || input.lastName == "" ||
      !Object.keys(input).includes('email') || input.email == "" ||
      !Object.keys(input).includes('phone') || input.phone == ""

    ) {
      isError = true
    }

    if ((!Object.keys(input).includes('zipCode') || input.zipCode == "") && campaignData.type == 'web') {
      isError = true
    }

    return !isError
  }

  //route for the booking pathway
  const handleBooking = async () => {

    if (Object.keys(locatorOutput).length > 0) {
      setVisibleStage('calendly')
    }
    else if (campaignData.type == "booth") {
      setIsBooking(true)
      setVisibleStage('form')
    }
    else {
      setIsBooking(true)
      callLocator(false, null)
    }
  }

  //handle translation
  const translate = (en: string, es: string) => {
    if (locatorInputs.language == 'Spanish') {
      return es
    }
    return en
  }

  const translateService = (s: string) => {
    const es = (serviceTranslations.find(({ en }) => en === s) ?? {}).es ?? '';
    if (es) {
      return translate(s, es)
    }
    else {
      return s
    }
  }
  /** */

  //clear the local storage and refresh the app for the next user
  const end = () => {
    setTimeout(() => {
      location.reload()
      localStorage.clear();
    }, 500)
  }

  //start a timeout to end the user's session
  const timeout = (isLong: boolean, stage: string) => {
    if (campaignData.type == 'booth') {
      if (isLong) {
        setTimeout(() => {

          if (stage == visibleStageRef.current) {
            handleOpen()
            setRunInterval(true)

            setTimeout(() => {
              if (isModalOpenRef.current) {
                end()
              }
              clearCountdownTimer(true)
            }, 5000)
          }

        }, 180000)
      }
      else {
        setTimeout(() => {
          if (stage == visibleStageRef.current) {
            handleOpen()
            setRunInterval(true)

            setTimeout(() => {
              if (isModalOpenRef.current) {
                end()
              }
              clearCountdownTimer(true)
            }, 5000)
          }
        }, 10000)
      }
    }



  }

  //call the locator endpoint to connect a client with an agency
  const callLocator = async (duplicateOverride: boolean, av: any) => {
    setShowInterstitial(true)
    clearCountdownTimer(true)


    let d: any

    if (av) {
      d = {
        ...locatorInputs,
        duplicateOverride,
        availabilityResults: av
      }

    }
    else {
      d = {
        ...locatorInputs,
        duplicateOverride,
        availabilityResults: availability
      }

    }


    if (isCode) {
      d.code = code
    }

    if (campaignData.type == 'booth') {
      d.state = campaignData.state
    }

    const config: AxiosRequestConfig = {
      method: 'post',
      maxBodyLength: Infinity,
      url: 'https://video-campaigns-6267.twil.io/' + appState.domain + '/call-locator-video',
      headers: {
        'Content-Type': 'application/json'
      },
      data: JSON.stringify({ useProd: appState.isProd, data: JSON.stringify(d) })
    };
    const response = await axios.request(config);

    setLocatorOutput(response.data)

  }

  // Function to calculate the inverted color
  const getInvertedColor = (color: string) => {
    if (color) {
      const hex = color.replace('#', '');
      const r = parseInt(hex.substring(0, 2), 16);
      const g = parseInt(hex.substring(2, 4), 16);
      const b = parseInt(hex.substring(4, 6), 16);
      const invertedColor = `#${(255 - r).toString(16).padStart(2, '0')}${(255 - g).toString(16).padStart(2, '0')}${(255 - b).toString(16).padStart(2, '0')}`;
      return invertedColor;
    }
    return '#000'; // Default color if no valid color is provided
  };

  // Constant to store the inverted color for use in components
  const invertedButtonColor = getInvertedColor(campaignData?.buttonColor);

  const ActionButton = styled('button')`
  height: 12rem;
  width: 18rem;
  font-size: 1.75rem;
  font-weight: 500;
  border-radius: 10px;
  border: 1px solid #ccc;
  background-color: ${campaignData?.buttonColor};
  color: ${invertedButtonColor};
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  &:hover {
    background-color: #ccc;
    color: #000;
  }

  @media (max-width: 576px) {
    height: 6rem;
    width: 14rem;
    font-size: 1rem;
  }

  &:visited {
    background-color: ${campaignData?.buttonColor};
    color: ${invertedButtonColor};
  }
  &:active {
    background-color: ${campaignData?.buttonColor};
    color: ${invertedButtonColor};
  }
  &:focus {
    background-color: #ccc;
    color: #000;
  }
`;

  const ServiceButton = styled('button')`
  height: 12rem;
  width: 18rem;
  font-size: 1.75rem;
  font-weight: 500;
  border-radius: 10px;
  border: 1px solid #ccc;
  background-color: ${campaignData?.buttonColor};
  color: ${invertedButtonColor};
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  &:hover {
    background-color: #ccc;
    color: #000;
  }

  @media (max-width: 576px) {
    height: 3rem;
    width: 10rem;
    font-size: 0.6rem;
  }

  @media (min-width: 577px) {
    height: 5rem;
    width: 12rem;
    font-size: 0.9rem;
  }

  &:visited {
    background-color: ${campaignData?.buttonColor};
    color: ${invertedButtonColor};
  }
  &:active {
    background-color: ${campaignData?.buttonColor};
    color: ${invertedButtonColor};
  }
  &:focus {
    background-color: #ccc;
    color: #000;
  }
`;

  const StartButton = styled('button')`
  height: 12rem;
  width: 22rem;
  font-size: 1.75rem;
  font-weight: 500;
  border-radius: 10px;
  border: none;
  background-color: ${campaignData?.buttonColor};
  color: ${invertedButtonColor};
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  @media (max-width: 576px) {
    height: 6rem;
    width: 14rem;
    font-size: 1rem;
  }

  &:visited {
    background-color: ${campaignData?.buttonColor};
    color: ${invertedButtonColor};
  }
  &:active {
    background-color: ${campaignData?.buttonColor};
    color: ${invertedButtonColor};
  }
  &:focus {
    background-color: #ccc;
    color: #000;
  }
`;

  const StartButtonDisabled = styled('button')`
  height: 12rem;
  width: 22rem;
  font-size: 1.75rem;
  font-weight: 500;
  border-radius: 10px;
  border: none;
  background-color: #CBCBCB;
  color: #ADAAAB;
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  @media (max-width: 576px) {
    height: 6rem;
    width: 14rem;
    font-size: 1rem;
  }
`;

  const EnterCodeButton = styled('button')`
  height: 6rem;
  width: 16rem;
  font-size: 1.2rem;
  font-weight: 500;
  border-radius: 10px;
  border: none;
  background-color: ${campaignData?.buttonColor};
  color: ${invertedButtonColor};
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  @media (max-width: 576px) {
    height: 4rem;
    width: 12rem;
    font-size: 1rem;
  }

  &:visited {
    background-color: ${campaignData?.buttonColor};
    color: ${invertedButtonColor};
  }
  &:active {
    background-color: ${campaignData?.buttonColor};
    color: ${invertedButtonColor};
  }
  &:focus {
    background-color: #ccc;
    color: #000;
  }
`;

  const CalendlyText = styled(Text)`
  color: #0485ad;
`;

  const calendlyStyle = {
    width: "90vw",
    height: "90vh",

    "@media(max-width: 767px)": {
      width: "320px",
      height: "50vh",
    },

    "@media(min-width: 768px) and (max-width: 1023px)": {
      width: "1000px",
      height: "80vh",
    }
  };

  const MuteButton = styled('button')`
  border-radius: 35px !important;
  width: 160px !important;
  height: 60px !important;
  font-size: 20px !important;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25)) drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25)) !important;
  -webkit-border-radius: 35px !important;
  -moz-border-radius: 35px !important;
  -ms-border-radius: 35px !important;
  -o-border-radius: 35px !important;
  
  /* Twilio Paste Button Base Styles */
  display: inline-flex;
  align-items: center;
  justify-content: space-evenly;
  padding: 24px 24px;
  background-color: ${campaignData?.buttonColor};
  color: ${invertedButtonColor};
  border: none;
  text-align: center;
  text-decoration: none;
  cursor: pointer;

  @media (max-width: 576px) {
    height: 40px !important;
    width: 130px !important;
    font-size: 12px !important;
  }
`;

  const EndCallButton = styled('button')`
  border-radius: 35px !important;
  width: 160px !important;
  height: 60px !important;
  font-size: 20px !important;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25)) drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25)) !important;
  -webkit-border-radius: 35px !important;
  -moz-border-radius: 35px !important;
  -ms-border-radius: 35px !important;
  -o-border-radius: 35px !important;

  /* End Call Button Styles */
  display: inline-flex;
  align-items: center;
  justify-content: space-evenly;
  padding: 24px 24px;
  background-color: red; /* Set the button background color to red */
  color: white; /* Set the text color to white */
  border: none;
  text-align: center;
  text-decoration: none;
  cursor: pointer;

  @media (max-width: 576px) {
    height: 40px !important;
    width: 130px !important;
    font-size: 12px !important;
  }
`;

  const backgroundColorChange = visibleStage !== "calendly" ? campaignData?.backgroundColor : "white";

  return (<div style={{ backgroundColor: backgroundColorChange }} id="main">

    <Box minHeight="94vh" width={["98%", "100%", "100%"]} display="flex" justifyContent="center">

      <Box display="flex" padding="space60" overflowX="hidden">

        {!useCampaignContext().isLoading && visibleStage != "calendly" && <><Box position="absolute" top="0" left="0" paddingLeft="space40" paddingTop="space40"><img src={campaignData.logoUrl} width="250" /></Box>

          {!showInterstitial &&
            <>

              {visibleStage == "start" &&
                <Box display="flex" flexDirection={["column", "row"]} alignItems="center" justifyContent="center">

                  <Box display="flex" flexDirection="column" rowGap="space200">

                    <StartButton id='start' onClick={start}>
                      {translate("Get Connected", "Conéctese")}
                    </StartButton>

                    {campaignData.isCodeEnabled == 1 &&
                      <Box display="flex" justifyContent="center">
                        <EnterCodeButton id='code' onClick={enterCode}>
                          {translate("Enter Code", "Ingrese el código")}
                        </EnterCodeButton>
                      </Box>
                    }
                  </Box>

                </Box>
              }

              {visibleStage == "start" && campaignData.availableLanguages.length > 1
                && <Box position="absolute" top="0" left="0" paddingTop="space200" marginTop="space50">

                  <Button id='start-back' variant="inverse_link" onClick={e => back("language")}>
                    <ChevronLeftIcon decorative={false} title="Back" />
                    {translate('back', 'atrás')}
                  </Button>
                </Box>
              }

              {visibleStage == "language"
                && <Box display="flex" flexDirection={["column", "column", "row"]} justifyContent="center" rowGap={["space200", "space200", "space0"]} alignItems="center" columnGap={["space0", "space200"]}>
                  <ActionButton key='language-en' id='language-en' name="language" value='English' onClick={e => handleClick('English', 'language')}>
                    English
                  </ActionButton>
                  <ActionButton key='language-es' id='language-es' name="language" value='Spanish' onClick={e => handleClick('Spanish', 'language')}>
                    Español
                  </ActionButton>
                </Box>
              }

              {visibleStage == "service"
                && <Box position="absolute" top="0" left="0" paddingTop="space200" marginTop="space50">

                  <Button id='service-back' variant="inverse_link" onClick={e => back("start")}>
                    <ChevronLeftIcon decorative={false} title="Back" />
                    {translate('back', 'atrás')}
                  </Button>
                </Box>
              }
              {visibleStage == "service"
                && <Box display="flex" flexDirection="column" justifyContent="center" paddingTop="space200" marginTop="space100">
                  <Text variant="h1" textAlign="center" as="p" fontSize={["fontSize60", "fontSize80"]} color="colorTextInverse" paddingBottom="space100">
                    {translate("I'm looking for help with:", "Estoy buscando ayuda con:")}
                  </Text>
                  <Box display="flex" flexDirection="row" columnGap="space200" width="99%" rowGap="space100" flexWrap="wrap" justifyContent="center">
                    {campaignData.availableServices &&
                      campaignData.availableServices.map((s: string, index: number) => (
                        <ServiceButton id={'service-' + index} key={'service-' + index} name="service" value={s} onClick={e => handleClick(s, 'service')}
                          style={{
                            flexBasis: campaignData.availableServices.length > 6 && "@media(min-width: 576px)" ? '25%' : 'auto',
                          }}>
                          {translateService(s)}
                        </ServiceButton>
                      ))}
                  </Box>
                </Box>
              }

              {visibleStage == "form" && campaignData.availableServices.length > 1
                && <Box position="absolute" top="0" left="0" paddingTop="space200" marginTop="space50">
                  <Button id='service-back' variant="inverse_link" onClick={e => back("service")}>
                    <ChevronLeftIcon decorative={false} title="Back" />
                    {translate('back', 'atrás')}
                  </Button>
                </Box>
              }

              {visibleStage == "form" && campaignData.availableServices.length == 1
                && <Box position="absolute" top="0" left="0" paddingTop="space200" paddingLeft="space60" marginTop="space50">
                  <Button id='service-back' variant="inverse_link" onClick={e => back("start")}>
                    <ChevronLeftIcon decorative={false} title="Back" />
                    {translate('back', 'atrás')}
                  </Button>
                </Box>
              }

              {visibleStage == "form" &&
                <Box display="flex" flexDirection="column" paddingTop={["space100", "space200"]} marginTop="space200" rowGap={["space60", "space100", "space100"]} alignItems="center" justifyContent={["center", "relative"]}>
                  <Box paddingBottom={["space20", "space60", "space60"]} paddingRight={["space0", "space60", "space60"]} width={["80%", "100%"]} textAlign="center">
                    <Text as="h1" color="colorTextInverse" fontSize={["fontSize60", "fontSize70", "fontSize80"]} fontWeight="fontWeightMedium" lineHeight="lineHeight60">
                      {translate('Please enter the following details:', 'Por favor ingrese las detalles siguientes:')}
                    </Text>
                  </Box>

                  <Box display="flex" flexDirection={["column", "row", "row"]} columnGap={["space0", "space60", "space100"]} justifyContent={["center", "space-evenly", "space-between"]} width="100%" rowGap={["space40", "space0", "space0"]}>

                    <Box width={["100%", "33%", "260px"]}>
                      <Label variant="inverse" htmlFor="firstName">{translate('First name', 'Nombre')}</Label>
                      <Input
                        id="firstName"
                        name="firstName"
                        type="text"
                        onChange={handleChange}
                        value={locatorInputs.firstName}
                        required
                        hasError={fNameError.length > 0}
                        placeholder={translate("required", "obligatorio")}
                      ></Input>
                      {fNameError.length > 0 && <Box flexDirection="row" columnGap="space20" display="flex" alignItems="flex-end">
                        <ErrorIcon decorative={false} title="First Name Error icon" color="colorTextBrandInverse" />
                        <Text paddingTop="space20" as="p" fontSize="fontSize30" color="colorTextInverse">
                          {fNameError}</Text>
                      </Box>}
                    </Box>

                    <Box width={["100%", "33%", "260px"]}>
                      <Label variant="inverse" htmlFor="lastName"> {translate('Last name', 'Apellido')}</Label>
                      <Input
                        id="lastName"
                        name="lastName"
                        type="text"
                        onChange={handleChange}
                        value={locatorInputs.lastName}
                        required
                        hasError={lNameError.length > 0}
                        placeholder={translate("required", "obligatorio")}
                      ></Input>
                      {lNameError.length > 0 && <Box flexDirection="row" columnGap="space20" display="flex" alignItems="flex-end">
                        <ErrorIcon decorative={false} title="Last Name Error icon" color="colorTextBrandInverse" />
                        <Text paddingTop="space20" as="p" fontSize="fontSize30" color="colorTextInverse">
                          {lNameError}</Text>
                      </Box>}
                    </Box>
                  </Box>

                  <Box display="flex" flexDirection={["column", "row", "row"]} columnGap={["space0", "space100", "space100"]} justifyContent={["center", "space-evenly", "space-between"]} width="100%" rowGap={["space40", "space0", "space0"]}>

                    <Box width={["100%", "33%", "260px"]}>
                      <Label variant="inverse" htmlFor="email">{translate('Email', 'Correo electrónico')}</Label>
                      <Input
                        id="email"
                        name="email"
                        type="email"
                        onChange={handleChange}
                        value={locatorInputs.email}
                        required
                        hasError={emailError.length > 0}
                        placeholder={translate("required", "obligatorio")}
                      ></Input>
                      {emailError.length > 0 && <Box flexDirection="row" columnGap="space20" display="flex" alignItems="flex-end">
                        <ErrorIcon decorative={false} title="Email Error icon" color="colorTextBrandInverse" />
                        <Text paddingTop="space20" as="p" fontSize="fontSize30" color="colorTextInverse">
                          {emailError}</Text>
                      </Box>}
                    </Box>

                    <Box width={["100%", "33%", "260px"]}>
                      <Label variant="inverse" htmlFor="phone"> {translate('Phone', 'Teléfono')}</Label>
                      <Input
                        id="phone"
                        name="phone"
                        type="tel"
                        onChange={handleChange}
                        value={locatorInputs.phone}
                        required
                        hasError={phoneError.length > 0}
                        placeholder={translate("required", "obligatorio")}
                      ></Input>
                      {phoneError.length > 0 && <Box flexDirection="row" columnGap="space20" display="flex" alignItems="flex-end">
                        <ErrorIcon decorative={false} title="Phone Number Error icon" color="colorTextBrandInverse" />
                        <Text paddingTop="space20" as="p" fontSize="fontSize30" color="colorTextInverse">
                          {phoneError}</Text>
                      </Box>}
                    </Box>

                  </Box>

                  {campaignData.type == "web" &&
                    <Box width={["100%", "33%", "260px"]} alignItems={["center", "auto"]}>
                      <Label variant="inverse" htmlFor="zipCode"> {translate('Zip code', 'Código postal')}</Label>
                      <Input
                        id="zipCode"
                        name="zipCode"
                        type="text"
                        onChange={handleChange}
                        value={locatorInputs.zipCode}
                        required
                        hasError={zipError.length > 0}
                        placeholder={translate("required", "obligatorio")}
                      ></Input>
                      {zipError.length > 0 && <Box flexDirection="row" columnGap="space20" display="flex" alignItems="flex-end">
                        <ErrorIcon decorative={false} title="Zip Code Error icon" color="colorTextBrandInverse" />
                        <Text paddingTop="space20" as="p" fontSize="fontSize30" color="colorTextInverse">
                          {zipError}</Text>
                      </Box>}
                    </Box>
                  }

                  <Box
                    display="flex"
                    paddingTop="space50"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Checkbox
                      checked={consent}
                      id="consent"
                      value="consent"
                      name="consent"
                      onChange={(event) => {
                        setConsent(event.target.checked);
                        setFormValid(validate('change', locatorInputs))
                      }}
                    >
                      <Label variant="inverse" htmlFor="consent">
                        {translate("The NFCC and its member agencies may contact me by video, phone, email or text", "La NFCC y sus agencias miembros pueden comunicarse conmigo por video, teléfono, correo electrónico o mensaje de texto.")}
                      </Label>

                    </Checkbox></Box>


                  <Box paddingTop={["space80", "space0"]}>

                    {formValid && consent &&
                      <StartButton id='get-connected' onClick={e => handleGetConnected()}>
                        {translate('Get Connected', 'Conéctase')}
                      </StartButton>
                    }

                    {(!formValid || !consent) &&
                      <StartButtonDisabled id='get-connected' >
                        {translate('Get Connected', 'Conéctase')}
                      </StartButtonDisabled>
                    }

                  </Box>

                </Box>}


              {visibleStage == "devices" &&
                <Box display="flex" flexDirection="column" paddingTop={["space60", "space140", "space200"]} rowGap={["space40", "space40", "space100"]} alignItems="center" justifyContent={["unset", "center"]} paddingBottom={["space0", "space100", "space200"]}>
                  {!Object.keys(permissionsError).includes('fallback') && !Object.keys(permissionsError).includes('message') && !hasAllPermissions &&
                    <Box paddingBottom={["space20", "space60", "space60"]} paddingRight={["space0", "space60", "space60"]} width={["80%", "100%"]} textAlign="center" paddingTop={["space100", "space0"]}>
                      <Text id="landscapeFont" as="h1" color="colorTextInverse" fontSize={["fontSize60", "fontSize80", "fontSize80"]} fontWeight="fontWeightMedium" lineHeight="lineHeight60">
                        {translate('Allow application to use your camera and microphone', 'Permitir que la aplicación utilice la cámara y el micrófono')}
                      </Text>
                      <Text id="landscapeFont" as="h4" color="colorTextInverse" fontSize={["fontSize30", "fontSize30", "fontSize30"]} fontWeight="fontWeightMedium" lineHeight="lineHeight60" paddingBottom="space60">
                        {translate('The application needs access to your camera and microphone so that other participants can see and hear you', 'La aplicación necesita acceso a tu cámara y micrófono para que los demás participantes puedan verte y oírte')}
                      </Text>
                      <img src={permissions} width="250" />
                    </Box>}

                  {Object.keys(permissionsError).includes('message') && !hasAllPermissions &&
                    <Box paddingBottom={["space20", "space60", "space60"]} paddingRight={["space0", "space60", "space60"]} width={["80%", "100%"]} textAlign="center" paddingTop={["space120", "space60"]}>
                      <Text id="landscapeFont" as="h1" color="colorTextInverse" fontSize={["fontSize60", "fontSize80", "fontSize80"]} fontWeight="fontWeightMedium" lineHeight="lineHeight60">
                        {permissionsError.title}
                      </Text>
                      <Box width={["100%", "90%"]} margin="auto">
                        <Text id="landscapeFontSmol" as="h4" color="colorTextInverse" fontSize={["fontSize30", "fontSize40"]} fontWeight="fontWeightMedium" lineHeight="lineHeight60" paddingY="space60">
                          {permissionsError.message}
                        </Text>
                      </Box>
                      <StartButton id='start' onClick={checkPermissions}>
                        {translate("Check again", "Revisar otra vez")}
                      </StartButton>
                    </Box>}

                  {hasAllPermissions && <>
                    <Box width={["80%", "100%"]} textAlign="center" paddingTop={["space120", "space0", "space0"]}>
                      <Text id="landscapeFont" as="h1" color="colorTextInverse" fontSize={["fontSize60", "fontSize80", "fontSize80"]} fontWeight="fontWeightMedium" lineHeight="lineHeight60">
                        {translate('Please select your input and output devices:', 'Seleccione sus dispositivos de entrada y salida:')}
                      </Text>
                    </Box>

                    <Box display="flex" flexDirection={["column", "row"]} columnGap={["space0", "space120"]} justifyContent="space-evenly" width="100%" flexWrap="wrap">

                      <Box width={["100%", "260px"]} paddingBottom={["space100", "space0"]}>
                        <Label variant="inverse" htmlFor="firstName">{translate('Microphone', 'Micrófono')}</Label>
                        <Select id="audioDeviceInput" name="audioDeviceInput" onChange={setDevice} value={audioDeviceInput}>

                          {audioDevicesInput ? (

                            audioDevicesInput && audioDevicesInput.map((s: any, index: number) => (

                              <Option key={index} value={JSON.stringify(s)} >{s.label}</Option>

                            ))) : null!}
                        </Select>
                      </Box>

                      {audioDevicesOutput.length > 0 && <Box width={["100%", "260px"]} paddingBottom={["space100", "space40"]}>
                        <Label variant="inverse" htmlFor="firstName">{translate('Speaker', 'Altavoz')}</Label>
                        <Select id="audioDeviceOutput" name="audioDeviceOutput" onChange={setDevice} value={audioDeviceOutput}>

                          {audioDevicesOutput ? (

                            audioDevicesOutput && audioDevicesOutput.map((s: any, index: number) => (

                              <Option key={index} value={JSON.stringify(s)} >{s.label}</Option>

                            ))) : null!}
                        </Select>
                      </Box>}

                      <Box width={["100%", "260px"]} paddingBottom={["space100", "space40"]}>
                        <Label variant="inverse" htmlFor="firstName">{translate('Webcam', 'Cámara')}</Label>
                        <Select id="videoDeviceInput" name="videoDeviceInput" onChange={setDevice} value={videoDeviceInput}>

                          {videoDevicesInput ? (

                            videoDevicesInput && videoDevicesInput.map((s: any, index: number) => (

                              <Option key={index} value={JSON.stringify(s)} >{s.label}</Option>

                            ))) : null!}
                        </Select>
                      </Box>


                    </Box></>}



                  {hasAllPermissions && <StartButton id='start' onClick={startAfterPerms}>
                    {translate("Get Connected", "Conéctase")}
                  </StartButton>}

                </Box>}

              {availability && visibleStage == "error" &&

                <Box display="flex" flexDirection="column" rowGap="space100" alignItems="center" justifyContent="center">
                  <Box width="60%" textAlign="center">
                    <Text as="h1" color="colorTextInverse" fontSize={["fontSize60", "fontSize80"]} lineHeight="lineHeight80">
                      {translate("Sorry! It looks like we are experiencing some technical difficulties with this app. (Next steps TBD)",
                        "¡Lo siento! Parece que estamos experimentando algunas dificultades técnicas con esta aplicación.")}
                    </Text>
                  </Box>
                  {campaignData.type == 'booth' && <ActionButton id='language-en' name="language" value='English' onClick={end}>
                    {translate("Start again", "Iniciar de nuevo")}
                  </ActionButton>}
                </Box>
              }

              {['prebooking', 'none-available', 'duplicate', 'calendly'].includes(visibleStage) && campaignData.availableServices.length > 1
                && <Box position="absolute" top="0" left="0" paddingTop="space200" marginTop="space50">

                  <Button id='service-back' variant="inverse_link" onClick={e => back("service")}>
                    <ChevronLeftIcon decorative={false} title="Back" />
                    {translate('back', 'atrás')}
                  </Button>
                </Box>
              }

              {['prebooking', 'none-available', 'duplicate', 'calendly'].includes(visibleStage) && campaignData.availableServices.length == 1 && campaignData.availableLanguages.length > 1
                && <Box position="absolute" top="0" left="0" paddingTop="space200" marginTop="space50">

                  <Button id='service-back' variant="inverse_link" onClick={e => back("language")}>
                    <ChevronLeftIcon decorative={false} title="Back" />
                    {translate('back', 'atrás')}
                  </Button>
                </Box>
              }

              {['prebooking', 'none-available', 'duplicate', 'calendly'].includes(visibleStage) && campaignData.availableServices.length == 1 && campaignData.availableLanguages.length == 1
                && <Box position="absolute" top="0" left="0" paddingTop="space200" marginTop="space50">

                  <Button id='service-back' variant="inverse_link" onClick={e => back("start")}>
                    <ChevronLeftIcon decorative={false} title="Back" />
                    {translate('back', 'atrás')}
                  </Button>
                </Box>
              }

              {availability && visibleStage == "prebooking" &&
                <Box display="flex" flexDirection="column" rowGap="space100" alignItems="center" justifyContent="center">
                  <Box width={["80%", "60%"]} textAlign="center">
                    <Text as="h1" color="colorTextInverse" fontSize={["fontSize60", "fontSize80"]} lineHeight="lineHeight80" fontWeight="fontWeightMedium">
                      {translate("No counselors are available right now but we can schedule a future appointment for you, would you like to see available appointments?", "No hay consejeros disponibles en este momento, pero podemos programar una cita futura para usted, ¿le gustaría ver las citas disponibles?")}
                    </Text>
                  </Box>
                  <Box display="flex" justifyContent="center">
                    <StartButton id='language-es' name="language" value='Spanish' onClick={handleBooking}>
                      {translate("Book an appointment", "Programar una cita")}
                    </StartButton>
                  </Box>
                </Box>
              }

              {availability && visibleStage == "none-available" &&

                <Box display="flex" flexDirection="column" rowGap="space100" alignItems="center" justifyContent={["center", "relative"]}>
                  <Box width={["80%", "60%"]}>
                    <Text as="h1" color="colorTextInverse" fontSize={["fontSize60", "fontSize80"]} textAlign="center" lineHeight="lineHeight60" fontWeight="fontWeightMedium">
                      {translate("Sorry, there are no counselors available at the moment but we have let the following agency know to reach out to you using the contact information you submitted. You will also receive an email with other ways to get in touch.",
                        "Lo sentimos, no hay consejeros disponibles en este momento, pero le hemos avisado a la siguiente agencia para que se comunique con usted utilizando la información de contacto que envió. También recibirá un correo electrónico con otras formas de ponerse en contacto.")}
                    </Text>
                  </Box>

                  <Box display="flex" flexDirection="column" rowGap="space50" alignItems="center">
                    <Text as="h2" color="colorTextInverse" fontSize="fontSize60">
                      {locatorOutput.agencyName}
                    </Text>
                    {Object.keys(locatorOutput).includes('agencyEmail') && <Text as="h3" color="colorTextInverse" fontSize="fontSize40">
                      {translate('Email:', 'Correo electrónico:')} {locatorOutput.agencyEmail}
                    </Text>}
                    {Object.keys(locatorOutput).includes('agencyPhone') && <Text as="h3" color="colorTextInverse" fontSize="fontSize40">
                      {translate('Phone:', 'Teléfono:')} {locatorOutput.agencyPhone}
                    </Text>}
                  </Box>


                </Box>

              }

              {visibleStage == "duplicate" &&

                <Box display="flex" flexDirection="column" rowGap="space100" alignItems="center" paddingTop={["space0", "space60", "space200"]} marginTop={["space200", "space140", "space200"]}>
                  <Box display="flex" flexDirection={["column", "column", "row"]} alignItems="center">
                    <Box display="flex" flexDirection="column" rowGap="space50" width={isCode && "99%" || "49%"} justifyContent="start">
                      <Box
                        padding={["space0", "space40"]}
                        width={["200%", "100%"]}
                        alignItems="center"
                        textAlign="center"
                        borderRightStyle={isCode && "none" || "solid"}
                        borderRightColor={["transparent", "transparent", "colorBorderInverse"]}
                        borderRightWidth="borderWidth10"
                        borderBottomStyle={isCode && "none" || "solid"}
                        borderBottomColor={["colorBorderInverse", "colorBorderInverse", "transparent"]}
                        borderBottomWidth="borderWidth20"
                        paddingTop={["space100", "space60", "space0"]}
                        alignSelf="center"
                      >
                        {locatorOutput.available &&
                          <Box display="flex" flexDirection="column" rowGap={["space60", "space60", "space200"]} alignItems="center" justifyContent="space-between" alignSelf="center" paddingBottom={["space80", "space0"]}>
                            <Text as="h1" color="colorTextInverse" fontSize={["fontSize60", "fontSize60", "fontSize80"]} lineHeight="lineHeight80" fontWeight="fontWeightMedium">
                              {translate("We can see you have already connected with " + locatorOutput.agencyName + ', would you like to reconnect with them?',
                                'Vemos que ya se conectó con ' + locatorOutput.agencyName + '. ¿Le gustaría volver a conectarse con ellos?')}
                            </Text>

                            <StartButton value='yes' onClick={fn => {
                              setShowInterstitial(false)
                              setVisibleStage('devices');
                              checkPermissions()
                            }} >
                              {translate('Connect now (average wait time ', 'Conéctese ahora (tiempo de espera promedio de ')}{Math.ceil(locatorOutput.waitTimeSecs / 60)}{locatorOutput.waitTimeSecs <= 60 && translate(' min)', ' minuto)') || translate(' mins)', ' minutos)')}
                            </StartButton>
                          </Box>
                        }
                        {!locatorOutput.available && (locatorOutput.calendlyUrl?.length == 0 || !locatorOutput.calendlyUrl) && //|| locatorOutput.otherAvailability.length == 0) &&
                          <Box display="flex" flexDirection="column" rowGap="space50" alignItems="center" paddingBottom={["space60", "space0"]}>
                            <Text as="h1" color="colorTextInverse" fontSize={["fontSize60", "fontSize80"]} lineHeight="lineHeight80" fontWeight="fontWeightMedium">
                              {translate("We can see you have already connected with " + locatorOutput.agencyName + ", unfortunately they are not available to connect by video right now",
                                "Vemos que ya se conectó con " + locatorOutput.agencyName + ", desafortunadamente no están disponibles para conectarse por video en este momento")}{isCode && translate('. We have let them know you are waiting in a booth. They may try to contact you by phone or email as well. ', '. Les hemos hecho saber que está esperando en una cabina. Es posible que también intenten comunicarse con usted por teléfono o correo electrónico.')}
                            </Text>

                            <Text as="h2" color="colorTextInverse" fontSize="fontSize60">
                              {locatorOutput.agencyName}
                            </Text>
                            {Object.keys(locatorOutput).includes('agencyEmail') && <Text as="h3" color="colorTextInverse" fontSize="fontSize40">
                              {translate('Email:', 'Correo electrónico:')} {locatorOutput.agencyEmail}
                            </Text>}
                            {Object.keys(locatorOutput).includes('agencyPhone') && <Text as="h3" color="colorTextInverse" fontSize="fontSize40">
                              {translate('Phone:', 'Teléfono:')} {locatorOutput.agencyPhone}
                            </Text>
                            }
                            {Object.keys(locatorOutput).includes('agencyWebsite') && <Text as="h3" color="colorTextInverse" fontSize="fontSize40">
                              {translate('Web:', 'El sitio web:')} {locatorOutput.agencyWebsite}
                            </Text>
                            }

                          </Box>

                        }
                        {!locatorOutput.available && (locatorOutput.calendlyUrl?.length > 0) &&
                          <Box display="flex" flexDirection="column" rowGap="space50" alignItems="center" width={["200%", "100%"]} marginLeft={["spaceNegative200", "space0"]}>
                            <Text as="h1" color="colorTextInverse" fontSize={["fontSize60", "fontSize80"]} lineHeight="lineHeight80" textAlign="center" fontWeight="fontWeightMedium" paddingBottom="space60">
                              {translate("We can see you have already connected with " + locatorOutput.agencyName + ", unfortunately they are not available to connect by video right now. We have let them know to reach out to you using the contact information you submitted.",
                                "Vemos que ya se conectó con " + locatorOutput.agencyName + ", desafortunadamente no están disponibles para conectarse por video en este momento Le hemos avisado a la siguiente agencia para que se comunique con usted utilizando la información de contacto que envió.")}
                            </Text>
                            <Text as="p" color="colorTextInverse" fontSize="fontSize40" paddingBottom="space60">{translate("To reconnect via video click the button below to schedule an appointment with them.",
                              "Para volver a conectarse por video, haga clic en el siguiente botón para programar una cita con ellos.")}

                            </Text>
                            <StartButton onClick={handleCalendly}>
                              {translate("Book an appointment", "Programar una cita")}
                            </StartButton>
                          </Box>
                        }
                      </Box>
                    </Box>

                    {!isCode && <Box display="flex" flexDirection="column" width={["100%", "49%"]} justifyContent="space-between">
                      <Box
                        display="flex"
                        flexDirection="column"
                        padding="space40"
                        alignItems="center"
                        textAlign="center"
                        rowGap={["space0", "space60", "space200"]}
                      >
                        <Box display="flex">
                          <Text as="h2" color="colorTextInverse" fontSize={["fontSize60", "fontSize60", "fontSize80"]} fontWeight="fontWeightMedium" lineHeight="lineHeight80" textAlign="center">
                            {translate("Or you can connect with another agency", "Conectar con otra agencia")}
                          </Text>
                        </Box>

                        {/* No others available, no others bookable*/}
                        {(locatorOutput.otherAvailability.length == 0 || (!((locatorOutput.otherAvailability as []).find(({ available }) => available == true)) && !((locatorOutput.otherAvailability as []).find(({ calendlyUrl }) => calendlyUrl !== '')))) &&
                          <Box display="flex" flexDirection="column" alignItems="center">
                            <Text as="p" color="colorTextInverse" fontSize="fontSize40" paddingBottom="space60">
                              {translate("Please note, this agency does not currently have any live agents available. They will contact you separately using the information you submitted in the form.", "¡Aviso! Esta agencia actualmente no tiene agentes en vivo disponibles. Será contactado por separado utilizando la información que envió en el formulario.")}
                            </Text>
                            <StartButton onClick={handleDuplicateOverride}>
                              {translate("Get connected with a new Agency", "Conéctese con una nueva agencia")}
                            </StartButton></Box>
                        }

                        {/* No others available, others bookable */}
                        {locatorOutput.otherAvailability.length > 0 && ((locatorOutput.otherAvailability as []).find(({ calendlyUrl }) => calendlyUrl !== ''))
                          && !locatorOutput.otherAvailability[0].available &&
                          <Box display="flex" flexDirection="column" alignItems="center">
                            <StartButton value="yes" onClick={handleDuplicateOverride}>
                              {translate("Book an appointment with a new agency", "Programar una cita con una nueva agencia")}
                            </StartButton>
                          </Box>
                        }

                        {/* Others available*/}
                        {locatorOutput.otherAvailability.length > 0 && locatorOutput.otherAvailability[0].available &&
                          <Box display="flex" flexDirection="column" alignItems="center">
                            <Text as="p" color="colorTextInverse" fontSize="fontSize40" paddingBottom="space60">
                              {translate("Average wait time: ", "tiempo de espera promedio de ")}{locatorOutput.otherAvailability[0].availability.waitTimeSecs}{translate(" mins", " minutos")}
                            </Text>
                            <StartButton value="yes" onClick={handleDuplicateOverride}>
                              {translate("Get connected with a new Agency", "Conéctese con una nueva agencia")}
                            </StartButton>
                          </Box>
                        }

                      </Box>
                    </Box>}
                  </Box>
                </Box>
              }
            </>}
        </>}

        {
          visibleStage == "video" && !ended &&
          <Box display="flex" flexDirection="column" rowGap="space100" alignItems="center" margin="auto" paddingTop="space200">
            {!attended && <Box position="relative" minHeight="400px">
              <Box display="flex" flexDirection="column" rowGap="space100" alignItems="center" width="80%" textAlign="center" margin="auto">

                <Text color="colorTextInverse" as="h3" fontSize="fontSize80" paddingTop="space120" fontWeight="fontWeightMedium" lineHeight="lineHeight80">
                  {translate("You're in a queue, please hold and we will be with you soon", "Usted está en cola de espera, por favor espere y le atenderemos pronto.")}
                </Text>

                <Spinner color="colorTextInverse" size="sizeIcon110" decorative={false} title="Loading" />
              </Box>
            </Box>}
            <Box position="relative" minHeight="400px" alignContent="center" flexDirection="column" alignItems="flex-start" display="flex">
              <Box ref={localVideoMedia} position="absolute" width="30%" borderRadius="borderRadius30" overflow="hidden" zIndex="zIndex90" top="0" left="0" hidden={!attended}>
              </Box>

              <Box ref={remoteVideoMedia} position="relative" borderRadius="borderRadius30" overflow="hidden" zIndex="zIndex50">
              </Box>
              {!attended && <Box display="flex" columnGap="space80" rowGap="space100" alignContent="center" maxHeight="50px" width="max-content" flexDirection="row" paddingTop="space30" id='buttonsQueue'>
                {!isMuted && <MuteButton onClick={() => video?.mute()}>
                  <MicrophoneOffIcon aria-label="Mute Microphone" title="Mute Microphone" decorative={false} /> {translate("Mute", "Mudo")}
                </MuteButton>}
                {isMuted && <MuteButton onClick={() => video?.unmute()}>
                  <MicrophoneOnIcon aria-label="Unmute Microphone" title="Unmute Microphone" decorative={false} /> {translate("Unmute", "Activar")}
                </MuteButton>}
                <EndCallButton id='end-call' name="end-call" onClick={handleDisconnect}>
                  <CallFailedIcon decorative={false} title="End Call icon" /> {translate("End Call", "Finalizar llamada")}
                </EndCallButton>
              </Box>}
            </Box>
            {attended && <Box display="flex" columnGap={["space40", "space80"]} rowGap="space100" alignItems="center" maxHeight="50px" width="max-content" flexDirection="row" paddingTop="space30" id='buttonsLive'>
              {!isMuted && <MuteButton onClick={() => video?.mute()}>
                <MicrophoneOffIcon aria-label="Mute Microphone" title="Mute Microphone" decorative={false} /> {translate("Mute", "Mudo")}
              </MuteButton>}
              {isMuted && <MuteButton onClick={() => video?.unmute()}>
                <MicrophoneOnIcon aria-label="Unmute Microphone" title="Unmute Microphone" decorative={false} /> {translate("Unmute", "Activar")}
              </MuteButton>}
              <EndCallButton id='end-call' name="end-call" onClick={handleDisconnect}>
                <CallFailedIcon decorative={false} title="End Call icon" /> {translate("End Call", "Finalizar llamada")}
              </EndCallButton>
            </Box>}


          </Box>
        }

        {
          visibleStage == "video" && ended &&
          <Box display="flex" columnGap="space80" rowGap="space100" width={["90%", "60%"]} textAlign="center" margin="auto" flexDirection="column" alignItems="center">
            <Text as="h1" color="colorTextInverse" fontSize={["fontSize60", "fontSize80"]} lineHeight={["lineHeight60", "lineHeight80"]} fontWeight="fontWeightMedium">
              {translate("Thank you for connecting with an NFCC member Agency. We have sent you an email with their contact information in case you need to get back in touch.",
                "Gracias por conectarse con una agencia asociada de la NFCC. Le hemos enviado un correo electrónico con su información de contacto en caso de que necesite volver a ponerse en contacto.")}
            </Text>
          </Box>
        }

        {

          visibleStage == 'calendly' && <>
            <Box position="absolute" top="0" left="0" paddingLeft="space40" paddingTop="space40"><img src={Logo} width="250" /></Box>
            {/* <Box display="flex" flexDirection="column" rowGap={["space30", "space60", "space60"]} justifyContent="center" width="max-content" marginTop={["space200", "space0", "spaceNegative200"]}>
                <Text as="p" fontSize={["fontSize60", "fontSize80", "fontSize80"]} lineHeight={["lineHeight20", "lineHeight80", "lineHeight80"]} fontWeight="fontWeightMedium">
                  {translate("Agency Details", "Detalles de la Agencia")}
                </Text>
                <Text as="p" fontSize={["fontSize40", "fontSize60", "fontSize60"]} lineHeight={["lineHeight20", "lineHeight80", "lineHeight80"]} fontWeight="fontWeightNormal">
                  {translate("Name:", "Nombre:")}
                  <CalendlyText as="span" fontSize={["fontSize40", "fontSize60", "fontSize60"]}>{" " + locatorOutput.agencyName}</CalendlyText>
                </Text>
                <Text as="p" fontSize={["fontSize40", "fontSize60", "fontSize60"]} lineHeight={["lineHeight20", "lineHeight80", "lineHeight80"]} fontWeight="fontWeightNormal">
                  {translate("Email:", "Correo electrónico:")}
                  <CalendlyText as="span" fontSize={["fontSize40", "fontSize60", "fontSize60"]}>{" " + locatorOutput.agencyEmail}</CalendlyText>
                </Text>
                <Text as="p" fontSize={["fontSize40", "fontSize60", "fontSize60"]} lineHeight={["lineHeight20", "lineHeight80", "lineHeight80"]} fontWeight="fontWeightNormal">
                  {translate("Phone:", "Teléfono:")}
                  <CalendlyText as="span" fontSize={["fontSize40", "fontSize60", "fontSize60"]}>{" " + locatorOutput.agencyPhone}</CalendlyText>
                </Text>
                <Text as="p" fontSize={["fontSize40", "fontSize60", "fontSize60"]} lineHeight={["lineHeight20", "lineHeight80", "lineHeight80"]} fontWeight="fontWeightNormal">
                  {translate("Web:", "El sitio web:")}
                  <CalendlyText as="span" fontSize={["fontSize40", "fontSize60", "fontSize60"]}>{" " + locatorOutput.agencyWebsite}</CalendlyText>
                </Text>
              </Box> */}
            {/* <Separator orientation="vertical" horizontalSpacing={["space0", "space100", "space100"]} /> */}
            <Box paddingTop={["space200", "space140", "space40"]} marginTop={["space40", "space0"]}>
              <InlineWidget url={locatorOutput.calendlyUrl} pageSettings={{
                backgroundColor: 'ffffff',
                hideEventTypeDetails: true,
                hideLandingPageDetails: false,
                primaryColor: '000000',
                textColor: '4d5055',
                hideGdprBanner: true
              }} prefill={{
                email: locatorInputs.email,
                firstName: locatorInputs.firstName,
                lastName: locatorInputs.lastName,
                name: locatorInputs.firstName + ' ' + locatorInputs.lastName + ' (' + locatorInputs.service + ' - ' + locatorInputs.language + ' - Video)',
                customAnswers: {


                  a1: (connectionAgent as any).code + translate(" is your code to start your next video session.",
                    " es su código para iniciar su próxima sesión de vídeo")
                    +
                    translate("\n\nIf you plan to connect with your counselor using your own device, please click on the following link at your scheduled appointment time: ",
                      "\n\nSi planea conectarse con su consejero usando su propio teléfono celular, haga clic en el siguiente enlace a la hora programada de su cita:")
                    + (appState.isProd && 'https://video.nfcc.org/a/' || 'https://nfcc-video-app-build.pages.dev/a/') +
                    (connectionAgent as any).code,
                  a2: locatorInputs.service,
                  a3: locatorInputs.phone
                }
              }}
                styles={calendlyStyle}
              />
            </Box>
          </>
        }

        {showInterstitial &&
          <>
            <Box display="flex" flexDirection="column" paddingTop="space200" marginTop="space200" rowGap="space100" alignItems="center">
              {interstitialText &&
                interstitialText.map((s: string, index: number) => (
                  <Text color="colorTextInverse" as="h3" key={'interstit-' + index} fontSize={["fontSize60", "fontSize80"]} variant="heading30" fontWeight="fontWeightMedium">
                    {interstitialText[index]}
                  </Text>
                ))}

              <Spinner color="colorTextInverse" size="sizeIcon110" decorative={false} title="Loading" />
            </Box>
          </>
        }

      </Box>

      <Modal ariaLabelledby={modalHeadingID} isOpen={isModalOpen} onDismiss={handleClose} size="default">

        <ModalBody>
          <Box display="flex" textAlign="center" margin="auto" paddingY="space140" width="80%" alignContent="center" justifyContent="center">
            <Text as="h3" variant="heading30" fontSize="fontSize80" fontWeight="fontWeightMedium">
              {translate("Ending session in ", "Finalizando sesión en ")}{countdownValue}
            </Text>
          </Box>
        </ModalBody>

        <ModalFooter>
          <ModalFooterActions>
            <Button variant="primary" onClick={handleClose}>{translate("Wait, I need more time", "¡Esperar! necesito más tiempo")}</Button>
          </ModalFooterActions>
        </ModalFooter>

      </Modal>

      <Modal ariaLabelledby={codeModalHeadingID} isOpen={isCodeModalOpen} onDismiss={handleCodeClose} size="default">

        <ModalHeader children={''} />

        <ModalBody>

          <Box width="400px" margin="auto" paddingY="space30" >
            <Label variant="default" htmlFor="code">{translate("Enter Code", "Ingrese el código")}</Label>
            <Input
              id="code"
              name="code"
              type="text"
              onChange={handleCodeChange}
              value={code}
              required

              placeholder={translate("required", "obligatorio")}
              hasError={codeError.length > 0}

            ></Input>
            {codeError.length > 0 && <Box flexDirection="row" columnGap="space20" display="flex" alignItems="flex-end">
              <ErrorIcon decorative={false} title="Code Error icon" color="colorTextError" />
              <Text paddingTop="space20" as="p" fontSize="fontSize30" color="colorTextError" >
                {codeError}</Text>
            </Box>}


          </Box>


        </ModalBody>
        <ModalFooter>
          <ModalFooterActions>
            {/* <Button variant="secondary" onClick={handleCodeClose}>{translate('Back', 'Atrás')}</Button> */}
            <Button variant="primary" onClick={handleCodeGo}>{translate('Get Connected', 'Conéctase')}</Button>
          </ModalFooterActions>
        </ModalFooter>
      </Modal>

    </Box>

    <Box width="100%" height='6vh' display="flex" justifyContent="flex-end"
      alignItems="flex-end" paddingBottom="space50" paddingRight="space50" >
      {visibleStage != 'video' && visibleStage != 'start' && campaignData.type == 'booth' &&
        <Button variant="destructive" id='language-en' name="language" value='English' onClick={end}>
          {translate("End", "Finalizar")}
        </Button>}
    </Box>


  </div>


  );
};

NFCCVideoResponsive.displayName = 'NFCC Video Counseling';
