import { FC, Suspense } from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import { Loading } from "../components/Loading";
import { NFCCVideo } from "../pages/NFCCVideo";
import { NFCCVideoResponsive } from "../pages/NFCCVideoResponsive";
import { useCampaignContext } from "../CampaignProvider";
import { Appointment } from "../pages/Appointment";
import { DemoVideo } from "../pages/DemoVideo"
import { useAppState } from '../hooks/useAppState/useAppState';

export const Router: FC = () => {

	const c= useCampaignContext()
	const { appState } = useAppState();

	return (<Suspense fallback={<Loading />}>
		       <>
		<BrowserRouter basename="/">
			<Routes>
				{!appState.isProd && <Route path="/" element={<Navigate to='/video/3985147b-ee7f-11ed-8b35-06b806f92192' />} />}
				<Route path="/demo-video" element={<DemoVideo />} />
				<Route path="/video/:guid" element={<NFCCVideo />} />
				<Route path="/v/:guid" element={<NFCCVideoResponsive />} />
				<Route path="/appointment/:code" element={<Appointment />} />
				<Route path="/a/:code" element={<Appointment />} />

			</Routes>
		</BrowserRouter></>
	</Suspense>);
};