import { produce } from "immer";

export interface appStateTypes {
	guid: string,
	isProd: boolean,
	domain: string
}

export type appActionTypes =
	| { type: "set-guid"; guid: string }
	| { type: "set-is-prod"; isProd: boolean }
	| { type: "set-domain"; domain: string }
	| { type: "reset-state" }

export const initialAppState: appStateTypes = {
	guid: "",
	isProd : false,
	domain: ""
};

export const appReducer = produce((draft: appStateTypes, action: appActionTypes) => {
	switch (action.type) {
	case "set-guid":
		draft.guid = action.guid;
		console.log(draft.guid, action.guid)
		break;
	case "set-is-prod":
		draft.isProd = action.isProd;
		break;
	case "set-domain":
		draft.domain = action.domain;
		break;
	case "reset-state":
		return initialAppState;
	default:
		console.error("Invalid Reducer");
		break;
	}
});