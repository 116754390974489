import { StrictMode } from "react";
import { ThemeProvider } from "./components/ThemeProvider/ThemeProvider";
import { Router } from "./core/Router";
import reportWebVitals from "./reportWebVitals";
import { CampaignProvider } from "./CampaignProvider";
import { render } from "react-dom";
import { VideoProvider } from "./VideoProvider";
import { AppStateProvider } from "./AppStateProvider";

const process = require('process');


process.on('uncaughtException', function(err : any) {
  
    // Handle the error safely
    console.log('uncaughtException handler',err)
})

process.on('unhandledRejection', function(err : any) {
  
    // Handle the error safely
    console.log('unhandledRejection handler',err)
})
	

render(
  <StrictMode>
    <ThemeProvider>
    <AppStateProvider>
        <CampaignProvider>
          <VideoProvider>
            <Router />
          </VideoProvider>
        </CampaignProvider>
      </AppStateProvider>
    </ThemeProvider>
  </StrictMode>,
  document.querySelector("#root")
);

/**
 * If you want to start measuring performance in your app, pass a function
 * to log results (for example: reportWebVitals(console.log))
 * or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
 */
// eslint-disable-next-line no-console
reportWebVitals(console.log);
