import * as React from 'react';
import { Button } from '@twilio-paste/core/button';
import { Stack } from '@twilio-paste/core/stack';
import { Box } from '@twilio-paste/core/box';
import { RadioButtonGroup, RadioButton } from "@twilio-paste/radio-button-group";

import { default as _ReactPlayer } from 'react-player/lazy';
import { ReactPlayerProps } from "react-player/types/lib";
const ReactPlayer = _ReactPlayer as unknown as React.FC<ReactPlayerProps>;



export const DemoVideo = (): JSX.Element => {


  const [quality, setQuality] = React.useState<string>("HQ")
  const ref = React.useRef<_ReactPlayer>(null) 

  


  return (
    
    <div style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100vw',
      height: '100vh',
      backgroundColor: '#0085ad',
      padding: '30px',
      //paddingTop: '20px'
    }}>
      <Stack orientation='vertical' spacing="space20">
        <ReactPlayer
        // @ts-ignore 
          ref={ref}
          url={'https://cdn.ciptex.com/nfcc/nfcc-video-conference-collateral_'+quality+'.mp4'}
          controls={true}
          width='1050px'
          height='589px'
          
          loop={true}
          playing={true}
          volume = {1}
          config={{file : {attributes: {autoplay : true,autoPlay : true, style:{
            //borderLeft: '0.1px solid black',
            borderRight: '0.7px solid black',
            borderTop: '0.7px solid black',
            width:"100%",
            height:"100%"
          }}}}}
          // @ts-ignore 
          onStart={() => {
            console.log('onStart', ref.current)

           
             // @ts-ignore 
            //ref.current.player.muted="false";
            // @ts-ignore 
            //ref.current.volume = 1;
          }}
        />
        <Box justifyContent='space-between' alignItems='center' display="flex" flexDirection={["row"]} padding='space20' >
          <form>
            <RadioButtonGroup attached name="foo" legend="" value={quality}>
              <RadioButton value="HQ" onClick={() => setQuality('HQ')}>High Quality</RadioButton>
              <RadioButton value="MQ" onClick={() => setQuality('MQ')}>Medium Quality</RadioButton>
              <RadioButton value="LQ" onClick={() => setQuality('LQ')}>Low Quality</RadioButton>
            </RadioButtonGroup></form>
          <Button variant="secondary" id='restart' name="restart" onClick={() => {
             // @ts-ignore 
            ref.current.seekTo(0)
           //console.log(ref)
          }
            }>
            Restart
            </Button>
        </Box></Stack>
    </div>



  );
};

DemoVideo.displayName = 'NFCC Video Demo';
