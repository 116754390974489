import * as React from 'react';
import { Box } from '@twilio-paste/core/box';
import { Spinner } from '@twilio-paste/core/spinner';
import { Button } from '@twilio-paste/core/button';
import { useCampaignContext } from "../CampaignProvider";
import axios, { AxiosRequestConfig } from 'axios';
import { useVideoContext } from "../hooks/useVideoContext";
import { useParams, useNavigate } from "react-router-dom";
import { useAppState } from '../hooks/useAppState/useAppState';
import { MicrophoneOffIcon } from "@twilio-paste/icons/esm/MicrophoneOffIcon";
import { MicrophoneOnIcon } from "@twilio-paste/icons/esm/MicrophoneOnIcon";
import { CallFailedIcon } from "@twilio-paste/icons/esm/CallFailedIcon";
import { AspectRatio, Separator } from '@twilio-paste/core';
import Logo from '../assets/Logo.png';
import useState from 'react-usestateref'
import { Text } from '@twilio-paste/core/text';
import { InlineWidget } from "react-calendly";
import styled from '@emotion/styled'
import dayjs from "dayjs";
import customParseFormat from 'dayjs/plugin/customParseFormat'
import permissions from '../assets/permissions.png';
import {
  MediaPermissionsError,
  MediaPermissionsErrorType,
  requestMediaPermissions
} from 'mic-check';
import { Select, Option } from "@twilio-paste/select";
import { Label } from "@twilio-paste/label";
import { ErrorIcon } from "@twilio-paste/icons/esm/ErrorIcon";
import "./Video.css";


export const Appointment = (): JSX.Element => {
  var utc = require('dayjs/plugin/utc')
  var timezone = require('dayjs/plugin/timezone')
  const navigate = useNavigate();

  //data bits
  const [connectionAgent, setConnectionAgent] = useState<any>({})
  const [newConnectionAgent, setNewConnectionAgent, newConnectionAgentRef] = useState<any>({});
  const [appointmentString, setAppointmentString] = useState<string>("")
  const [error, setError] = useState<boolean>(false)
  const [locatorError, setLocatorError] = useState<boolean>(false)
  const [isNow, setIsNow] = useState<boolean>(false)
  const [isPassed, setIsPassed] = useState<boolean>(false)
  const [videoOn, setVideoOn] = useState<boolean>(false)
  const [availability, setAvailability] = React.useState<any>();
  const [locatorOutput, setLocatorOutput] = React.useState<any>()
  const [visibleStage, setVisibleStage, visibleStageRef] = useState("start"); //React.useState<string>("start");
  const [ending, setEnding] = useState<boolean>(false)

  //device management
  const [audioDevicesInput, setAudioDevicesInput] = React.useState<any>([]);
  const [audioDevicesOutput, setAudioDevicesOutput] = React.useState<any>([]);
  const [videoDevicesInput, setVideoDevicesInput] = React.useState<any>([]);
  const [audioDeviceInput, setAudioDeviceInput] = React.useState<any>();
  const [audioDeviceOutput, setAudioDeviceOutput] = React.useState<any>();
  const [videoDeviceInput, setVideoDeviceInput] = React.useState<any>();
  const [permissionsError, setPermissionsError] = React.useState<any>({});
  const [hasAllPermissions, setHasAllPermissions] = React.useState(false);

  //context bits
  const { code } = useParams();
  const { appDispatch, appState } = useAppState();
  const campaignData = useCampaignContext().campaignData;

  //video bits
  const { video, isMuted, localVideoMedia, remoteVideoMedia, connect, disconnect, isOpen, ended, attended } = useVideoContext();
  const { init: videoInit } = useVideoContext();
  const [interstitialText, setInterstitialText] = React.useState<string[]>(["Starting application..."]);
  const [showInterstitial, setShowInterstitial] = React.useState(false);

  //calendly bits
  const [agencyServiceConfigId, setAgencyServiceConfigId, agencyServiceConfigIdRef] = useState(0);

  const checkPermissions = () => {
    console.log('checkPermissions')

    requestMediaPermissions().then(() => {

      navigator.mediaDevices
        .getUserMedia({ video: true, audio: true })

      //get devices
      navigator.mediaDevices
        .enumerateDevices()
        .then((devices) => {

          let ai: any[] = []
          let ao: any[] = []
          let vi: any[] = []

          let ps = [];

          ps.push(
            new Promise(function (resolve, reject) {
              devices.forEach((device) => {

                console.log(`${device.kind}: ${device.label} id = ${device.deviceId}`);
                if (device.kind === 'audioinput') {
                  ai.push(device)
                  if (device.deviceId === 'default') {
                    setAudioDeviceInput(JSON.stringify(device))
                    localStorage.setItem("input_device", JSON.stringify(device));
                  }
                }
                else if (device.kind === 'audiooutput') {
                  ao.push(device)
                  if (device.deviceId === 'default') {
                    setAudioDeviceOutput(JSON.stringify(device))
                    localStorage.setItem("speaker_device", JSON.stringify(device));
                  }
                }
                else if (device.kind === 'videoinput') {
                  vi.push(device)
                  if (device.deviceId === 'default') {
                    setVideoDeviceInput(JSON.stringify(device))
                    localStorage.setItem("video_device", JSON.stringify(device));
                  }
                }
                else {
                  console.log('???', device)
                }
              }
              );
              resolve('done')
            }))

          Promise.all(ps).then((i) => {
            console.log('promise done')
            setAudioDevicesInput(ai)
            setAudioDevicesOutput(ao)
            setVideoDevicesInput(vi)
          })
        })
        .catch((err) => {
          console.error(`${err.name}: ${err.message}`);
        });
      // can successfully access camera and microphone streams
      console.log('yay')
      setPermissionsError({})
      setHasAllPermissions(true)
    })
      .catch((err: MediaPermissionsError) => {
        const { type, name, message } = err;
        if (type === MediaPermissionsErrorType.SystemPermissionDenied) {
          // browser does not have permission to access camera or microphone
          console.log('browser does not have permission to access camera or microphone')
          setPermissionsError({ fallback: true })
        } else if (type === MediaPermissionsErrorType.UserPermissionDenied) {
          // user didn't allow app to access camera or microphone
          console.log('user did not allow app to access camera or microphone')
          setPermissionsError({
            'title': translate("Camera and microphone are blocked", "Cámara y micrófono bloqueados"),
            'message': translate("This app requires access to your camera and microphone. Click the camera blocked icon in your browser's address bar and grant access to camera and microphone",
              "Esta aplicación requiere acceso a tu cámara y micrófono. Haga clic en el icono de cámara bloqueada en la barra de direcciones del navegador y conceda acceso a la cámara y al micrófono.")
          })

        } else if (type === MediaPermissionsErrorType.CouldNotStartVideoSource) {
          // camera is in use by another application (Zoom, Skype) or browser tab (Google Meet, Messenger Video)
          // (mostly Windows specific problem)
          console.log('camera is in use by another application (Zoom, Skype) or browser tab (Google Meet, Messenger Video)')

          setPermissionsError({
            'title': translate("Can't start your camera or microphone", "No se puede iniciar la cámara o el micrófono"),
            'message': translate("Another application or browser tab might already by using your webcam. Please turn off other cameras before proceeding",
              "Es posible que otra aplicación o pestaña del navegador ya esté utilizando tu webcam. Por favor, apague otras cámaras antes de proceder")
          })


        } else {
          // not all error types are handled by this library
          console.log('other')
          setPermissionsError({ fallback: true })
        }
      })
      .catch((err: any) => { console.log('other error', err) });
  }

  const handleDisconnect = async () => {
    //if the call is ended while in the queue, need to remove the task from flex
    setEnding(true);

    if (!attended) {
      console.log(connectionAgent)

      //get task by videoChatRoomSid attribute
      const agencyVideoId = connectionAgent.agencyVideoId

      if (video) {
        if (video.room) {
          const roomSid = video?.room.sid

          const url = 'https://video-campaigns-6267.twil.io/' + appState.domain + '/cancel-task'

          //cancel task
          const config: AxiosRequestConfig = {
            method: 'post',
            maxBodyLength: Infinity,
            url,
            headers: {
              'Content-Type': 'application/json'
            },
            data: JSON.stringify({
              useProd: appState.isProd,
              data: JSON.stringify({ "agencyVideoId": agencyVideoId, "videoChatRoomSid": roomSid })
            })
          };
          try {
            const response = await axios.request(config);
            disconnect()
          }
          catch (error) {
            disconnect()
          }
        }
      }

    }
    else {
      disconnect()
    }

  }

  function isCalendlyEvent(e: any) {
    return e.data.event && e.data.event.indexOf("calendly") === 0;
  }

  React.useEffect(() => {

    if (appState.domain) {

      //fix for chrome on android only playing ball the first time after restarting chrome
      localStorage.clear();

      window.addEventListener("message", function (e) {

        if (isCalendlyEvent(e)) {
          console.log('calendly', e.data);
          if (e.data.event == 'calendly.event_scheduled') {
            const updateAppointment = async () => {
              //create dummy appointment
              const url = e.data.payload.event.uri

              //update ConnectionAgent
              const config: AxiosRequestConfig = {
                method: 'post',
                maxBodyLength: Infinity,
                url: 'https://video-campaigns-6267.twil.io/' + appState.domain + '/update-appointment',
                headers: {
                  'Content-Type': 'application/json'
                },
                data: JSON.stringify({
                  useProd: appState.isProd,
                  data: JSON.stringify({ 'url': url, 'agencyServiceConfigId': agencyServiceConfigIdRef.current, 'connectionAgent': newConnectionAgentRef.current })
                })

              }
              try {
                const response = await axios.request(config);
              }
              catch (error) {
                console.error(error)
              }
            }

            // call the function
            updateAppointment()
              // make sure to catch any error
              .catch(console.error);
          }
        }
      })
    };
  }, [appState.domain]);

  React.useEffect(() => {
    if (code && appState.domain) {
      console.log('code', code)

      getAppointmentByCode(code)

      console.log('here')


      navigator.mediaDevices
        .getUserMedia({ video: true, audio: true })

      //get devices
      navigator.mediaDevices
        .enumerateDevices()
        .then((devices) => {

          let ai: any[] = []
          let ao: any[] = []
          let vi: any[] = []

          let ps = [];

          ps.push(
            new Promise(function (resolve, reject) {
              devices.forEach((device) => {

                console.log(`${device.kind}: ${device.label} id = ${device.deviceId}`);
                if (device.kind === 'audioinput') {
                  ai.push(device)
                  if (device.deviceId === 'default') {
                    setAudioDeviceInput(device)
                    localStorage.setItem("input_device", JSON.stringify(device));
                  }
                }
                else if (device.kind === 'audiooutput') {
                  ao.push(device)
                  if (device.deviceId === 'default') {
                    setAudioDeviceOutput(device)
                    localStorage.setItem("speaker_device", JSON.stringify(device));
                  }
                }
                else if (device.kind === 'videoinput') {
                  vi.push(device)
                  if (device.deviceId === 'default') {
                    setVideoDeviceInput(device)
                    localStorage.setItem("video_device", JSON.stringify(device));
                  }
                }
              }
              );
              resolve('done')
            }))

          Promise.all(ps).then((i) => {
            //console.log(ai,ao,vi)
            setAudioDevicesInput(ai)
            setAudioDevicesOutput(ao)
            setVideoDevicesInput(vi)
          })
        })
        .catch((err) => {
          console.error(`${err.name}: ${err.message}`);
        });



    }

  }, [appState.domain]);

  const checkAvailability = async (l: any) => {
    console.log('checkAvailability', l)

    let a = { ...l }

    if (a.serviceName === 'Overall Budget and Financial Review') {
      a.serviceName = 'Budget'
    }


    if (l.service === 'Overall Budget and Financial Review') {
      a.service = 'Budget'
    }


    const config: AxiosRequestConfig = {
      method: 'post',
      maxBodyLength: Infinity,
      url: 'https://video-campaigns-6267.twil.io/' + appState.domain + '/get-availability',
      headers: {
        'Content-Type': 'application/json'
      },
      data: JSON.stringify({ useProd: appState.isProd, data: JSON.stringify(a) })
    };
    const response = await axios.request(config);
    console.log(response.data)
    setAvailability(response.data)


  }

  function getAlternativeName(timeZone: string) {
    const opts: any = { timeZoneName: 'shortOffset', timeZone };
    return Intl.DateTimeFormat('en-EN', opts).format(Date.now()).split(',')[1];
  }

  const getAppointmentByCode = async (code: string) => {
    //validate code
    const url = 'https://video-campaigns-6267.twil.io/' + appState.domain + '/get-connection-by-code'

    const config: AxiosRequestConfig = {
      method: 'post',
      maxBodyLength: Infinity,
      url,
      headers: {
        'Content-Type': 'application/json'
      },
      data: JSON.stringify({ useProd: appState.isProd, data: JSON.stringify({ "code": code }) })
    };
    try {

      const response = await axios.request(config);
      console.log('appointment', response.data)

      if (response.data.appointmentStart) {
        setConnectionAgent(response.data)
        dayjs.extend(timezone)
        dayjs.extend(customParseFormat)

        console.log('r', response.data.appointmentStart)

        let d = dayjs((response.data.appointmentStart.split('.')[0] + '.000Z'), 'YYYY-MM-DDTHH:mm:ss.SSSZ')

        console.log('r', response.data.appointmentStart, d)
        //@ts-ignore
        console.log('tz', dayjs.tz.guess(), getAlternativeName(dayjs.tz.guess()))


        let appointmentTimeArray = response.data.appointmentStart.split(' ')[1].split(':')
        appointmentTimeArray.pop()

        //set appointment string
        if (response.data.language == "Spanish") {
          //@ts-ignore
          setAppointmentString("Su cita con " + response.data.agencyName + " está programada para " + d.format('MM/DD/YYYY') + " a las " + d.format('h:mm A'))
        }
        else {
          //@ts-ignore
          setAppointmentString("Your appointment with " + response.data.agencyName + " is scheduled for " + d.format('MM/DD/YYYY') + " at " + d.format('h:mm A'))
        }

        console.log(typeof response.data.appointmentStart)

        //const s = new Date((response.data.appointmentStart.split('.')[0] + 'Z'))
        //const e = new Date((response.data.appointmentEnd.split('.')[0] + 'Z'))

        let e = dayjs((response.data.appointmentEnd.split('.')[0] + '.000Z'), 'YYYY-MM-DDTHH:mm:ss.SSSZ')


        //check if current date is within 5 mins of the appointment window
        //const start = new Date(s.getTime() - 5 * 60000);
        //const end = new Date(e.getTime() + 5 * 60000);

        const start = dayjs(d.valueOf() - 5 * 60000);
        const end = dayjs(e.valueOf() + 5 * 60000);


        //const now = new Date()

        var now = dayjs()

        console.log(response.data.appointmentStart, start, response.data.appointmentEnd, end, now)

        if (now <= end && now >= start) {
          setIsNow(true)
        }

        if (now > end) {
          setIsPassed(true)

          //check availability
          checkAvailability(response.data)

        }




      }
      else {
        setError(true)
      }



    }
    catch (e) {
      console.error(e)
      setError(true)
    }
  }

  const generateCode = async () => {

    console.log('CAZ calling generatecode')
    var code = ''

    const adjectives = ["Active", "Agile", "Amiable", "Ample", "Apt", "Artful", "Astute", "Avid", "Awake", "Aware", "Beloved", "Blissful", "Brave", "Breezy", "Bright",
      "Buoyant", "Candid", "Capable", "Careful", "Cheery", "Chirpy", "Clever", "Comely", "Comfy", "Crisp", "Cuddly", "Dainty", "Darling", "Dazzle", "Delish",
      "Dimple", "Doting", "Dreamy", "Dynamic", "Earnest", "Easy",
      "Exotic", "Expert", "Fabled", "Faith", "Fancy", "Fierce", "Fine", "Fond", "Free", "Fresh", "Friendly", "Frolic", "Fun", "Funny", "Gentle", "Gifted", "Glorious",
      "Grateful", "Happy", "Hardy", "Hearty", "Honest", "Huge", "Humble", "Ideal", "Innocent", "Jazzy", "Jolly", "Keen", "Kind", "Lively", "Lovely", "Loving",
      "Lucky", "Merry", "Mighty", "Modest", "Neat", "Noble", "Peace", "Playful", "Plucky", "Posh", "Pure", "Quick", "Radiant", "Rare", "Refined", "Regal", "Relish", "Rich",
      "Robust", "Rosy", "Secure", "Serene", "Sharp", "Shiny", "Simple", "Sincere", "Skill", "Sleek", "Smart", "Smitten", "Snappy", "Snug", "Solid", "Sonic",
      "Sought", "Speedy", "Spry", "Square", "Stable", "Steady", "Sunny", "Super", "Supreme", "Sure", "Sweet", "Swift", "Swish", "Talented", "Tender",
      "Tickled", "Toasty", "Top"
    ]

    const animals = [
      "Badger", "Beaver", "Bison", "Bobcat", "Buffalo", "Bulldog", "Butterfly", "Capybara", "Cheetah", "Chimp",
      "Chipmunk", "Cicada", "Cobra", "Corgi", "Cougar", "Crab", "Crane", "Crayfish", "Crow", "Deer",
      "Dingo", "Dolphin", "Donkey", "Dragonfly", "Eagle", "Eel", "Elephant", "Elk", "Falcon", "Ferret",
      "Finch", "Firefly", "Flamingo", "Fly", "Fox", "Frog", "Gazelle", "Gecko", "Gibbon", "Giraffe",
      "Goat", "Goose", "Gorilla", "Greyhound", "Guppy", "Hamster", "Hare", "Hawk",
      "Hedgehog", "Heron", "Hippo", "Horse", "Hyena", "Iguana", "Impala", "Jackal", "Jaguar",
      "Jay", "Jellyfish", "Kangaroo", "Koala", "Komodo", "Ladybug", "Lemur", "Leopard", "Lion", "Lizard",
      "Llama", "Lobster", "Macaw", "Magpie", "Manatee", "Mantis", "Marmot", "Meerkat", "Mongoose", "Monkey",
      "Moose", "Mosquito", "Moth", "Narwhal", "Newt", "Ocelot", "Octopus", "Opossum", "Orca",
      "Ostrich", "Otter", "Owl", "Panda", "Panther", "Parrot", "Peacock", "Pelican", "Penguin", "Pig",
      "Platypus", "Poodle", "Porcupine", "Possum", "Puma", "Puppy", "Rabbit", "Raccoon",
      "Rat", "Raven", "Rhino", "Rooster", "Salmon", "Scorpion", "Seagull", "Seahorse",
      "Seal", "Shark", "Sheep", "Shrimp", "Skunk", "Sloth", "Snail", "Snake", "Sparrow", "Squid",
      "Squirrel", "Starling", "Stingray", "Stork", "Swan", "Tamarin", "Tiger", "Toad", "Tortoise",
      "Turkey", "Turtle", "Viper", "Vulture", "Wallaby", "Walrus", "Warthog", "Wasp", "Weasel", "Whale",
      "Wolf", "Wombat", "Wren", "Yak", "Zebra"
    ]

    //generate code
    code = adjectives[Math.floor(Math.random() * adjectives.length)].toLowerCase() + ' ' + animals[Math.floor(Math.random() * animals.length)].toLowerCase()

    //validate code
    const url = 'https://video-campaigns-6267.twil.io/' + appState.domain + '/get-connection-by-code'

    let conn: any = {}

    const config: AxiosRequestConfig = {
      method: 'post',
      maxBodyLength: Infinity,
      url,
      headers: {
        'Content-Type': 'application/json'
      },
      data: JSON.stringify({
        useProd: appState.isProd,
        data: JSON.stringify({ "code": code })
      })
    };



    axios(config)
      .then(function (response1: any) {
        console.log("found connection", JSON.stringify(response1.data));
        if (Object.keys(response1.data).includes("error_message")) {
          //it's unique!
          return code
        }
        else {
          //not unique, generate another
          console.log('not unique')
          generateCode()
        }
      })
      .catch(function (error) {
        console.log('caz', error);
      });


    return code
  };


  //handle button clicks for language and service selection
  const reschedule = async () => {
    console.log('reschedule')
    setInterstitialText([translate('Please wait...', 'Por favor espere...')])
    setShowInterstitial(true)

    let d = {
      ...connectionAgent,
      duplicateOverride: false,
      availabilityResults: availability,
      email: connectionAgent.fromEmail,
      phone: connectionAgent.fromPhone,
    }

    console.log(d)

    const config: AxiosRequestConfig = {
      method: 'post',
      maxBodyLength: Infinity,
      url: 'https://video-campaigns-6267.twil.io/' + appState.domain + '/call-locator-video',
      headers: {
        'Content-Type': 'application/json'
      },
      data: JSON.stringify({ useProd: appState.isProd, data: JSON.stringify(d) })
    };
    const response = await axios.request(config);
    console.log(response.data)

    if (Object.keys(response.data).includes('error_message')) {
      setLocatorError(true)
      setShowInterstitial(false)
    }
    else {
      //const createAppointment = async () => {

      const url = 'https://video-campaigns-6267.twil.io/' + appState.domain + '/create-appointment'

      //const c = response.data.connectionId.toString() + Math.ceil(Math.random() * 10000);
      const c = await generateCode()

      let ca: any = {
        connectionId: response.data.connectionId,
        agencyId: response.data.agencyId,
        code: c
      }

      const config: AxiosRequestConfig = {
        method: 'post',
        maxBodyLength: Infinity,
        url,
        headers: {
          'Content-Type': 'application/json'
        },
        data: JSON.stringify({
          useProd: appState.isProd,
          data: JSON.stringify(ca)
        })
      };
      try {
        const response1 = await axios.request(config);
        ca.connectionAgentId = response1.data.connectionAgentId
        setNewConnectionAgent(ca)

      }
      catch (error) {
        console.error(error)
      }


      setLocatorOutput(response.data)
      setAgencyServiceConfigId(response.data.agencyServiceConfigId)
      setShowInterstitial(false)
    }

  };

  const setDevice = ({
    target
  }: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    console.log(target.id)
    if (target.id === 'audioDeviceInput') {
      setAudioDeviceInput(target.value)
      localStorage.setItem("input_device", target.value);
    }
    else if (target.id === 'audioDeviceOutput') {
      setAudioDeviceOutput(target.value)
      localStorage.setItem("speaker_device", target.value);
      console.log("speaker_device", target.value)
    }
    else if (target.id === 'videoDeviceInput') {
      setVideoDeviceInput(target.value)
      localStorage.setItem("video_device", target.value);
    }

  };



  //handle translation
  const translate = (en: string, es: string) => {
    if (connectionAgent.language == 'Spanish') {
      return es
    }
    return en
  }


  const handleGetConnected = async () => {
    console.log('Calling handleGetConnected')



    setShowInterstitial(false)
    setVisibleStage('devices');
    checkPermissions()


  }


  const startVideoAfterPerms = () => {

    let label = (connectionAgent.service === 'Overall Budget and Financial Review' && 'Budget' || connectionAgent.service) + '_' + (code ?? '').replace('-', '*') + '!' + connectionAgent.referrer + '!' + Math.ceil(Math.random() * 100) + '_' + (connectionAgent.language == 'Spanish' && 'es' || 'en')

    if (Object.keys(connectionAgent).includes('workerSid')) {
      if (connectionAgent.workerSid) {
        label = label + '_' + connectionAgent.workerSid
      }

    }





    if (audioDeviceOutput) {
      videoInit(connectionAgent.accountSid, connectionAgent.firstName + ' ' + connectionAgent.lastName, connectionAgent.kioskSid
        //+ '-' + locatorInputs.firstName + '_' + locatorInputs.lastName
        , label, JSON.parse(audioDeviceOutput).deviceId);
    }
    else {
      videoInit(connectionAgent.accountSid, connectionAgent.firstName + ' ' + connectionAgent.lastName, connectionAgent.kioskSid
        //+ '-' + locatorInputs.firstName + '_' + locatorInputs.lastName
        , label, null);
    }



    let s = [
      translate('Connecting you now', 'Conectándose ahora')]
    setInterstitialText(s)
    setShowInterstitial(true)
    setVisibleStage('')

    setTimeout(() => {
      setShowInterstitial(false);
      setVideoOn(true)

      connect()
    }, 1000)


  }

  // Function to calculate the inverted color
  const getInvertedColor = (color: string) => {
    if (color) {
      const hex = color.replace('#', '');
      const r = parseInt(hex.substring(0, 2), 16);
      const g = parseInt(hex.substring(2, 4), 16);
      const b = parseInt(hex.substring(4, 6), 16);
      const invertedColor = `#${(255 - r).toString(16).padStart(2, '0')}${(255 - g).toString(16).padStart(2, '0')}${(255 - b).toString(16).padStart(2, '0')}`;
      return invertedColor;
    }
    return '#000'; // Default color if no valid color is provided
  };

  // Constant to store the inverted color for use in components
  const invertedButtonColor = getInvertedColor(campaignData?.buttonColor);

  const StartButton = styled('button')`
  height: 12rem;
  width: 22rem;
  font-size: 1.75rem;
  font-weight: 500;
  border-radius: 10px;
  border: none;
  background-color: ${campaignData?.buttonColor};
  color: ${invertedButtonColor};
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  @media (max-width: 576px) {
    height: 6rem;
    width: 14rem;
    font-size: 1rem;
  }

  @media (max-width: 960px) and (orientation: landscape) {
    height: 4rem;
    width: 10rem;
    font-size: 1rem;
  }

  &:visited {
    background-color: ${campaignData?.buttonColor};
    color: ${invertedButtonColor};
  }
  &:active {
    background-color: ${campaignData?.buttonColor};
    color: ${invertedButtonColor};
  }
  &:focus {
    background-color: #ccc;
    color: #000;
  }
`;

  const StartButtonDisabled = styled('button')`
  height: 12rem;
  width: 22rem;
  font-size: 1.75rem;
  font-weight: 500;
  border-radius: 10px;
  border: none;
  background-color: #CBCBCB;
  color: #ADAAAB;
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  @media (max-width: 576px) {
    height: 6rem;
    width: 14rem;
    font-size: 1rem;
  }

  @media (max-width: 960px) and (orientation: landscape) {
    height: 4rem;
    width: 10rem;
    font-size: 1rem;
  }
`;

  const CalendlyText = styled(Text)`
  color: #0485ad;
`;

  const MuteButton = styled('button')`
  border-radius: 35px !important;
  width: 160px !important;
  height: 60px !important;
  font-size: 20px !important;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25)) drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25)) !important;
  -webkit-border-radius: 35px !important;
  -moz-border-radius: 35px !important;
  -ms-border-radius: 35px !important;
  -o-border-radius: 35px !important;
  
  /* Twilio Paste Button Base Styles */
  display: inline-flex;
  align-items: center;
  justify-content: space-evenly;
  padding: 24px 24px;
  background-color: ${campaignData?.buttonColor};
  color: ${invertedButtonColor};
  border: none;
  text-align: center;
  text-decoration: none;
  cursor: pointer;

  @media (max-width: 576px) {
    height: 40px !important;
    width: 130px !important;
    font-size: 12px !important;
  }

  @media (max-width: 960px) and (orientation: landscape) {
    height: 30px !important;
    width: 100px !important;
    font-size: 12px !important;
    
    span.text {
      display: none;
    }

  }
`;

  const EndCallButton = styled('button')`
  border-radius: 35px !important;
  width: 160px !important;
  height: 60px !important;
  font-size: 20px !important;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25)) drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25)) !important;
  -webkit-border-radius: 35px !important;
  -moz-border-radius: 35px !important;
  -ms-border-radius: 35px !important;
  -o-border-radius: 35px !important;

  /* End Call Button Styles */
  display: inline-flex;
  align-items: center;
  justify-content: space-evenly;
  padding: 24px 24px;
  background-color: red; /* Set the button background color to red */
  color: white; /* Set the text color to white */
  border: none;
  text-align: center;
  text-decoration: none;
  cursor: pointer;

  @media (max-width: 576px) {
    height: 40px !important;
    width: 130px !important;
    font-size: 12px !important;
  }

  @media (max-width: 960px) and (orientation: landscape) {
    height: 30px !important;
    width: 100px !important;
    font-size: 12px !important;
  }
`;

  // interface ImageProps {
  //   style?: CSSProperties;
  // }

  const backgroundColorChange = !(locatorOutput && locatorOutput.calendlyUrl.length > 0) ? "#0085AD" : "white";

  return (<div style={{ backgroundColor: backgroundColorChange }} id='main'>

    <Box height="100vh" width={["98%", "100%", "100%"]} display="flex" justifyContent="center">

      <Box display="flex" padding="space60" overflowX="hidden">

        {(!videoOn || ended) && <Box position="absolute" top="0" left="0" paddingLeft="space40" paddingTop="space40"><img src={"https://cdn.ciptex.com/nfcc/nfcc-white.png"} width="250" /></Box>}


        {visibleStage == "devices" && !videoOn &&
          <Box display="flex" flexDirection="column" paddingTop={["space60", "space140", "space200"]} rowGap={["space40", "space40", "space100"]} alignItems="center" justifyContent={["unset", "center"]} paddingBottom={["space0", "space100", "space200"]}>
            {!Object.keys(permissionsError).includes('fallback') && !Object.keys(permissionsError).includes('message') && !hasAllPermissions &&
              <Box paddingBottom={["space20", "space60", "space60"]} paddingRight={["space0", "space60", "space60"]} width={["80%", "100%"]} textAlign="center" paddingTop={["space100", "space0"]}>
                <Text id="landscapeFont" as="h1" color="colorTextInverse" fontSize={["fontSize60", "fontSize80", "fontSize80"]} fontWeight="fontWeightMedium" lineHeight="lineHeight60">
                  {translate('Allow application to use your camera and microphone', 'Permitir que la aplicación utilice la cámara y el micrófono')}
                </Text>
                <Text id="landscapeFont" as="h4" color="colorTextInverse" fontSize={["fontSize30", "fontSize30", "fontSize30"]} fontWeight="fontWeightMedium" lineHeight="lineHeight60" paddingBottom="space60">
                  {translate('The application needs access to your camera and microphone so that other participants can see and hear you', 'La aplicación necesita acceso a tu cámara y micrófono para que los demás participantes puedan verte y oírte')}
                </Text>
                <img src={permissions} width="250" />
              </Box>}

            {Object.keys(permissionsError).includes('message') && !hasAllPermissions &&
              <Box paddingBottom={["space20", "space60", "space60"]} paddingRight={["space0", "space60", "space60"]} width={["80%", "100%"]} textAlign="center" paddingTop={["space120", "space60"]}>
                <Text id="landscapeFont" as="h1" color="colorTextInverse" fontSize={["fontSize60", "fontSize80", "fontSize80"]} fontWeight="fontWeightMedium" lineHeight="lineHeight60">
                  {permissionsError.title}
                </Text>
                <Box width={["100%", "90%"]} margin="auto">
                  <Text id="landscapeFontSmol" as="h4" color="colorTextInverse" fontSize={["fontSize30", "fontSize40"]} fontWeight="fontWeightMedium" lineHeight="lineHeight60" paddingY="space60">
                    {permissionsError.message}
                  </Text>
                </Box>
                <StartButton id='start' onClick={checkPermissions}>
                  {translate("Check again", "Revisar otra vez")}
                </StartButton>
              </Box>}

            {hasAllPermissions && <>
              <Box width={["80%", "100%"]} textAlign="center" paddingTop={["space120", "space0", "space0"]}>
                <Text id="landscapeFont" as="h1" color="colorTextInverse" fontSize={["fontSize60", "fontSize80", "fontSize80"]} fontWeight="fontWeightMedium" lineHeight="lineHeight60">
                  {translate('Please select your input and output devices:', 'Seleccione sus dispositivos de entrada y salida:')}
                </Text>
              </Box>

              <Box display="flex" flexDirection={["column", "row"]} columnGap={["space0", "space120"]} justifyContent="space-evenly" width="100%" flexWrap="wrap">

                <Box width={["100%", "260px"]} paddingBottom={["space100", "space0"]}>
                  <Label variant="inverse" htmlFor="firstName">{translate('Microphone', 'Micrófono')}</Label>
                  <Select id="audioDeviceInput" name="audioDeviceInput" onChange={setDevice} value={audioDeviceInput}>

                    {audioDevicesInput ? (

                      audioDevicesInput && audioDevicesInput.map((s: any, index: number) => (

                        <Option key={index} value={JSON.stringify(s)} >{s.label}</Option>

                      ))) : null!}
                  </Select>
                </Box>

                {audioDevicesOutput.length > 0 && <Box width={["100%", "260px"]} paddingBottom={["space100", "space40"]}>
                  <Label variant="inverse" htmlFor="firstName">{translate('Speaker', 'Altavoz')}</Label>
                  <Select id="audioDeviceOutput" name="audioDeviceOutput" onChange={setDevice} value={audioDeviceOutput}>

                    {audioDevicesOutput ? (

                      audioDevicesOutput && audioDevicesOutput.map((s: any, index: number) => (

                        <Option key={index} value={JSON.stringify(s)} >{s.label}</Option>

                      ))) : null!}
                  </Select>
                </Box>}

                <Box width={["100%", "260px"]} paddingBottom={["space100", "space40"]}>
                  <Label variant="inverse" htmlFor="firstName">{translate('Webcam', 'Cámara')}</Label>
                  <Select id="videoDeviceInput" name="videoDeviceInput" onChange={setDevice} value={videoDeviceInput}>

                    {videoDevicesInput ? (

                      videoDevicesInput && videoDevicesInput.map((s: any, index: number) => (

                        <Option key={index} value={JSON.stringify(s)} >{s.label}</Option>

                      ))) : null!}
                  </Select>
                </Box>


              </Box></>}



            {hasAllPermissions && <StartButton id='start' onClick={startVideoAfterPerms}>
              {translate("Get Connected", "Conéctase")}
            </StartButton>}

          </Box>}

        {!videoOn && connectionAgent && !isPassed && appointmentString && !showInterstitial && visibleStage != "devices" &&

          <Box display="flex" padding="space60">

            

            <Box display="flex" alignItems="center">

              <Box display="flex" flexDirection="column" rowGap="space80" alignItems="center" className="landscapeMobile">

                <Box>
                  <Text id="landscapeFont" as="h1" color="colorTextInverse" fontSize={["fontSize60", "fontSize80", "fontSize80"]} textAlign="center" lineHeight="lineHeight60" fontWeight="fontWeightMedium">
                    {translate("Hi", "!Hola")}{connectionAgent.firstName != 'Quick' && " " + connectionAgent.firstName || ""}!
                  </Text>
                </Box>
                <Box>
                  <Text id="landscapeFont" as="h1" color="colorTextInverse" fontSize={["fontSize60", "fontSize80", "fontSize80"]} textAlign="center" lineHeight="lineHeight60" fontWeight="fontWeightMedium">
                    {appointmentString}
                  </Text>
                </Box>
                <Box>
                  <Text id="landscapeFont" as="h1" color="colorTextInverse" fontSize={["fontSize60", "fontSize80", "fontSize80"]} textAlign="center" lineHeight="lineHeight60" fontWeight="fontWeightMedium">
                    {translate("The button will be enabled 5 minutes before your scheduled appointment",
                      "El botón se habilitará 5 minutos antes de su cita programada")}
                  </Text>
                </Box>
                {isNow &&
                  <Box paddingTop="space40">
                    <StartButton id='start' onClick={handleGetConnected}>
                      {translate("Get Connected", "Conéctese")}
                    </StartButton>
                  </Box>
                }
                {!isPassed && !isNow &&
                  <Box paddingTop="space40">
                    <StartButtonDisabled id='get-connected' >
                      {translate("Get Connected", "Conéctese")}
                    </StartButtonDisabled>
                  </Box>
                }


              </Box>

            </Box>


          </Box>}
        {error &&

          <Box display="flex" padding="space60">

            <Box position="absolute" top="0" left="0" paddingLeft="space40" paddingTop="space40"><img src="https://cdn.ciptex.com/nfcc/nfcc-white.png" width="250" /></Box>

            <Box display="flex" alignItems="center">

              <Box display="flex" flexDirection="column" rowGap="space80" alignItems="center">

                <Box>
                  <Text id="landscapeFont" as="h1" color="colorTextInverse" fontSize={["fontSize60", "fontSize80", "fontSize80"]} textAlign="center" lineHeight="lineHeight60" fontWeight="fontWeightMedium">
                    {translate("This appointment cannot be found. Please check the link and try again", "No se puede encontrar esta cita. Por favor revisa el enlace y vuelve a intentarlo")}
                  </Text>
                </Box>



              </Box>

            </Box>


          </Box>}

        {locatorError &&

          <Box display="flex" padding="space60">


            <Box display="flex" alignItems="center">

              <Box display="flex" flexDirection="column" rowGap="space80" alignItems="center">

                <Box display="flex" flexDirection="column" alignItems="center" rowGap="space100" justifyContent="center">
                  <ErrorIcon decorative={false} title="Error Icon" size="sizeIcon100" color="colorTextInverse" />
                  <Text id="landscapeFont" variant="h1" textAlign="center" as="p" fontSize="fontSize80" color="colorTextInverse" paddingBottom="space200" >
                    {translate("Apologies, an error has occured", "Disculpas, ha ocurrido un error")}
                  </Text>
                </Box>


              </Box>

            </Box>


          </Box>}
        {!videoOn && connectionAgent && isPassed && !showInterstitial && visibleStage != 'devices' && !locatorError &&
          <Box display="flex">

            <Box position="absolute" top="0" left="0" paddingLeft="space40" paddingTop="space40"><img src="https://cdn.ciptex.com/nfcc/nfcc-white.png" width="250" /></Box>

            <Box display="flex" flexDirection="row" alignItems="center">

              <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" className="landscapeMobile">

                {!videoOn && connectionAgent && isPassed && !showInterstitial && !locatorOutput &&
                  <Box width={["90%", "100%"]} >
                    <Text id="landscapeFont" as="h1" color="colorTextInverse" fontSize={["fontSize60", "fontSize80", "fontSize80"]} textAlign="center" lineHeight="lineHeight60" fontWeight="fontWeightMedium" paddingBottom={["space0", "space60"]}>
                      {translate("Your scheduled appointment with " + connectionAgent.agencyName + " has passed", "Su cita programada con " + connectionAgent.agencyName + " ha pasado")}
                    </Text>
                  </Box>
                }

                {!availability &&
                  <>
                    <Box display="flex" flexDirection="column" paddingTop="space60" alignItems="center">

                      {/*<Text color="colorTextInverse" as="h3" key={'interstit-'} fontSize={["fontSize60", "fontSize80", "fontSize80"]} variant="heading30" fontWeight="fontWeightMedium">
                    {translate("Starting application...", 'Ejecutando aplicación...')}
            </Text>*/}

                      <Spinner color="colorTextInverse" size="sizeIcon110" decorative={false} title="Loading" />
                    </Box>
                  </>
                }
                {availability && !locatorOutput && <Box display="flex" flexDirection={["column", "row", "row"]} columnGap={["space0", "space100", "space200"]} rowGap="space80" alignContent="center" flexWrap="wrap" justifyContent="center" paddingTop={["space60", "space60", "space0"]}>
                  {availability.available &&
                    <StartButton value="yes" onClick={handleGetConnected}>
                      {translate("Connect now (average wait time 5 mins)", "Conéctese ahora (tiempo de espera promedio de 5 minutos)")}
                    </StartButton>
                  }
                  {!availability.available &&
                    <StartButtonDisabled value="yes">
                      {translate("No counselors available", "No hay consejeros disponibles")}
                    </StartButtonDisabled>
                  }

                  <StartButton value="yes" onClick={reschedule}>
                    {translate("Reschedule", "Reprogramar")}
                  </StartButton>
                </Box>
                }

              </Box>

            </Box>


          </Box>}
        {showInterstitial &&
          <>
            <Box display="flex" flexDirection="column" paddingTop="space200" marginTop="space200" rowGap="space100" alignItems="center">
              {interstitialText &&
                interstitialText.map((s: string, index: number) => (
                  <Text id="landscapeFont" color="colorTextInverse" as="h3" key={'interstit-' + index} fontSize={["fontSize40", "fontSize80", "fontSize80"]} variant="heading30" fontWeight="fontWeightMedium">
                    {interstitialText[index]}
                  </Text>
                ))}

              <Spinner color="colorTextInverse" size="sizeIcon110" decorative={false} title="Loading" />
            </Box>
          </>
        }

        {
          videoOn && !ended &&
          <Box display="flex" flexDirection="column" rowGap="space100" alignItems="center" overflow="hidden" justifyContent="center">

            <Box ref={localVideoMedia} position="fixed" width={"20%"} borderRadius="borderRadius30" overflow="hidden" zIndex="zIndex90" top="10px" left="10px" hidden={!attended}>
            </Box>





            <Box position="relative" minHeight="400px" alignContent="center">


              {!attended && <Box position="relative" minHeight="400px">
                <Box display="flex" flexDirection="column" rowGap="space100" alignItems="center" width={["85%", "60%"]} textAlign="center" margin="auto">

                  <Text id="landscapeFont" color="colorTextInverse" as="h3" fontSize={["fontSize60", "fontSize80"]} paddingTop={["space200", "space200"]} fontWeight="fontWeightMedium" lineHeight="lineHeight80">
                    {translate("You're in a queue, please hold and we will be with you soon", "Usted está en cola de espera, por favor espere y le atenderemos pronto.")}
                  </Text>

                  <Spinner color="colorTextInverse" size="sizeIcon110" decorative={false} title="Loading" />
                </Box>
              </Box>}
              {/*<AspectRatio key='ar'
              ratio={document.body.clientWidth<document.body.clientHeight && "9:16" || "16:9"} ref={remoteVideoMedia}>*/}
              <Box ref={remoteVideoMedia} position="relative" borderRadius="borderRadius30" overflow="hidden" zIndex="zIndex50">
              </Box>{/*</AspectRatio>*/}
            </Box>


            {/*<div style={{"bottom" : "70px", "position" : "fixed", "zIndex" : "90"}}>*/}
            <Box display="flex" columnGap={["space40", "space80"]} rowGap="space100" alignItems="center" justifyContent="center" width="max-content" bottom={["10px", "20px", "30px"]} position="fixed" zIndex="zIndex90">
              {!isMuted && <MuteButton onClick={() => video?.mute()} >
                <MicrophoneOffIcon aria-label="Mute Microphone" title="Mute Microphone" decorative={false} /> {translate("Mute", "Mudo")}
              </MuteButton>}
              {isMuted && <MuteButton onClick={() => video?.unmute()} >
                <MicrophoneOnIcon aria-label="Unmute Microphone" title="Unmute Microphone" decorative={false} /> {translate("Unmute", "Activar")}
              </MuteButton>}
              <EndCallButton id='end-call' name="end-call" onClick={handleDisconnect}>
                {ending && <Box margin="space20"><Spinner  color="colorTextInverse" size="sizeIcon60" decorative={false} title="Ending call"/></Box> || 
               <> <CallFailedIcon decorative={false} title="End Call icon"  /> {translate("End Call", "Finalizar llamada")}</>}
              </EndCallButton>
            </Box>{/*</div>*/}

          </Box>
        }

        {
          videoOn && ended &&
          <Box display="flex" columnGap="space80" rowGap="space100" width={["90%", "60%"]} textAlign="center" margin="auto" flexDirection="column" alignItems="center">
            <Text id="landscapeFont" as="h1" color="colorTextInverse" fontSize={["fontSize60", "fontSize80", "fontSize80"]} lineHeight="lineHeight80" fontWeight="fontWeightMedium">
              {translate("Thank you for connecting with an NFCC member Agency.",
                "Gracias por conectarse con una agencia asociada de la NFCC.")}
            </Text>
          </Box>
        }
        {

          locatorOutput && locatorOutput.calendlyUrl.length == 0 && <Box display="flex" flexDirection="column" paddingTop="space200">
            <Box display="flex" textAlign="center" alignContent="center" alignItems="center" flexDirection="column" paddingTop="space200" paddingBottom="space60">
              <Text id="landscapeFont" as="h1" color="colorTextInverse" fontSize={["fontSize40", "fontSize80", "fontSize80"]} lineHeight="lineHeight80" fontWeight="fontWeightMedium">
                {translate("To re-schedule your appointment please contact",
                  "Para reprogramar su cita por favor conéctese con")}
              </Text>

            </Box>
            <Box display="flex" flexDirection="column" rowGap={["space50", "space70", "space100"]} alignItems="center" paddingTop={["space20", "space40", "space80"]}>
              <Text as="h2" color="colorTextInverse" fontSize={["fontSize60", "fontSize80", "fontSize100"]}>
                {locatorOutput.agencyName}
              </Text>
              {Object.keys(locatorOutput).includes('agencyEmail') && <Text as="h4" color="colorTextInverse" fontSize={["fontSize40", "fontSize60", "fontSize80"]}>
                {translate('Email:', 'Correo electrónico:')} {locatorOutput.agencyEmail}
              </Text>}
              {Object.keys(locatorOutput).includes('agencyPhone') && <Text as="h4" color="colorTextInverse" fontSize={["fontSize40", "fontSize60", "fontSize80"]}>
                {translate('Phone:', 'Teléfono:')} {locatorOutput.agencyPhone}
              </Text>}
            </Box>
          </Box>}

        {
          locatorOutput && locatorOutput.calendlyUrl.length > 0 &&
          <>
          <Box position="absolute" top="0" left="0" paddingLeft="space40" paddingTop="space40"><img id="logoId" src={Logo} width="250" /></Box>

            <Box display="flex" flexDirection={["column", "column", "row"]} columnGap="space200" paddingTop={["space200", "space60", "space0"]} justifyContent="center" alignItems="center" flexWrap={["nowrap", "wrap", "nowrap"]}>
              <Box display={["none", "none", "flex"]} flexDirection="column" rowGap={["space30", "space60", "space60"]} justifyContent="center" width="max-content" marginTop={["space200", "space0", "spaceNegative200"]}>
                <Text as="p" fontSize={["fontSize60", "fontSize80", "fontSize80"]} lineHeight={["lineHeight20", "lineHeight80", "lineHeight80"]} fontWeight="fontWeightMedium">
                  {translate("Agency Details", "Detalles de la Agencia")}
                </Text>
                <Text as="p" fontSize={["fontSize40", "fontSize60", "fontSize60"]} lineHeight={["lineHeight20", "lineHeight80", "lineHeight80"]} fontWeight="fontWeightNormal">
                  {translate("Name:", "Nombre:")}
                  <CalendlyText as="span" fontSize={["fontSize40", "fontSize60", "fontSize60"]}>{" " + locatorOutput.agencyName}</CalendlyText>
                </Text>
                <Text as="p" fontSize={["fontSize40", "fontSize60", "fontSize60"]} lineHeight={["lineHeight20", "lineHeight80", "lineHeight80"]} fontWeight="fontWeightNormal">
                  {translate("Email:", "Correo electrónico:")}
                  <CalendlyText as="span" fontSize={["fontSize40", "fontSize60", "fontSize60"]}>{" " + locatorOutput.agencyEmail}</CalendlyText>
                </Text>
                <Text as="p" fontSize={["fontSize40", "fontSize60", "fontSize60"]} lineHeight={["lineHeight20", "lineHeight80", "lineHeight80"]} fontWeight="fontWeightNormal">
                  {translate("Phone:", "Teléfono:")}
                  <CalendlyText as="span" fontSize={["fontSize40", "fontSize60", "fontSize60"]}>{" " + locatorOutput.agencyPhone}</CalendlyText>
                </Text>
                <Text as="p" fontSize={["fontSize40", "fontSize60", "fontSize60"]} lineHeight={["lineHeight20", "lineHeight80", "lineHeight80"]} fontWeight="fontWeightNormal">
                  {translate("Web:", "El sitio web:")}
                  <CalendlyText as="span" fontSize={["fontSize40", "fontSize60", "fontSize60"]}>{" " + locatorOutput.agencyWebsite}</CalendlyText>
                </Text>
              </Box>
              <Box display={["none", "none", "flex"]}>
                <Separator orientation="vertical" horizontalSpacing={["space0", "space100", "space100"]} />
              </Box>
              <Box display="flex" flexDirection="column" rowGap="space100" justifyContent="center" height="83vh" padding={["space0", "space100"]} backgroundColor="colorBackgroundBody" marginTop={["spaceNegative90", "space100", "space200"]}>
                <InlineWidget url={locatorOutput.calendlyUrl} pageSettings={{
                  backgroundColor: 'ffffff',
                  hideEventTypeDetails: true,
                  hideLandingPageDetails: false,
                  primaryColor: '000000',
                  textColor: '4d5055',
                  hideGdprBanner: true
                }} prefill={{
                  email: connectionAgent.fromEmail,
                  firstName: connectionAgent.firstName,
                  lastName: connectionAgent.lastName,
                  name: connectionAgent.firstName + ' ' + connectionAgent.lastName + ' (' + connectionAgent.service + ' - ' + connectionAgent.language + ' - Video)',
                  customAnswers: {
                    a1: (newConnectionAgent as any).code + translate(" is your code to start your next video session.",
                    " es su código para iniciar su próxima sesión de vídeo")
                    +
                    translate("\n\nIf you plan to connect with your counselor using your own device, please click on the following link at your scheduled appointment time: ",
                      "\n\nSi planea conectarse con su consejero usando su propio teléfono celular, haga clic en el siguiente enlace a la hora programada de su cita:")
                    + (appState.isProd && 'https://video.nfcc.org/a/' || 'https://nfcc-video-app-build.pages.dev/a/') +
                    (newConnectionAgent as any).code,
                    


                    a2: connectionAgent.service,
                    a3: connectionAgent.fromPhone
                  }
                }} />

              </Box>
            </Box>
          </>
        }
      </Box>

    </Box>


  </div>


  );
};

Appointment.displayName = 'NFCC Video Counseling';
