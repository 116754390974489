import { Dispatch, PropsWithChildren, createContext, useReducer, useState, Context, FC } from "react";
import { appActionTypes, appReducer, appStateTypes, initialAppState } from "./core/appReducer";

export interface StateContextType {

	appState: appStateTypes;
	appDispatch: Dispatch<appActionTypes>;
}

export const StateContext: Context<StateContextType> = createContext<StateContextType>(null!);

/*
  The 'react-hooks/rules-of-hooks' linting rules prevent React Hooks from being called
  inside of if() statements. This is because hooks must always be called in the same order
  every time a component is rendered. The 'react-hooks/rules-of-hooks' rule is disabled below
  because the "if (process.env.REACT_APP_SET_AUTH === 'firebase')" statements are evaluated
  at build time (not runtime). If the statement evaluates to false, then the code is not
  included in the bundle that is produced (due to tree-shaking). Thus, in this instance, it
  is ok to call hooks inside if() statements.
*/

export const AppStateProvider: FC<{}> = (props: PropsWithChildren<{}>) => {

	const [appState, appDispatch] = useReducer(appReducer, initialAppState);


	const contextValue = {

		appState,
		appDispatch,
	} as StateContextType;

	return (
		<StateContext.Provider value={{ ...contextValue }}>
			{props.children}
		</StateContext.Provider>
	);
}
