import React, { createContext, useContext, useEffect, useState } from 'react';
import axios, { AxiosRequestConfig } from 'axios';
import { useAppState } from './hooks/useAppState/useAppState';


interface CampaignData {
  useProd: boolean;
  guid: string;
}

interface CampaignContextState {
  campaignData?: any;
  isLoading: boolean;
  error?: string;
}

interface CampaignProviderProps {
  children: React.ReactNode;
}

const CampaignContext = createContext<CampaignContextState>({
  isLoading: true
});

const CampaignProvider: React.FC<CampaignProviderProps> = ({ children }) => {
  const [campaignData, setCampaignData] = useState<any>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<string>();
  const { appState, appDispatch } = useAppState();

  console.log(appState)

  useEffect(() => { 
    console.log('here')
    let isProd_ = false;

    if(['http://localhost:3000','https://nfcc-video-app-build.pages.dev'].includes(document.location.origin ))
    {
      isProd_ = false
    }
    else if (['https://video.nfcc.org'].includes(document.location.origin ) || document.location.origin.includes('cloudfront')) //TODO TEMPORARY!!!!
    {
      isProd_ = true
    }
    else{
      return
    }

    appDispatch({ type: "set-is-prod", isProd: isProd_ });

    let domain_ = ''

    if(['http://localhost:3000'].includes(document.location.origin ))
    {
      domain_ = 'localhost'
    }
    else if(['https://nfcc-video-app-build.pages.dev'].includes(document.location.origin ))
    {
      domain_ = 'pages.dev'
    }
    else if (['https://video.nfcc.org'].includes(document.location.origin )) 
    {
      domain_ = 'video.nfcc.org'
    }
    else if (document.location.origin.includes('cloudfront')) //TODO TEMPORARY!!!!
    {
      domain_ = 'cloudfront'
    }

    appDispatch({ type: "set-domain", domain: domain_ ?? '' }); 
  }, []);

  useEffect(() => {
    const fetchCampaignData = async () => {

      try {
        
        if (appState.guid != '') {
          console.log(appState.guid)



          const data: CampaignData = {
            useProd: appState.isProd,
            guid: appState.guid 
          };
          const config: AxiosRequestConfig = {
            method: 'post',
            maxBodyLength: Infinity,
            url: 'https://video-campaigns-6267.twil.io/'+appState.domain+'/get-video-campaign',
            headers: {
              'Content-Type': 'application/json'
            },
            data: JSON.stringify(data)
          };
          const response = await axios.request(config);
          console.log(response)
          setCampaignData(response.data);
          setIsLoading(false);
        }
      } catch (error) {
        //setError(error?.message);
        setIsLoading(false);
      }
    };
    fetchCampaignData();


  }, [appState.guid]);






  return (
    <CampaignContext.Provider value={{ campaignData, isLoading, error }}>
      {children}
    </CampaignContext.Provider>
  );
};

const useCampaignContext = () => {
  const context = useContext(CampaignContext);
  if (!context) {
    throw new Error('useCampaignContext must be used within a CampaignProvider');
  }
  return context;
};

export { CampaignProvider, useCampaignContext };
